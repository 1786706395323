import React, { createContext, useReducer, useContext, useEffect } from 'react'
import { ContextDevTool } from 'react-context-devtool'
import { messages } from 'utils'
import reducer from './reducer'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useAuth } from '../AuthContext'

const QuestsContext = createContext([])

const initialState = {
  loadingQuests: true,
  errorQuests: false,
  errorMessageQuests: null,
  quests: [],
}

export const QuestsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { enqueueSnackbar } = useSnackbar()
  const { loadingAuth } = useAuth()

  useEffect(() => {
    !loadingAuth && loadQuests()
    // eslint-disable-next-line
  }, [loadingAuth])

  // Load Escape Rooms
  const loadQuests = async () => {
    try {
      const res = await axios('/api/quests')
      dispatch({
        type: 'QUESTS_LOADED',
        payload: res.data,
      })
    } catch (error) {
      dispatch({
        type: 'QUESTS_FAIL',
        payload: error.message,
      })
    }
  }

  const editQuestOwner = async (questId, form, closeModal) => {
    try {
      await axios.put(`/api/owner/quest/${questId}`, form)
      enqueueSnackbar(messages.admin.quest.updateSuccess, { variant: 'success' })
      loadQuests()
      closeModal()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.quest.updateFail, { variant: 'error' })
      }
    }
  }

  const createQuestOwner = async (form, closeModal) => {
    try {
      const { data } = await axios.post(`/api/owner/quest/create`, form)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadQuests()
      closeModal()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.quest.createFail, { variant: 'error' })
      }
    }
  }

  const editQuestAdmin = async (questId, form, closeModal) => {
    try {
      await axios.put(`/api/admin/quest/${questId}`, form)
      enqueueSnackbar(messages.admin.quest.updateSuccess, { variant: 'success' })
      loadQuests()
      closeModal()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.quest.updateFail, { variant: 'error' })
      }
    }
  }

  return (
    <QuestsContext.Provider
      value={{
        quests: state.quests,
        cities: state.cities,
        loadingQuests: state.loadingQuests,
        errorQuests: state.errorQuests,
        errorMessageQuests: state.errorMessageQuests,
        createQuestOwner,
        editQuestOwner,
        editQuestAdmin,
      }}>
      <ContextDevTool context={QuestsContext} id="quests" displayName="Quests" />
      {children}
    </QuestsContext.Provider>
  )
}

export const useQuests = () => {
  const context = useContext(QuestsContext)

  if (context === undefined) {
    throw new Error('useQuests can only be used inside QuestsProvider')
  }

  return context
}
