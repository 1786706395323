import React from 'react'
import { useParams } from 'react-router-dom'
import 'date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'

const PickDate = ({ form, setForm }) => {
  const params = useParams()
  const isExistingReview = params.id ? true : false

  const handleDateChange = (date) => {
    setForm({ ...form, date })
  }

  return (
    <Grid item xs={12}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disabled={isExistingReview}
          style={{ width: '100%' }}
          autoOk
          required
          margin="normal"
          inputVariant="outlined"
          variant="inline"
          label="Датата, на която игра"
          format="dd/MM/yyyy"
          value={form.date}
          onChange={handleDateChange}
          InputAdornmentProps={{ position: 'start' }}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  )
}

export default React.memo(PickDate)
