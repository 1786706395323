import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { useEscapeRooms } from 'core'

const SelectRoom = ({ setEscapeRoom }) => {
  const { escaperooms } = useEscapeRooms()

  return (
    <Grid item xs={12}>
      <Autocomplete
        options={escaperooms.filter(
          (escaperoom) => !escaperoom.isChosen && !escaperoom.isRandomChosen
        )}
        noOptionsText={'Няма намерени стаи'}
        getOptionLabel={(option) => option.title}
        onChange={(e, room) => setEscapeRoom(room?._id)}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={'Избери стая за добавяне'}
            variant="outlined"
          />
        )}
      />
    </Grid>
  )
}

export default React.memo(SelectRoom)
