import { makeStyles } from '@material-ui/core'
import Resizer from 'react-image-file-resizer'

export const resizeImage = (
  file,
  maxWidth = 1920,
  maxHeight = 1080,
  quality = 100,
  format = 'JPEG'
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      format,
      quality,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export const reviewStyles = makeStyles((theme) => ({
  header: {
    '& .MuiCardHeader-content': {
      textAlign: 'left',
    },

    '& .MuiCardHeader-action': {
      margin: 0,
      alignSelf: 'initial',
    },
  },
  content: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRadius: 4,
    position: 'relative',
    marginBottom: theme.spacing(3),

    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  media: {
    cursor: 'pointer',
    height: 0,
    paddingTop: '90%',
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      paddingTop: '75%',
    },
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: `3px solid ${theme.palette.primary.main}`,
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  review: {
    cursor: 'pointer',
  },
  fullReviewButton: {
    position: 'absolute',
    bottom: -theme.spacing(1.6),
    left: '50%',
    transform: 'translateX(-50%)',

    '& button': {
      backgroundColor: theme.palette.background.default,

      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
}))
