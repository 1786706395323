import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ContactsIcon from '@material-ui/icons/Contacts'
import { Avatar, TableCell, TableRow, Typography, IconButton, Tooltip } from '@material-ui/core'
import { EditModal, AdminModal } from './components'
import { USER_GROUP } from 'helpers'
import { useAuth } from 'core'

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  active: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  inactive: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Row = (props) => {
  const { quest } = props

  const [openEditModal, setOpenEditModal] = useState(false)
  const [openAdminModal, setOpenAdminModal] = useState(false)
  const classes = useRowStyles()
  const { user } = useAuth()

  const isAdmin = user.userGroup === USER_GROUP.admin.id

  const handleClickOpenEditModal = () => {
    setOpenEditModal(true)
  }

  const handleClickOpenAdminModal = () => {
    setOpenAdminModal(true)
  }

  return (
    <>
      <TableRow className={classes.tableRow} hover key={quest._id}>
        <TableCell>
          <div className={classes.nameContainer}>
            <Avatar className={classes.avatar} src={quest.image} />
            <Typography variant="body1">{quest.title}</Typography>
          </div>
        </TableCell>
        <TableCell>{quest.company?.name}</TableCell>
        <TableCell>
          {quest.expiryDate ? moment(quest.expiryDate).format('DD/MM/YYYY') : 'Перманентно'}
        </TableCell>
        <TableCell align="center">
          {quest.people[1] ? `${quest.people[0]} - ${quest.people[1]}` : quest.people[0]}
        </TableCell>
        <TableCell>
          {quest.city}, {quest.address}
        </TableCell>
        <TableCell align="center">{quest.complexity}/5</TableCell>
        <TableCell align="center">{quest.gameLength} мин.</TableCell>
        <TableCell align="center">
          {quest.status ? (
            <CheckIcon className={classes.active} />
          ) : (
            <CancelIcon className={classes.inactive} />
          )}
        </TableCell>
        <TableCell align="right">
          {isAdmin && (
            <Tooltip title="Администрирай">
              <IconButton color="primary" size="small" onClick={handleClickOpenAdminModal}>
                <ContactsIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Редактирай куеста">
            <IconButton color="primary" size="small" onClick={handleClickOpenEditModal}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <EditModal open={openEditModal} setOpen={setOpenEditModal} quest={quest} />
      <AdminModal open={openAdminModal} setOpen={setOpenAdminModal} quest={quest} />
    </>
  )
}

export default Row
