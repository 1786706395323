import React from 'react'
import { makeStyles } from '@material-ui/styles'

const Image = ({ url, borderRadius }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      minHeight: theme.sizing.homePage.escapeRoomNew.height,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius: borderRadius,
    },
  }))
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${url})`,
      }}></div>
  )
}

export default Image
