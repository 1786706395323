import React from 'react'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

const Opinion = ({ form, setForm }) => {
  return (
    <Grid item xs={12}>
      <TextField
        style={{ width: '100%' }}
        label="Твоето мнение"
        multiline
        maxRows={10}
        variant="outlined"
        required
        onChange={(e) => setForm({ ...form, review: e.target.value })}
        helperText="Разкажи какво мислиш за стаята, без да издаваш важна информация"
      />
    </Grid>
  )
}

export default React.memo(Opinion)
