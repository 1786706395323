import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.mainLayout.darkGrey,
    fontFamily: 'Ubuntu',
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}))

const BigHeading = ({ children, className }) => {
  const classes = useStyles()
  return (
    <Typography variant="h1" className={clsx(classes.root, className)}>
      {children}
    </Typography>
  )
}

export default BigHeading
