import React, { useState, useEffect } from 'react'
import {
  Typography,
  Button,
  FormHelperText,
  FormControl,
  FormLabel,
  Switch,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
} from '@material-ui/core'
import { useEscapeRooms } from 'core'
import 'date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Search } from './components'
import { InPageLoading } from 'components'
import DateFnsUtils from '@date-io/date-fns'
import axios from 'axios'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ setOpen, open, escaperoom }) {
  const { editEscapeRoomAdmin } = useEscapeRooms()

  const [isLoading, setIsLoading] = useState(false)
  const [currentOwner, setCurrentOwner] = useState(null)
  const [form, setForm] = useState({
    expiryDate: escaperoom.expiryDate,
    status: escaperoom.status,
    owner: escaperoom.owner,
  })

  useEffect(() => {
    const handleGetUser = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(`/api/admin/user/id/${form.owner}`)
        data !== '' ? setCurrentOwner(data) : setCurrentOwner('deleted')
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }

    open && form.owner !== undefined && handleGetUser()
  }, [form.owner, open])

  const toggleChecked = () => {
    setForm({ ...form, status: !form.status })
  }

  const handleDateChange = (expiryDate) => {
    setForm({ ...form, expiryDate })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleEdit = async () => {
    setIsLoading(true)
    editEscapeRoomAdmin(escaperoom._id, form, handleClose)
    setIsLoading(false)
  }

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      {isLoading && <InPageLoading />}
      <DialogTitle>Администрирай стаята</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Достъпност
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                autoOk
                margin="normal"
                inputVariant="outlined"
                variant="inline"
                label="Валидна до"
                format="dd/MM/yyyy"
                helperText="Ако датата на валидност е преди днешната, то стаята няма да се показва в сайта"
                value={form.expiryDate}
                onChange={handleDateChange}
                InputAdornmentProps={{ position: 'start' }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} lg={4}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Статус</FormLabel>
              <Box display="flex" alignItems="center" mt={2} mb={1}>
                <Typography variant="overline">Неактивна</Typography>
                <Switch size="small" checked={form.status} onChange={toggleChecked} />
                <Typography variant="overline">Активна</Typography>
              </Box>
              <FormHelperText>
                Ако статусът на стаята е "неактивна", то тя няма да се показва на сайта, дори и
                датата й да е валидна
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Собственик на стаята
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {currentOwner?.email
                ? `Сегашен собственик: ${currentOwner.email}`
                : currentOwner === 'deleted'
                ? 'Потребителят вече не е в базата данни'
                : 'Не е избран'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Избери нов собственик
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Search setForm={setForm} form={form} setIsLoading={setIsLoading} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEdit} color="primary" disabled={isLoading}>
          Запази
        </Button>
        <Button onClick={handleClose}>Отмени</Button>
      </DialogActions>
    </Dialog>
  )
}
