import React, { useEffect, useState } from 'react'
import { makeStyles, Grid, Typography, CircularProgress, Link } from '@material-ui/core'
import axios from 'axios'
import { useAuth } from 'core'
import { useSnackbar } from 'notistack'
import { useParams, useHistory, Link as RouterLink } from 'react-router-dom'
import { displayUserRole, USER_GROUP } from 'helpers'
import { FavoriteRooms } from './components'
import { PageURLs } from 'Routes'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  root: {},
  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  link: {
    color: '#263238',
  },
}))

const UserDetails = (props) => {
  const { isOwnProfile, setIsLoading, isLoading } = props
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const profileId = params.profileId
  const { user, isAuthenticated } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const url =
    isAuthenticated &&
    (user.userGroup === USER_GROUP.admin.id || user.userGroup === USER_GROUP.owner.id)
      ? `/api/owner/user/${profileId}`
      : `/api/user/${profileId}`

  const [profile, setProfile] = useState(user)

  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true)

      try {
        const { data } = await axios(url)
        setProfile(data)
        setIsLoading(false)
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
        setIsLoading(false)
        history.push('/')
      }
    }

    !isOwnProfile ? getProfile() : setProfile(user)
    // eslint-disable-next-line
  }, [isOwnProfile, params])

  return (
    <Grid item xs={12} className={classes.root}>
      {!isLoading ? (
        profile !== null && (
          <Grid container spacing={2}>
            <Helmet>
              <title>{profile.name} | Всички стаи</title>
            </Helmet>
            <Grid item xs={12} lg={2}>
              <img width={200} height={200} src={profile.avatar || '/image/no-image.png'} alt="" />
            </Grid>
            <Grid item xs={12} lg={6} className={classes.info}>
              <div>
                <Typography variant="h4">
                  <Link
                    className={classes.link}
                    to={`${PageURLs.Profile}/${profile._id}`}
                    component={RouterLink}>
                    {profile.name}
                  </Link>{' '}
                  {profile.email && `(${profile.email})`}{' '}
                  {isOwnProfile && (
                    <Link
                      style={{ fontSize: 12 }}
                      to={`${PageURLs.Profile}/${profile._id}/settings`}
                      component={RouterLink}>
                      Редактирай профила
                    </Link>
                  )}
                </Typography>
                <Typography gutterBottom color="primary" variant="overline">
                  {displayUserRole(profile.userGroup)}
                </Typography>
              </div>

              <Typography variant="h5">{profile.bio}</Typography>
              <span />
            </Grid>
            {isOwnProfile && (
              <Grid item xs={12} lg={4}>
                {profile.favoriteRooms.length > 0 ? (
                  <>
                    <Typography variant="h4">Любими стаи:</Typography>
                    <FavoriteRooms
                      profileFavoriteRooms={profile.favoriteRooms}
                      isOwnProfile={isOwnProfile}
                    />
                  </>
                ) : (
                  <Typography variant="h4">Не е избрал любими стаи</Typography>
                )}
              </Grid>
            )}
          </Grid>
        )
      ) : (
        <CircularProgress />
      )}
    </Grid>
  )
}

export default UserDetails
