import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { useAuth } from 'core'

import { ExistingCompany, CreateCompany } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const Account = () => {
  const classes = useStyles()
  const { user } = useAuth()

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CreateCompany />
        </Grid>
        {user.companies.length ? (
          <>
            <Grid item xs={12}>
              <Typography>Съществуващи компании</Typography>
            </Grid>
            {user.companies.map((company) => (
              <Grid key={company.name} item lg={4} md={6} xl={4} xs={12}>
                <ExistingCompany company={company} />
              </Grid>
            ))}
          </>
        ) : (
          ''
        )}
      </Grid>
    </div>
  )
}

export default Account
