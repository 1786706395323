import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 18,
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}))

const SmallHeading = ({ children, className }) => {
  const classes = useStyles()
  return (
    <Typography variant="h6" component="h2" className={clsx(classes.root, className)}>
      {children}
    </Typography>
  )
}

export default SmallHeading
