import React, { useState } from 'react'
import { SearchHeading, SelectCity } from './components'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { PageURLs } from 'Routes'
import { useEscapeRooms } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'column',
    justifyContent: 'center',
    background: theme.palette.white,

    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))

const Search = () => {
  const classes = useStyles()
  const history = useHistory()
  const { cities, changeMainCity } = useEscapeRooms()

  const [defaultCity, setDefaultCity] = useState('nocity')

  const handleChangeCity = (e) => {
    changeMainCity(e.target.value)
    setDefaultCity(e.target.value)
  }

  return (
    <div className={classes.root}>
      <SearchHeading searchText="Намери ескейп стая в">
        <SelectCity value={defaultCity} onChange={handleChangeCity} helperText=".">
          {cities.map((option) => (
            <MenuItem
              key={option}
              value={option}
              onClick={() => history.push(PageURLs.EscapeRooms)}>
              {option}
            </MenuItem>
          ))}
        </SelectCity>
      </SearchHeading>
    </div>
  )
}

export default React.memo(Search)
