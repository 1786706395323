import React from 'react'
import { Typography, Grid, Paper, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useEscapeRooms } from 'core'
import { PageURLs } from 'Routes'
import { ComponentTitle } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1.5),
    width: '100%',
    cursor: 'pointer',
    height: 0,
    paddingTop: '56.25%', // 16:9
    position: 'relative',
    display: 'block',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    '& p': {
      position: 'absolute',
      bottom: theme.spacing(2),
      left: theme.spacing(2),
      color: theme.palette.white,
    },
  },
  imageHolder: {
    maxWidth: 300,
    minWidth: 200,
    width: '100%',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  grid: {
    display: 'flex',
    paddingTop: '0 !important',
    flexWrap: 'wrap',
  },
}))

const OtherEscapeRooms = ({ company, escaperoomId }) => {
  const classes = useStyles()
  const { escaperooms } = useEscapeRooms()

  const otherEscapeRooms = escaperooms.filter(
    (escaperoom) => escaperoom.company?.name === company && escaperoom._id !== escaperoomId
  )

  return (
    otherEscapeRooms.length >= 1 && (
      <>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <ComponentTitle>Други стаи от {company}</ComponentTitle>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          {otherEscapeRooms
            .filter((room) => room.status)
            .map((room) => (
              <Link
                key={room._id}
                to={`${PageURLs.EscapeRooms}/${room.shortname}`}
                className={classes.imageHolder}>
                <Paper
                  className={classes.root}
                  style={{ backgroundImage: `url(${room.thumbnail})` }}>
                  <Typography component="p" variant="h4">
                    {room.title}
                  </Typography>
                </Paper>
              </Link>
            ))}
        </Grid>
      </>
    )
  )
}

export default OtherEscapeRooms
