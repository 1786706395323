import React from 'react'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  OutlinedInput,
  Button,
  InputAdornment,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  ViberIcon,
  WhatsappIcon,
} from 'react-share'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  icon: { margin: theme.spacing(1) },
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const CustomDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton size="small" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

const Share = (props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    onClose,
    open,
    title = 'https://vsichkistai.bg/ - Най-мащабният сайт за ескейп стаи в България',
    shareUrl = '/',
    mainUrl = 'https://vsichkistai.bg',
  } = props

  const buttonSize = 60

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <CustomDialogTitle onClose={handleClose}>Сподели</CustomDialogTitle>
      <DialogContent>
        <FacebookShareButton className={classes.icon} url={mainUrl + shareUrl} quote={title}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <FacebookIcon size={buttonSize} round />
            <Typography>Facebook</Typography>
          </Box>
        </FacebookShareButton>

        <FacebookMessengerShareButton
          className={classes.icon}
          url={mainUrl + shareUrl}
          appId="361333338300005">
          <Box display="flex" flexDirection="column" alignItems="center">
            <FacebookMessengerIcon size={buttonSize} round />
            <Typography>Messenger</Typography>
          </Box>
        </FacebookMessengerShareButton>

        <ViberShareButton className={classes.icon} url={mainUrl + shareUrl} title={title}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <ViberIcon size={buttonSize} round />
            <Typography>Viber</Typography>
          </Box>
        </ViberShareButton>

        <WhatsappShareButton
          className={classes.icon}
          url={mainUrl + shareUrl}
          title={title}
          separator=":: ">
          <Box display="flex" flexDirection="column" alignItems="center">
            <WhatsappIcon size={buttonSize} round />
            <Typography>Whatsapp</Typography>
          </Box>
        </WhatsappShareButton>

        <EmailShareButton className={classes.icon} url={mainUrl + shareUrl} subject={title} body="">
          <Box display="flex" flexDirection="column" alignItems="center">
            <EmailIcon size={buttonSize} round />
            <Typography>Email</Typography>
          </Box>
        </EmailShareButton>
      </DialogContent>
      <DialogContent style={{ marginTop: 16, width: '100%', marginBottom: 8 }}>
        <OutlinedInput
          readOnly
          defaultValue={mainUrl + shareUrl}
          style={{ width: '100%' }}
          endAdornment={
            <InputAdornment position="end">
              <Button
                onClick={() => {
                  enqueueSnackbar('Линкът е копиран в клипборда')
                  navigator.clipboard.writeText(mainUrl + shareUrl)
                }}
                edge="end">
                Копирай
              </Button>
            </InputAdornment>
          }
        />
      </DialogContent>
    </Dialog>
  )
}

export default Share
