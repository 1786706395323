import React from 'react'
import { makeStyles, Card, Typography, Avatar, Box, Button } from '@material-ui/core'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.mainLayout.divider,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    position: 'relative',
  },
  header: {
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
  icon: {
    fontSize: '4.5rem',
    position: 'absolute',
    top: theme.spacing(1.5),
    color: '#c1c1c1',
    left: theme.spacing(1),
  },
}))

const Reply = ({ setDetails, comment, setComment, details = null }) => {
  const classes = useStyles()

  return (
    details && (
      <Box mt={2}>
        <Box mb={1}>
          <Typography color="primary" variant="overline">
            Отговори на {details.owner.name}
          </Typography>
          <Button
            onClick={() => {
              setDetails(null)
              setComment({ ...comment, mention: null })
            }}
            style={{ marginLeft: 8 }}
            variant="outlined"
            size="small">
            <Typography variant="overline">Отмени отговора</Typography>
          </Button>
        </Box>
        <Card className={classes.root} elevation={0}>
          <FormatQuoteIcon className={classes.icon} />
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar style={{ marginRight: 8 }} src={details.owner.avatar} />{' '}
            <Typography variant="h6">{details.owner.name} написа:</Typography>
          </Box>
          <Typography>{details.text}</Typography>
        </Card>
      </Box>
    )
  )
}

export default Reply
