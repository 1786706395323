import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import {
  Header,
  MainInformation,
  AdditionalInformation,
  Reviews,
  OtherEscapeRooms,
  LegacyReviews,
} from './components'
import { useEscapeRooms } from 'core'
import { Helmet } from 'react-helmet'
import { PageURLs } from 'Routes'
import { useFetchData } from 'helpers'
import { messages } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  grid: {
    maxWidth: theme.sizing.main.contentWidth,
    width: '100%',
    marginTop: theme.spacing(1),
  },
}))

const EscapeRoom = () => {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const { escaperooms } = useEscapeRooms()
  const escaperoom = escaperooms.find((escaperoom) => escaperoom.shortname === params.shortName)

  const [reviews, setReviews] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [filter, setFilter] = useState('latest')

  const [data, isLoading, isError, setErrorMessage] = useFetchData(
    escaperoom ? `/api/reviews/${escaperoom?._id}?page=${page}&filter=${filter}` : null
  )

  useEffect(() => {
    page !== 1 && setPage(1)
    // eslint-disable-next-line
  }, [filter])

  useEffect(() => {
    setErrorMessage(messages.review.failurePlural)
    if (!isLoading) {
      setReviews(data.reviews)
      setTotalPages(data.totalPages)
    }
    // eslint-disable-next-line
  }, [data, isLoading, filter])

  return escaperoom ? (
    <div className={classes.root}>
      <Helmet>
        <title>{`${escaperoom.title} | Всички стаи`}</title>
        <meta property="og:title" content={`${escaperoom.title} | Всички стаи`} />
        <meta property="og:description" content={escaperoom.description} />
        <meta property="og:image" content={`https://vsichkistai.bg${escaperoom.image}`} />
        <meta
          property="og:url"
          content={`https://vsichkistai.bg/escape-rooms/${escaperoom.shortname}`}
        />
      </Helmet>
      <React.Fragment key={escaperoom._id}>
        <Header cover={escaperoom.image} logo={escaperoom.company?.logo} title={escaperoom.title} />
        <Grid container className={classes.grid} spacing={5}>
          <MainInformation
            complexity={escaperoom.complexity}
            people={escaperoom.people}
            gameLength={escaperoom.gameLength}
            description={escaperoom.description}
            city={escaperoom.city}
            address={escaperoom.address}
            images={escaperoom.gallery}
            escaperoomId={escaperoom._id}
            site={escaperoom.site}
            favorite={escaperoom.favorite}
          />
          <AdditionalInformation
            escaperoomId={escaperoom._id}
            site={escaperoom.site}
            email={escaperoom.email}
            phone={escaperoom.phone}
            facebook={escaperoom.facebook}
            messenger={escaperoom.messenger}
            tripadvisor={escaperoom.tripadvisor}
            instagram={escaperoom.instagram}
            twitter={escaperoom.twitter}
            city={escaperoom.city}
            address={escaperoom.address}
            ageRestriction={escaperoom.ageRestriction}
            alerts={escaperoom.alerts}
            genres={escaperoom.genres}
          />
        </Grid>
        <Grid container className={classes.grid} spacing={5}>
          <Reviews
            title={escaperoom.title}
            reviews={reviews}
            totalPages={totalPages}
            page={page}
            setPage={setPage}
            setFilter={setFilter}
            isLoading={isLoading}
            isError={isError}
            filter={filter}
          />
        </Grid>
        <Grid container className={classes.grid} spacing={5}>
          <OtherEscapeRooms escaperoomId={escaperoom._id} company={escaperoom.company?.name} />
        </Grid>
        <Grid container className={classes.grid} spacing={5}>
          <LegacyReviews
            title={escaperoom.title}
            totalPagesNewReviews={totalPages}
            newReviews={reviews}
          />
        </Grid>
      </React.Fragment>
      {!escaperoom.status && <Redirect to="/escape-rooms" />}
    </div>
  ) : (
    history.push(PageURLs.EscapeRooms)
  )
}

export default React.memo(EscapeRoom)
