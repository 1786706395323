import React, { useState, useRef } from 'react'
import { Box, Button, Grid, CircularProgress } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ExistingPhoto } from '..'
import axios from 'axios'
import { resizeImage } from 'helpers'

const UploadPhoto = ({ form, setForm }) => {
  const photo = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [uploading, setUploading] = useState(false)

  const handleUploadFile = async (e) => {
    const originalFile = e.target.files[0]
    const resizedFile = await resizeImage(originalFile, 1600, 1080, 90)
    const formData = new FormData()
    formData.append('file', resizedFile)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/auth/upload', formData, config)

      setForm({ ...form, file: data })
      setUploading(false)
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: 'error' })
      setUploading(false)
    }
  }

  return (
    <Grid item xs={12}>
      {form.file !== '' && <ExistingPhoto form={form} />}
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Button component="label" variant="outlined" disabled={uploading}>
          {uploading && <CircularProgress />} Качи снимка от играта *
          <input
            ref={photo}
            accept="image/*"
            type="file"
            onChange={handleUploadFile}
            style={{ display: 'none' }}
          />
        </Button>
      </Box>
    </Grid>
  )
}

export default React.memo(UploadPhoto)
