import React, { useState, useEffect } from 'react'
import { makeStyles, Typography, Box, Avatar, IconButton, Tooltip } from '@material-ui/core'
import { useAuth, useEscapeRooms } from 'core'
import { Link } from 'react-router-dom'
import { PageURLs } from 'Routes'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 100,
    height: 100,
    marginRight: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  room: {
    height: 60,
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  },
}))

const FavoriteRooms = ({ profileFavoriteRooms, isOwnProfile }) => {
  const classes = useStyles()
  const { removeFavoriteRoom } = useAuth()
  const { escaperooms } = useEscapeRooms()

  const [roomsFullInfo, setRoomsFullInfo] = useState([])

  useEffect(() => {
    profileFavoriteRooms.length &&
      setRoomsFullInfo(
        escaperooms.filter((escaperoom) => profileFavoriteRooms.includes(escaperoom._id))
      )
  }, [profileFavoriteRooms, escaperooms])

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column">
        {roomsFullInfo.map((favoriteRoom) => (
          <Box
            key={favoriteRoom.title}
            className={classes.room}
            style={{ backgroundImage: `url(${favoriteRoom.image})` }}>
            <Box display="flex" alignItems="center">
              <Avatar src={favoriteRoom.company.logo} style={{ marginRight: 16 }} />
              <Typography variant="h4">
                <Link
                  style={{ color: 'white' }}
                  to={`${PageURLs.EscapeRooms}/${favoriteRoom.shortname}`}>
                  {favoriteRoom.title}
                </Link>
              </Typography>
            </Box>
            {isOwnProfile && (
              <div>
                <Tooltip title="Премахни от любими" placement="left">
                  <IconButton
                    size="small"
                    onClick={() => removeFavoriteRoom(favoriteRoom._id)}
                    style={{ color: 'white' }}>
                    <VisibilityOffIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </Box>
        ))}
      </Box>
    </div>
  )
}

export default React.memo(FavoriteRooms)
