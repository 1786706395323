import React from 'react'
import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { PageURLs } from 'Routes'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  link: { color: theme.palette.black },
}))

const UserRecommendation = ({ team, userId, score, room }) => {
  const classes = useStyles()

  return (
    <Alert className={classes.root} severity={'info'}>
      {userId ? (
        <>
          <Link className={classes.link} to={`${PageURLs.Profile}/${userId}`}>
            {team}
          </Link>{' '}
          оцени с {score}
        </>
      ) : (
        <span>
          {team} оцени с {score}
        </span>
      )}
    </Alert>
  )
}

export default React.memo(UserRecommendation)
