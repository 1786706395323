import React from 'react'
import { Search, EscapeRooms, LatestReviews, News } from './components'
import { CurvedSeparator } from 'layouts/Main/components'
import { useTheme } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'
import { Helmet } from 'react-helmet'

const Home = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  return (
    <>
      <Helmet>
        <title>Всички Стаи | Сайтът за ескейп стаи в България</title>
      </Helmet>
      <Search />
      <CurvedSeparator height={isDesktop ? 80 : 60} />
      <EscapeRooms />
      <CurvedSeparator
        topColor={theme.palette.background.default}
        bottomColor={theme.palette.white}
        height={60}
      />
      <News />
      <LatestReviews />
    </>
  )
}

export default React.memo(Home)
