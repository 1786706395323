import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import clsx from 'clsx'
import { PageURLs } from 'Routes'
import { Link } from 'react-router-dom'
import { useAuth, useReviews } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    height:
      theme.sizing.homePage.escapeRoom.width -
      theme.sizing.homePage.escapeRoom.additionalInformationHeight,
  },
  loved: {
    '&:hover': {
      backgroundColor: `${theme.palette.white} !important`,
    },
  },
}))

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const CircleButton = withStyles((theme) => ({
  root: {
    marginBottom: 5,
    color: theme.palette.white,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(IconButton)

const Buttons = ({ className, escaperoomId }) => {
  const classes = useStyles()
  const { addFavoriteRoom, removeFavoriteRoom, user, isAuthenticated } = useAuth()
  const { allReviewedRooms, allReviews } = useReviews()
  const favoriteRooms = user?.favoriteRooms
  const isEscapeRoomPlayed = allReviewedRooms.includes(escaperoomId)
  const myReview = allReviews.find((review) => review.roomId === escaperoomId)?._id

  return (
    <div className={clsx(className, classes.root)}>
      {!isEscapeRoomPlayed ? (
        <LightTooltip title="Напиши отзив" placement="left">
          <Link
            to={{
              pathname: `${PageURLs.CreateReview}`,
              state: { id: escaperoomId },
            }}>
            <CircleButton>
              <RateReviewOutlinedIcon fontSize="small" />
            </CircleButton>
          </Link>
        </LightTooltip>
      ) : isAuthenticated ? (
        <LightTooltip title="Виж ревюто си" placement="left">
          <Link
            to={{
              pathname: `${PageURLs.Profile}/${user?._id}/review/${myReview}`,
              state: { id: escaperoomId },
            }}>
            <CircleButton>
              <RateReviewOutlinedIcon fontSize="small" />
            </CircleButton>
          </Link>
        </LightTooltip>
      ) : (
        <LightTooltip title="Напиши отзив" placement="left">
          <Link
            to={{
              pathname: `${PageURLs.CreateReview}`,
              state: { id: escaperoomId },
            }}>
            <CircleButton>
              <RateReviewOutlinedIcon fontSize="small" />
            </CircleButton>
          </Link>
        </LightTooltip>
      )}
      {isAuthenticated ? (
        <LightTooltip
          title={favoriteRooms?.includes(escaperoomId) ? 'Премахни от любими' : 'Добави в любими'}
          placement="left">
          {!favoriteRooms?.includes(escaperoomId) ? (
            <CircleButton
              onClick={() => {
                addFavoriteRoom(escaperoomId)
              }}>
              <FavoriteBorderIcon fontSize="small" />
            </CircleButton>
          ) : (
            <CircleButton
              className={classes.loved}
              onClick={() => {
                removeFavoriteRoom(escaperoomId)
              }}>
              <FavoriteIcon color="primary" fontSize="small" />
            </CircleButton>
          )}
        </LightTooltip>
      ) : (
        <Link to={PageURLs.Login}>
          <LightTooltip title={'Добави в любими'} placement="left">
            <CircleButton>
              <FavoriteBorderIcon fontSize="small" />
            </CircleButton>
          </LightTooltip>
        </Link>
      )}
    </div>
  )
}

export default Buttons
