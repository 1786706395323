import React, { useState } from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { useEscapeRooms } from 'core'
import { Alert } from '@material-ui/lab'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ setOpen, open, escaperoom, title, alerts }) {
  const { removeAlert, addAlert } = useEscapeRooms()
  const theme = useTheme()

  const [newAlert, setNewAlert] = useState({
    message: 'Текст',
    severity: 'success',
  })

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (e) => {
    setNewAlert({ ...newAlert, [e.target.name]: e.target.value })
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Редактирай съобщенията на {title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Добави ново съобщение
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              name="message"
              label="Напиши текст"
              value={newAlert.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              variant="outlined"
              size="small"
              name="severity"
              label="Избери цвят"
              select
              value={newAlert.severity}
              onChange={handleChange}>
              <MenuItem value="success">
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: theme.palette.success.main,
                    color: 'transparent',
                  }}>
                  block el
                </div>
              </MenuItem>
              <MenuItem value="info">
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: theme.palette.info.main,
                    color: 'transparent',
                  }}>
                  block el
                </div>
              </MenuItem>
              <MenuItem value="warning">
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: theme.palette.warning.main,
                    color: 'transparent',
                  }}>
                  block el
                </div>
              </MenuItem>
              <MenuItem value="error">
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    background: theme.palette.error.main,
                    color: 'transparent',
                  }}>
                  block el
                </div>
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">Предварителен преглед на новото съобщение</Typography>
            <Alert severity={newAlert.severity}>{newAlert.message}</Alert>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                addAlert(escaperoom._id, newAlert)
              }}>
              Добави съобщението
            </Button>
          </Grid>
          {alerts.length ? (
            <Grid item xs={12}>
              <Typography color="primary" variant="overline">
                Съществуващи съобщения
              </Typography>
            </Grid>
          ) : (
            ''
          )}
          {alerts.map((alert) => {
            return (
              <Grid key={alert.message} item xs={12}>
                <Alert severity={alert.severity}>{alert.message}</Alert>
                <Button
                  style={{ marginTop: 4 }}
                  variant="contained"
                  size="small"
                  onClick={() => removeAlert(escaperoom._id, alert)}>
                  Изтрий съобщението
                </Button>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
