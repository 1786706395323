import React, { createContext, useReducer, useContext, useEffect } from 'react'
import { ContextDevTool } from 'react-context-devtool'
import { messages } from 'utils'
import reducer from './reducer'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useAuth } from '../AuthContext'
import { USER_GROUP } from 'helpers'

const EscapeRoomsContext = createContext([])

const initialState = {
  loadingEscapeRooms: true,
  errorEscapeRooms: false,
  errorMessageEscapeRooms: null,
  mainCity: 'София',
  escaperooms: [],
  chosenRooms: [],
  randomChosenRooms: [],
  cities: [],
}

export const EscapeRoomsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { enqueueSnackbar } = useSnackbar()
  const { loadingAuth, isAuthenticated, user } = useAuth()

  useEffect(() => {
    !loadingAuth && loadEscapeRooms()
    // eslint-disable-next-line
  }, [loadingAuth])

  // Load Escape Rooms
  const loadEscapeRooms = async () => {
    if (
      isAuthenticated &&
      (user.userGroup === USER_GROUP.owner.id || user.userGroup === USER_GROUP.admin.id)
    ) {
      try {
        const res = await axios('/api/owner/rooms')
        dispatch({
          type: 'ESCAPE_ROOMS_LOADED',
          payload: res.data,
        })
      } catch (error) {
        dispatch({
          type: 'ESCAPE_ROOMS_FAIL',
          payload: error.message,
        })
      }
    } else {
      try {
        const res = await axios('/api/rooms')
        dispatch({
          type: 'ESCAPE_ROOMS_LOADED',
          payload: res.data,
        })
      } catch (error) {
        dispatch({
          type: 'ESCAPE_ROOMS_FAIL',
          payload: error.message,
        })
      }
    }
  }

  const editEscapeRoomOwner = async (escaperoomId, form, closeModal) => {
    try {
      await axios.put(`/api/owner/room/${escaperoomId}`, form)
      enqueueSnackbar(messages.admin.escapeRoom.updateSuccess, { variant: 'success' })
      loadEscapeRooms()
      closeModal()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.escapeRoom.updateFail, { variant: 'error' })
      }
    }
  }

  const createEscapeRoomOwner = async (form, closeModal) => {
    try {
      const { data } = await axios.post(`/api/owner/room/create`, form)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadEscapeRooms()
      closeModal()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.escapeRoom.createFail, { variant: 'error' })
      }
    }
  }

  const editEscapeRoomAdmin = async (escaperoomId, form, closeModal) => {
    try {
      await axios.put(`/api/admin/room/${escaperoomId}`, form)
      enqueueSnackbar(messages.admin.escapeRoom.updateSuccess, { variant: 'success' })
      loadEscapeRooms()
      closeModal()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.escapeRoom.updateFail, { variant: 'error' })
      }
    }
  }

  const addBadge = async (escaperoomId, badge) => {
    try {
      const { data } = await axios.put(`/api/owner/room/badge/add/${escaperoomId}`, badge)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadEscapeRooms()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const removeBadge = async (escaperoomId, badge) => {
    try {
      const { data } = await axios.put(`/api/owner/room/badge/remove/${escaperoomId}`, badge)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadEscapeRooms()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const addAlert = async (escaperoomId, alert) => {
    try {
      const { data } = await axios.put(`/api/owner/room/alert/add/${escaperoomId}`, alert)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadEscapeRooms()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const removeAlert = async (escaperoomId, alert) => {
    try {
      const { data } = await axios.put(`/api/owner/room/alert/remove/${escaperoomId}`, alert)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadEscapeRooms()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const changeMainCity = (city) => {
    dispatch({
      type: 'CHANGE_MAIN_CITY',
      payload: city,
    })
  }

  return (
    <EscapeRoomsContext.Provider
      value={{
        escaperooms: state.escaperooms,
        cities: state.cities,
        loadingEscapeRooms: state.loadingEscapeRooms,
        errorEscapeRooms: state.errorEscapeRooms,
        errorMessageEscapeRooms: state.errorMessageEscapeRooms,
        mainCity: state.mainCity,
        chosenRooms: state.chosenRooms,
        randomChosenRooms: state.randomChosenRooms,
        changeMainCity,
        createEscapeRoomOwner,
        editEscapeRoomOwner,
        editEscapeRoomAdmin,
        removeBadge,
        addBadge,
        removeAlert,
        addAlert,
      }}>
      <ContextDevTool context={EscapeRoomsContext} id="escaperooms" displayName="EscapeRooms" />
      {children}
    </EscapeRoomsContext.Provider>
  )
}

export const useEscapeRooms = () => {
  const context = useContext(EscapeRoomsContext)

  if (context === undefined) {
    throw new Error('useEscapeRooms can only be used inside EscapeRoomsProvider')
  }

  return context
}
