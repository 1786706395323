import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, TextField, MenuItem, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}))

const Toolbar = (props) => {
  const { setPage, filter, setFilter, ...rest } = props

  const classes = useStyles()

  const handleChange = (e) => {
    setFilter(e.target.value)
    setPage(1)
  }

  return (
    <div {...rest} className={classes.root}>
      <div className={classes.row}>
        <Typography variant="h3">Игри</Typography>
        <span className={classes.spacer} />
        <Typography style={{ marginRight: 8 }}>Филтър</Typography>
        <TextField onChange={handleChange} value={filter} select size="small" variant="outlined">
          <MenuItem value="all">Всички</MenuItem>
          <MenuItem value="untrusted">Непотвърдени</MenuItem>
          <MenuItem value="unfinished">Недовършени</MenuItem>
        </TextField>
        <Link style={{ marginLeft: 8 }} to="/admin/create-review">
          <Button variant="contained" color="primary">
            Създай игра
          </Button>
        </Link>
      </div>
      <div className={classes.row}></div>
    </div>
  )
}

export default Toolbar
