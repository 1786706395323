import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  IconButton,
  Grid,
  Avatar,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  previewImage: {
    maxWidth: 500,
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
    width: 400,
    height: 400,
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  iconButton: {
    color: theme.palette.white,
  },
}))

const ExistingPhoto = ({ form }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleOpenImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center">
        <Avatar
          alt="Team preview"
          src={form.file}
          className={classes.previewImage}
          onClick={handleOpenImage}
        />

        <Dialog maxWidth={false} open={open} onClose={handleClose}>
          <DialogActions className={classes.actions}>
            <IconButton
              className={classes.iconButton}
              size="small"
              onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <img alt="Team" src={form.file} style={{ maxWidth: '100%' }} />
        </Dialog>
      </Box>
    </Grid>
  )
}

export default React.memo(ExistingPhoto)
