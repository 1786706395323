import React, { useState } from 'react'
import {
  makeStyles,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Button,
  Link,
  Box,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from 'core'
import { USER_GROUP } from 'helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.mainLayout.divider}`,
    marginBottom: theme.spacing(1),
  },
  reply: {
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    position: 'relative',
  },
  replyIcon: {
    fontSize: '2.5rem',
    position: 'absolute',
    top: theme.spacing(1),
    color: '#c1c1c1',
    left: theme.spacing(1),
  },
  header: {
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(1),
  },
}))

const Comment = ({
  allComments,
  initialComment,
  children,
  setComment,
  comment,
  handleDeleteComment,
  handleEditComment,
}) => {
  const classes = useStyles()
  const { user, isAuthenticated } = useAuth()
  const { avatar, name, _id } = initialComment.owner

  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [newComment, setNewComment] = useState(initialComment.text)

  const reply = allComments.find(
    (mentionedComment) => mentionedComment._id === initialComment.mention
  )

  const handleReply = () => {
    setComment({ ...comment, mention: initialComment._id })
  }

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        className={classes.header}
        avatar={<Avatar className={classes.avatar} src={avatar} />}
        title={
          <Link component={RouterLink} to={`/profile/${_id}`}>
            {name}
          </Link>
        }
        subheader={Intl.DateTimeFormat('bg-BG', {
          dateStyle: 'medium',
          timeStyle: 'short',
        }).format(new Date(initialComment.date))}
      />
      <CardContent className={classes.content}>
        {initialComment.mention &&
          (reply ? (
            <Card className={classes.reply} elevation={0}>
              <FormatQuoteIcon className={classes.replyIcon} />
              <Box display="flex" alignItems="center">
                <Typography style={{ marginRight: 4 }} variant="h6">
                  {reply.owner.name} написа:
                </Typography>
                <Typography>{reply.text}</Typography>
              </Box>
            </Card>
          ) : (
            <Card className={classes.reply} elevation={0}>
              <FormatQuoteIcon className={classes.replyIcon} />
              <Box display="flex" alignItems="center">
                <Typography>Цитираният коментар е бил изтрит.</Typography>
              </Box>
            </Card>
          ))}
        <Typography variant="body1">{children}</Typography>
      </CardContent>
      {isAuthenticated && (
        <Button size="small" startIcon={<AlternateEmailIcon />} onClick={handleReply}>
          Отговори
        </Button>
      )}
      {(user?._id === _id || user?.userGroup === USER_GROUP.admin.id) && (
        <>
          <Button size="small" startIcon={<EditIcon />} onClick={() => setOpenEdit(true)}>
            Редактирай
          </Button>
          <Button size="small" startIcon={<DeleteIcon />} onClick={() => setOpenDelete(true)}>
            Изтрий
          </Button>

          <Dialog onClose={() => setOpenEdit(false)} open={openEdit}>
            <DialogTitle>Редактирай коментара си</DialogTitle>
            <DialogContent>
              <TextField
                size="small"
                multiline
                variant="outlined"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={initialComment.text === newComment}
                color="primary"
                onClick={() => {
                  handleEditComment(initialComment._id, { text: newComment })
                  setOpenEdit(false)
                }}>
                Редактирай
              </Button>
              <Button onClick={() => setOpenEdit(false)}>Отмени</Button>
            </DialogActions>
          </Dialog>

          <Dialog onClose={() => setOpenDelete(false)} open={openDelete}>
            <DialogTitle>Изтрий коментара</DialogTitle>
            <DialogContent>
              <Typography>Сигурен ли си, че искаш да изтриеш коментара?</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => {
                  handleDeleteComment(initialComment._id)
                  setOpenDelete(false)
                }}>
                Изтрий
              </Button>
              <Button onClick={() => setOpenDelete(false)}>Отмени</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Card>
  )
}

export default Comment
