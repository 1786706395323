import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import {
  // AdditionalInformation,
  // Badges as Badge,
  MainInformation,
  Image,
  // Buttons,
} from './components'
import { Link } from 'react-router-dom'
import { PageURLs } from 'Routes'
import { Grid } from '@material-ui/core'

const borderRadius = 10
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: theme.sizing.homePage.escapeRoomNew.height,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    borderRadius: borderRadius,

    '&:hover $additionalInformation, &:hover $buttons': {
      display: 'flex',
      opacity: 1,
      transition: 'all 0.3s',
    },

    '&:hover $badge': {
      opacity: 0,
      transition: 'all 0.3s',
    },
  },
  additionalInformation: {
    opacity: 0,
    transition: 'all 0.3s',

    [theme.breakpoints.down('lg')]: {
      opacity: 0,
    },
  },
  buttons: {
    opacity: 0,
    transition: 'all 0.3s',

    [theme.breakpoints.down('lg')]: {
      opacity: 1,
    },
  },
  badge: {
    opacity: 1,
    transition: 'all 0.3s',
  },
  badgesWrapper: {
    position: 'absolute',
    top: theme.spacing(2),
    left: -theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

const EscapeRoom = ({ escaperoom }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper className={classes.root} elevation={3}>
        <Link to={`${PageURLs.EscapeRooms}/${escaperoom.shortname}`}>
          {/* <div className={classes.badgesWrapper}>
            {escaperoom.badges.map((badge) => (
              <Badge
                key={badge.label}
                className={classes.badge}
                label={badge.label}
                backgroundColor={badge.color}
                textColor={badge.textColor}
              />
            ))}
          </div> */}
          <Image url={escaperoom.thumbnail} borderRadius={borderRadius} />
          {/* <AdditionalInformation
            className={classes.additionalInformation}
            players={escaperoom.people}
            complexity={escaperoom.complexity}
            time={escaperoom.gameLength}
          /> */}
          <MainInformation title={escaperoom.title} />
        </Link>
        {/* <Buttons className={classes.buttons} escaperoomId={escaperoom._id} /> */}
      </Paper>
    </Grid>
  )
}

export default React.memo(EscapeRoom)
