import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Chip from '@material-ui/core/Chip'

const Badges = ({ label, textColor = 'white', backgroundColor = null, className }) => {
  const useStyles = makeStyles((theme) => ({
    chip: {
      textTransform: 'uppercase',
      fontSize: 10,
      fontWeight: 500,
      position: 'relative',
      border: 0,
      height: 18,
      zIndex: 2,
      color: textColor,

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        transform: 'skew(-20deg)',
        background: backgroundColor ? backgroundColor : theme.palette.primary.main,
        borderRadius: 2.5,
        zIndex: -1,
      },
    },
  }))

  const classes = useStyles()

  return (
    <div className={className}>
      <div>
        <Chip size="small" variant="outlined" className={classes.chip} label={label} />
      </div>
    </div>
  )
}

export default Badges
