import React, { useState, useRef } from 'react'
import { Box, Button, Grid, CircularProgress, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ExistingPhoto } from '..'
import axios from 'axios'
import { resizeImage } from 'helpers'

const UploadGallery = ({ form, setForm }) => {
  const photo = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [uploading, setUploading] = useState(false)

  const handleUploadFile = async (e) => {
    const file = e.target.files

    let gallery = [...form.gallery]

    for (let i = 0; i < file.length; i++) {
      const formData = new FormData()
      formData.append('gallery', await resizeImage(file[i], 1600, 900, 100))
      setUploading(true)

      try {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const { data } = await axios.post('/api/owner/upload/gallery', formData, config)

        gallery.push(data)

        setUploading(false)
      } catch (error) {
        enqueueSnackbar(error.response.data, { variant: 'error' })
        setUploading(false)
      }
    }

    setForm({ ...form, gallery })
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        style={{ position: 'relative', paddingTop: 48, border: '1px dashed gray', minHeight: 128 }}>
        {form.gallery.length ? (
          <Box display="flex" flexWrap="wrap">
            {form.gallery.map((image, index) => (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                key={index}>
                <ExistingPhoto rectangle width={330} height={250} image={image} />
                <Button
                  size="small"
                  onClick={() =>
                    setForm({ ...form, gallery: form.gallery.filter((item) => item !== image) })
                  }>
                  Изтрий снимката
                </Button>
              </Box>
            ))}
          </Box>
        ) : (
          ''
        )}
        <Typography>Препоръчителна резолюция на изображенията: 16x9</Typography>
        <Button
          component="label"
          variant="outlined"
          disabled={uploading}
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            top: 32,
            left: '50%',
            transform: 'translateX(-50%)',
          }}>
          {uploading && <CircularProgress />} Качи галерия
          <input
            ref={photo}
            multiple
            accept="image/*"
            type="file"
            onChange={handleUploadFile}
            style={{ display: 'none' }}
          />
        </Button>
      </Box>
    </Grid>
  )
}

export default React.memo(UploadGallery)
