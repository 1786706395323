import React, { useState, useRef } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import {
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Avatar,
  Box,
  TextField,
  MenuItem,
} from '@material-ui/core'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { resizeImage, USER_GROUP } from 'helpers'
import { isEqual } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.info.main,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    width: 100,
    height: 100,
    marginRight: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
}))

const EditUser = ({ user, setInitialUser }) => {
  const classes = useStyles()
  const photo = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [form, setForm] = useState(user)
  const [uploading, setUploading] = useState(false)

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleUploadFile = async (e) => {
    const originalFile = e.target.files[0]
    const resizedFile = await resizeImage(originalFile, 400, 400, 90)
    const formData = new FormData()
    formData.append('file', resizedFile)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/auth/upload', formData, config)

      setForm({ ...form, avatar: data })
      setUploading(false)
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: 'error' })
      setUploading(false)
    }
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.post(`/api/admin/user/${user._id}`, form)
      enqueueSnackbar('Потребителят е успешно редактиран', { variant: 'success' })
      setInitialUser({
        ...user,
        name: data.user.name,
        phone: data.user.phone,
        avatar: data.user.avatar,
        email: data.user.email,
        userGroup: data.user.userGroup,
        isEnabled: data.user.isEnabled,
      })
      setForm({
        ...user,
        name: data.user.name,
        phone: data.user.phone,
        avatar: data.user.avatar,
        email: data.user.email,
        userGroup: data.user.userGroup,
        isEnabled: data.user.isEnabled,
      })
      setIsLoading(false)
      handleClose()
    } catch (error) {
      enqueueSnackbar('Потребителят не беше редактиран', { variant: 'error' })
      setIsLoading(false)
      handleClose()
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setForm(user)
  }

  return (
    <>
      <IconButton size="small" onClick={handleClickOpen}>
        <EditIcon className={classes.root} />
      </IconButton>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Редактирай потребител "{user.name}"</DialogTitle>
        <DialogContent className={classes.content}>
          <Box display="flex" alignItems="center" className={classes.field}>
            <Avatar className={classes.avatar} src={user.avatar} />
            <Button component="label" variant="outlined" disabled={uploading} size="small">
              {uploading && <CircularProgress />} Качи друга снимка
              <input
                ref={photo}
                accept="image/*"
                type="file"
                onChange={handleUploadFile}
                style={{ display: 'none' }}
              />
            </Button>
          </Box>
          <TextField
            size="small"
            defaultValue={user.name ?? ''}
            variant="outlined"
            name="name"
            label="Име"
            onChange={handleChange}
            className={classes.field}
          />
          <TextField
            size="small"
            defaultValue={user.phone ?? ''}
            variant="outlined"
            name="phone"
            label="Телефон"
            onChange={handleChange}
            className={classes.field}
          />
          <TextField
            size="small"
            defaultValue={user.email ?? ''}
            variant="outlined"
            name="email"
            label="Имейл"
            onChange={handleChange}
            className={classes.field}
          />
          <TextField
            size="small"
            select
            defaultValue={user.userGroup}
            variant="outlined"
            name="userGroup"
            label="Роля"
            onChange={handleChange}
            className={classes.field}>
            <MenuItem value={USER_GROUP.admin.id}>{USER_GROUP.admin.name}</MenuItem>
            <MenuItem value={USER_GROUP.owner.id}>{USER_GROUP.owner.name}</MenuItem>
            <MenuItem value={USER_GROUP.user.id}>{USER_GROUP.user.name}</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isLoading || isEqual(form, user)}
            onClick={handleSubmit}>
            {isLoading && <CircularProgress size={14} style={{ marginRight: 4 }} />} Редактирай
          </Button>
          <Button onClick={handleClose}>Отмени</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditUser
