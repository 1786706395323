export default (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'QUESTS_LOADED':
      return {
        ...state,
        loadingQuests: false,
        errorQuests: false,
        errorMessageQuests: null,
        quests: payload,
      }
    case 'QUESTS_FAIL':
      return {
        ...state,
        loadingQuests: false,
        errorQuests: true,
        errorMessageQuests: payload,
      }
    default:
      return state
  }
}
