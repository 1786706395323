import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography, Button, Box } from '@material-ui/core'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { SelectRoom, EscapeRoom } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const ChosenRooms = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [chosenRooms, setChosenRooms] = useState([])
  const [randomChosenRooms, setRandomChosenRooms] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [escapeRoom, setEscapeRoom] = useState(null)
  const [isUpdated, setIsUpdated] = useState(true)

  useEffect(() => {
    const getChosenRooms = async () => {
      setIsLoading(true)
      try {
        const chosenRoomsRes = await axios(`/api/chosen-rooms`)
        const randomChosenRoomsRes = await axios(`/api/random-chosen-rooms`)
        setChosenRooms(chosenRoomsRes.data)
        setRandomChosenRooms(randomChosenRoomsRes.data)
        setIsLoading(false)
        setIsUpdated(false)
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
        setIsLoading(false)
        setIsUpdated(false)
      }

      try {
        setIsLoading(false)
        setIsUpdated(false)
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
        setIsLoading(false)
        setIsUpdated(false)
      }
    }

    isUpdated && getChosenRooms()
    // eslint-disable-next-line
  }, [isUpdated])

  const deleteChosenRoom = async (roomId) => {
    setIsLoading(true)
    try {
      await axios.delete(`/api/admin/chosen-rooms/${roomId}`)
      setIsLoading(false)
      setIsUpdated(true)
    } catch {
      setIsLoading(false)
    }
  }

  const addChosenRoom = async (roomId) => {
    setIsLoading(true)
    try {
      await axios.post(`/api/admin/chosen-rooms/${roomId}`)
      setIsLoading(false)
      setIsUpdated(true)
      setEscapeRoom(null)
    } catch {
      setIsLoading(false)
    }
  }

  const deleteRandomChosenRoom = async (roomId) => {
    setIsLoading(true)
    try {
      await axios.delete(`/api/admin/random-chosen-rooms/${roomId}`)
      setIsLoading(false)
      setIsUpdated(true)
    } catch {
      setIsLoading(false)
    }
  }

  const addRandomChosenRoom = async (roomId) => {
    setIsLoading(true)
    try {
      await axios.post(`/api/admin/random-chosen-rooms/${roomId}`)
      setIsLoading(false)
      setIsUpdated(true)
      setEscapeRoom(null)
    } catch {
      setIsLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectRoom escapeRoom={escapeRoom} setEscapeRoom={setEscapeRoom} />
          <Box display="flex" alignItems="flex-end">
            <Button
              style={{ marginTop: 8 }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => addChosenRoom(escapeRoom)}
              disabled={!escapeRoom}>
              Добави стаята в стаи на фокус
            </Button>{' '}
            <Typography style={{ marginLeft: 8, marginRight: 8 }}>или</Typography>
            <Button
              style={{ marginTop: 8 }}
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => addRandomChosenRoom(escapeRoom)}
              disabled={!escapeRoom}>
              Добави стаята в стаи на фокус, показвани на случаен принцип
            </Button>
          </Box>
        </Grid>
        {chosenRooms.length ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h4">Избрани стаи на фокус</Typography>
              <Typography color="textSecondary">
                Тези стаи ще се показват на началната страница винаги
              </Typography>
            </Grid>
            {!isLoading &&
              chosenRooms.map((escaperoom) => (
                <Grid key={escaperoom._id} item lg={4} md={6} xl={4} xs={12}>
                  <EscapeRoom escaperoom={escaperoom} />
                  <Button
                    style={{ width: 330, marginTop: 8 }}
                    variant="outlined"
                    onClick={() => deleteChosenRoom(escaperoom._id)}>
                    Премахни стаята от избрани
                  </Button>
                </Grid>
              ))}
          </>
        ) : (
          ''
        )}

        {randomChosenRooms.length ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h4">
                Избрани стаи на фокус, показвани на случаен принцип
              </Typography>
              <Typography color="textSecondary">
                Тези стаи ще се показват на началната страница на случаен принцип. Те ще допълват
                стаите на фокус, ако не са достигнали своя лимит.
              </Typography>
            </Grid>
            {!isLoading &&
              randomChosenRooms.map((escaperoom) => (
                <Grid key={escaperoom._id} item lg={4} md={6} xl={4} xs={12}>
                  <EscapeRoom escaperoom={escaperoom} />
                  <Button
                    style={{ width: 330, marginTop: 8 }}
                    variant="outlined"
                    onClick={() => deleteRandomChosenRoom(escaperoom._id)}>
                    Премахни стаята от избрани
                  </Button>
                </Grid>
              ))}
          </>
        ) : (
          ''
        )}
      </Grid>
    </div>
  )
}

export default ChosenRooms
