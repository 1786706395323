import { Box, Button, TextField } from '@material-ui/core'
import axios from 'axios'
import { useAuth } from 'core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Comment, Reply } from './components'

const CommentSection = ({
  allComments,
  setAllComments,
  postCommentUrl,
  deleteCommentUrl,
  editCommentUrl,
  context = 'review',
}) => {
  const { isAuthenticated } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [comment, setComment] = useState({ text: '', mention: null })
  const [mentionDetails, setMentionDetails] = useState(null)

  useEffect(() => {
    comment.mention !== null &&
      setMentionDetails(
        allComments.find((initialComment) => initialComment._id === comment.mention)
      )
  }, [allComments, comment])

  const handlePostComment = async () => {
    try {
      const { data } = await axios.post(postCommentUrl, comment)
      enqueueSnackbar(data.message, { variant: 'success' })
      setAllComments(data[context].comments)
      setComment({ text: '', mention: null })
      setMentionDetails(null)
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar('Нещо се случи и коментарът не беше записан', { variant: 'error' })
      }
    }
  }

  const handleDeleteComment = async (commentId) => {
    try {
      const { data } = await axios.delete(`${deleteCommentUrl}${commentId}`)
      enqueueSnackbar(data.message, { variant: 'success' })
      setAllComments(data[context].comments)
    } catch (error) {
      enqueueSnackbar('Нещо се случи и коментарът не беше записан', { variant: 'error' })
    }
  }

  const handleEditComment = async (commentId, text) => {
    try {
      const { data } = await axios.put(`${editCommentUrl}${commentId}`, text)
      enqueueSnackbar(data.message, { variant: 'success' })
      setAllComments(data[context].comments)
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar('Нещо се случи и коментарът не беше записан', { variant: 'error' })
      }
    }
  }

  return (
    <>
      {allComments.map((initialComment) => (
        <Comment
          key={initialComment._id}
          handleDeleteComment={handleDeleteComment}
          handleEditComment={handleEditComment}
          allComments={allComments}
          comment={comment}
          initialComment={initialComment}
          setComment={setComment}>
          {initialComment.text}
        </Comment>
      ))}
      {isAuthenticated && (
        <>
          <Reply
            comment={comment}
            setComment={setComment}
            setDetails={setMentionDetails}
            details={mentionDetails}
          />
          <Box display="flex" mt={1} mb={1}>
            <TextField
              style={{ marginRight: 8 }}
              size="small"
              placeholder="Напиши коментар..."
              multiline
              variant="outlined"
              fullWidth
              value={comment.text}
              onChange={(e) => setComment({ ...comment, text: e.target.value })}
            />
            <Button
              disabled={comment.text === ''}
              size="small"
              variant="outlined"
              color="primary"
              onClick={handlePostComment}>
              Коментирай
            </Button>
          </Box>
        </>
      )}
    </>
  )
}

export default CommentSection
