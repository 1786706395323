import React, { useState } from 'react'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Box, Typography, Dialog, makeStyles } from '@material-ui/core'
import { SignIn } from 'views'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.black,
    cursor: 'pointer',
  },
}))

const Login = ({ isDesktop }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        onClick={handleClickOpen}
        className={classes.root}>
        <AccountCircleIcon />{' '}
        {isDesktop && (
          <Typography style={{ color: '#000', marginLeft: 4, fontSize: 13 }} variant="overline">
            Влез
          </Typography>
        )}
      </Box>

      <Dialog onClose={handleClose} open={open}>
        <SignIn />
      </Dialog>
    </>
  )
}

export default Login
