import React, { useState } from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import EditIcon from '@material-ui/icons/Edit'
import LabelIcon from '@material-ui/icons/Label'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ContactsIcon from '@material-ui/icons/Contacts'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import { Avatar, TableCell, TableRow, Typography, IconButton, Tooltip } from '@material-ui/core'
import { EditModal, BadgesModal, AdminModal, AlertsModal } from './components'
import { USER_GROUP } from 'helpers'
import { useAuth } from 'core'

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  active: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  inactive: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Row = (props) => {
  const { escaperoom } = props

  const [openEditModal, setOpenEditModal] = useState(false)
  const [openBadgesModal, setOpenBadgesModal] = useState(false)
  const [openAlertsModal, setOpenAlertsModal] = useState(false)
  const [openAdminModal, setOpenAdminModal] = useState(false)
  const classes = useRowStyles()
  const { user } = useAuth()

  const isAdmin = user.userGroup === USER_GROUP.admin.id

  const handleClickOpenEditModal = () => {
    setOpenEditModal(true)
  }

  const handleClickOpenBadgesModal = () => {
    setOpenBadgesModal(true)
  }

  const handleClickOpenAdminModal = () => {
    setOpenAdminModal(true)
  }

  const handleClickOpenAlertsModal = () => {
    setOpenAlertsModal(true)
  }

  return (
    <>
      <TableRow className={classes.tableRow} hover key={escaperoom._id}>
        <TableCell>
          <div className={classes.nameContainer}>
            <Avatar className={classes.avatar} src={escaperoom.thumbnail} />
            <Typography variant="body1">{escaperoom.title}</Typography>
          </div>
        </TableCell>
        <TableCell>{escaperoom.company?.name}</TableCell>
        <TableCell>
          {escaperoom.expiryDate
            ? moment(escaperoom.expiryDate).format('DD/MM/YYYY')
            : 'Перманентно'}
        </TableCell>
        <TableCell align="center">
          {escaperoom.people[1]
            ? `${escaperoom.people[0]} - ${escaperoom.people[1]}`
            : escaperoom.people[0]}
        </TableCell>
        <TableCell>
          {escaperoom.city}, {escaperoom.address}
        </TableCell>
        <TableCell align="center">{escaperoom.complexity}/5</TableCell>
        <TableCell align="center">{escaperoom.gameLength} мин.</TableCell>
        <TableCell align="center">
          {escaperoom.status ? (
            <CheckIcon className={classes.active} />
          ) : (
            <CancelIcon className={classes.inactive} />
          )}
        </TableCell>
        <TableCell align="right">
          {isAdmin && (
            <>
              <Tooltip title="Администрирай">
                <IconButton color="primary" size="small" onClick={handleClickOpenAdminModal}>
                  <ContactsIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Редактирай значките">
                <IconButton color="primary" size="small" onClick={handleClickOpenBadgesModal}>
                  <LabelIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title="Редактирай съобщенията">
            <IconButton color="primary" size="small" onClick={handleClickOpenAlertsModal}>
              <AnnouncementIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Редактирай стаята">
            <IconButton color="primary" size="small" onClick={handleClickOpenEditModal}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <EditModal open={openEditModal} setOpen={setOpenEditModal} escaperoom={escaperoom} />
      <AdminModal open={openAdminModal} setOpen={setOpenAdminModal} escaperoom={escaperoom} />
      <BadgesModal
        open={openBadgesModal}
        setOpen={setOpenBadgesModal}
        escaperoom={escaperoom}
        title={escaperoom.title}
        badges={escaperoom.badges}
      />
      <AlertsModal
        open={openAlertsModal}
        setOpen={setOpenAlertsModal}
        escaperoom={escaperoom}
        title={escaperoom.title}
        alerts={escaperoom.alerts}
      />
    </>
  )
}

export default Row
