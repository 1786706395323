import { USER_GROUP } from 'helpers'

export default (role) => {
  switch (role) {
    case USER_GROUP.admin.id:
      return USER_GROUP.admin.name
    case USER_GROUP.owner.id:
      return USER_GROUP.owner.name
    default:
      return USER_GROUP.user.name
  }
}
