import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import { UploadPhoto, SelectRoom, PickDate, Submit, Toolbar } from './components'
import {
  Grid,
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  Button,
  FormHelperText,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  field: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Review = () => {
  const history = useHistory()
  const classes = useStyles()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const [fields, setFields] = useState([{ value: '' }])
  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    file: '',
    roomId: location.state?.id || '',
    date: new Date(),
    emails: [],
  })

  useEffect(() => {
    setForm({
      ...form,
      emails: fields.map((field) => field.value.toLowerCase()).filter((field) => field !== ''),
    })
    // eslint-disable-next-line
  }, [fields])

  const handleInputChange = (index, event) => {
    const values = [...fields]
    values[index].value = event.target.value
    setFields(values)
  }

  const handleAddFields = () => {
    const values = [...fields]
    values.push({ value: '' })
    setFields(values)
  }

  const handleRemoveFields = (index) => {
    const values = [...fields]
    values.splice(index, 1)
    setFields(values)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.post(`/api/owner/review/create`, form)
      enqueueSnackbar(data.message, { variant: 'success' })
      const errors = data.errors
      errors.length && errors.map((error) => enqueueSnackbar(error.message, { variant: 'warning' }))
      setIsLoading(false)
      history.push('/admin/reviews')
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' })
      setIsLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <Toolbar />
      <Grid container spacing={2}>
        <Grid item lg={4} xs={12}>
          <UploadPhoto form={form} setForm={setForm} />
        </Grid>
        <Grid item lg={8} xs={12}>
          <SelectRoom form={form} setForm={setForm} />
          <PickDate form={form} setForm={setForm} />
          <Box display="flex" flexWrap="wrap">
            {fields.map((field, index) => (
              <FormControl variant="outlined" key={`${field}~${index}`} className={classes.field}>
                <InputLabel htmlFor={field.index}>Имейл на играча</InputLabel>
                <OutlinedInput
                  id={field.index}
                  type="email"
                  value={field.value}
                  onChange={(e) => handleInputChange(index, e)}
                  labelWidth={112}
                  endAdornment={
                    fields.length > 1 && (
                      <InputAdornment position="end">
                        <IconButton onClick={() => handleRemoveFields(index)} edge="end">
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
                {field.value !== '' && !emailRegex.test(field.value) && (
                  <FormHelperText error={true}>Невалиден имейл</FormHelperText>
                )}
              </FormControl>
            ))}
          </Box>
          <Box display="flex" justifyContent="space-between" style={{ width: '100%' }} mb={2}>
            <Button
              disabled={fields.some((field) => field.value === '' || !emailRegex.test(field.value))}
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddFields}>
              Добави друг имейл
            </Button>
            <Submit
              form={form}
              handleSubmit={handleSubmit}
              emailRegex={emailRegex}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default Review
