import { cities } from 'utils'

export default (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'ESCAPE_ROOMS_LOADED':
      const escaperoomCities = payload
        .filter(
          (escaperoom) =>
            new Date(escaperoom.expiryDate).getTime() > Date.now() && escaperoom.status === true
        )
        .map((escaperoom) => escaperoom.city)
      return {
        ...state,
        loadingEscapeRooms: false,
        errorEscapeRooms: false,
        errorMessageEscapeRooms: null,
        escaperooms: payload,
        chosenRooms: payload.filter(
          (escaperoom) =>
            escaperoom.isChosen === true &&
            new Date(escaperoom.expiryDate).getTime() > new Date().getTime() &&
            escaperoom.status === true
        ),
        randomChosenRooms: payload.filter(
          (escaperoom) =>
            escaperoom.isRandomChosen === true &&
            new Date(escaperoom.expiryDate).getTime() > new Date().getTime() &&
            escaperoom.status === true
        ),
        cities: cities.filter((city) => escaperoomCities.includes(city)),
      }
    case 'ESCAPE_ROOMS_FAIL':
      return {
        ...state,
        loadingEscapeRooms: false,
        errorEscapeRooms: true,
        errorMessageEscapeRooms: payload,
      }
    case 'CHANGE_MAIN_CITY':
      return {
        ...state,
        mainCity: payload,
      }
    default:
      return state
  }
}
