import React, { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { makeStyles } from '@material-ui/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from '@ckeditor/ckeditor5-build-classic'
import {
  Avatar,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Grid,
  IconButton,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core'
import { getInitials } from 'helpers'
import { useEscapeRooms } from 'core'
import { Photo } from 'components'
import { Link as RouterLink } from 'react-router-dom'
import { messages } from 'utils'
import { useSnackbar } from 'notistack'
import { isEqual } from 'lodash'

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  active: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  inactive: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Row = (props) => {
  const { news, columns, setIsUpdated } = props
  const { escaperooms } = useEscapeRooms()
  const { enqueueSnackbar } = useSnackbar()

  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState(news)
  const [initialNews, setInitialNews] = useState(news)
  const [open, setOpen] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const classes = useRowStyles()

  const handleEdit = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.put(`/api/owner/news/${news.shortname}`, form)
      setForm(data)
      setInitialNews(data)
      enqueueSnackbar(messages.admin.news.updateSuccess, { variant: 'success' })
      setIsLoading(false)
      setIsUpdated(true)
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.news.updateFail, { variant: 'error' })
      }
      setIsLoading(false)
    }
  }

  const handleApprove = async () => {
    try {
      const { data } = await axios.put(`/api/owner/news/enable/${news.shortname}`)
      setForm(data)
      setInitialNews(data)
      setIsUpdated(true)
      enqueueSnackbar(messages.admin.news.approve, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(messages.admin.news.updateFail, { variant: 'error' })
    }
  }

  const handleDisapprove = async () => {
    try {
      const { data } = await axios.put(`/api/owner/news/disable/${news.shortname}`)
      setForm(data)
      setInitialNews(data)
      setIsUpdated(true)
      enqueueSnackbar(messages.admin.news.disapprove, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(messages.admin.news.updateFail, { variant: 'error' })
    }
  }

  const handleDeletePermanently = async () => {
    try {
      await axios.delete(`/api/owner/news/${news.shortname}`)
      enqueueSnackbar(messages.admin.news.deleteSuccess, { variant: 'success' })
      setOpenDeleteDialog(false)
      setIsUpdated(true)
    } catch (error) {
      enqueueSnackbar(messages.admin.news.deleteFail, { variant: 'error' })
    }
  }

  return (
    <>
      <TableRow className={classes.tableRow} hover key={news._id}>
        <TableCell>{initialNews.title}</TableCell>
        <TableCell>
          <Link component={RouterLink} to={`/news/${initialNews.shortname}`}>
            /news/{initialNews.shortname}
          </Link>
        </TableCell>
        <TableCell>
          <div className={classes.nameContainer}>
            <Avatar className={classes.avatar} src={news.author?.avatar}>
              {getInitials(news.author?.name)}
            </Avatar>
            <Typography variant="body1">
              {news.author ? news.author.name : 'Нерегистриран потребител'}
            </Typography>
          </div>
        </TableCell>
        <TableCell>{moment(initialNews.createdAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell>
          {initialNews.taggedRoom
            ? escaperooms.find(
                (hookedEscapeRoom) => hookedEscapeRoom._id === initialNews.taggedRoom
              ).title
            : 'Няма'}
        </TableCell>
        <TableCell align="center">
          {initialNews.isEnabled ? (
            <CheckIcon className={classes.active} />
          ) : (
            <CancelIcon className={classes.inactive} />
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.container}>
              <Grid container spacing={4}>
                <Grid item xs={12} style={{ maxWidth: 1110, margin: '0 auto' }}>
                  <Photo
                    width={'100%'}
                    image={form.image}
                    handleDelete={() => setForm({ ...form, image: '' })}
                  />
                  <CKEditor
                    editor={Editor}
                    data={form.content}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setForm({ ...form, content: data })
                    }}
                  />
                  <Box mt={2}>
                    <Button
                      size="small"
                      onClick={handleEdit}
                      variant="outlined"
                      style={{ marginRight: 4 }}
                      disabled={isLoading || isEqual(form, initialNews)}>
                      {isLoading && <CircularProgress size={14} style={{ marginRight: 4 }} />}{' '}
                      Запази промените
                    </Button>

                    {initialNews.isEnabled ? (
                      <Button
                        className={classes.inactive}
                        size="small"
                        onClick={handleDisapprove}
                        startIcon={<CancelIcon />}>
                        Деактивирай
                      </Button>
                    ) : (
                      <Button
                        className={classes.active}
                        size="small"
                        onClick={handleApprove}
                        startIcon={<CheckIcon />}>
                        Активирай
                      </Button>
                    )}
                    <Button
                      style={{ marginRight: 8 }}
                      size="small"
                      onClick={() => setOpenDeleteDialog(true)}
                      startIcon={<CancelIcon />}>
                      Изтрий новината перманентно
                    </Button>
                    <Typography variant="caption" style={{ opacity: 0.75 }}>
                      Последна промяна:{' '}
                      {Intl.DateTimeFormat('bg-BG', {
                        dateStyle: 'short',
                        timeStyle: 'medium',
                      }).format(new Date(initialNews.updatedAt))}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog onClose={() => setOpenDeleteDialog(false)} open={openDeleteDialog}>
        <DialogTitle>Изтрий новината</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Сигурен ли си, че искаш да изтриеш тази новина? Действието е необратимо.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleDeletePermanently}>
            Изтрий
          </Button>
          <Button onClick={() => setOpenDeleteDialog(false)}>Отмени</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Row
