import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core'
import { Toolbar, Row } from './components'
import { useQuests, useAuth } from 'core'
import { USER_GROUP } from 'helpers'
import { CreateModal } from './components/Row/components'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}))

const CustomTable = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const { quests } = useQuests()
  const { user } = useAuth()

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [openCreateModal, setOpenCreateModal] = useState(false)

  const isAdmin = user.userGroup === USER_GROUP.admin.id

  const columns = [
    { name: 'Име', headAlign: 'inherit' },
    { name: 'Компания', headAlign: 'inherit' },
    { name: 'Валиден до', headAlign: 'inherit' },
    { name: 'Отбор', headAlign: 'center' },
    { name: 'Адрес', headAlign: 'inherit' },
    { name: 'Трудност', headAlign: 'center' },
    { name: 'Времетраене', headAlign: 'center' },
    { name: 'Активен', headAlign: 'center' },
    { name: 'Действия', headAlign: 'right' },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <Toolbar setOpenCreateModal={setOpenCreateModal} />
      <Card {...rest} className={classes.root}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.name} align={column.headAlign}>
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isAdmin
                    ? quests
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((quest) => <Row key={quest._id} quest={quest} />)
                    : quests
                        .filter((quest) => quest.owner === user._id)
                        .map((quest) => <Row key={quest._id} quest={quest} />)}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={
              isAdmin
                ? quests.length
                : quests.filter((escaperoom) => escaperoom.owner === user._id).length
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={'Редове на страница:'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} от ${count !== -1 ? count : `повече от ${to}`}`
            }
          />
        </CardActions>
      </Card>

      <CreateModal open={openCreateModal} setOpen={setOpenCreateModal} />
    </>
  )
}

CustomTable.propTypes = {
  className: PropTypes.string,
}

export default CustomTable
