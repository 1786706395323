import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Box, CardMedia, Grid, Typography, Link } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom'
import { useEscapeRooms } from 'core'
import TodayIcon from '@material-ui/icons/Today'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import axios from 'axios'
import { PageURLs } from 'Routes'
import { Loading } from 'views'
import { EscapeRoom } from 'views/EscapeRooms/components'
import { Helmet } from 'react-helmet'
import { CommentSection } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  grid: {
    maxWidth: theme.sizing.main.contentWidth / 1.3,
    width: '100%',
    display: 'flex',
  },
  content: {},
  image: {
    height: 500,
    width: '100%',
    borderRadius: 4,
    paddingTop: 0,

    [theme.breakpoints.down('sm')]: {
      height: 0,
      paddingTop: '56.25%',
    },
  },
}))

const News = () => {
  const classes = useStyles()
  const { escaperooms } = useEscapeRooms()
  const { enqueueSnackbar } = useSnackbar()
  const { newsId } = useParams()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [news, setNews] = useState(null)
  const [allComments, setAllComments] = useState([])
  const [escaperoom, setEscapeRoom] = useState(null)

  useEffect(() => {
    const getNews = async () => {
      setIsLoading(true)

      try {
        const { data } = await axios.get(`/api/news/${newsId}`)
        setNews(data)
        setIsLoading(false)
        setAllComments(data.comments)
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
        history.push('/')
        setIsLoading(false)
      }
    }

    newsId && getNews()
    // eslint-disable-next-line
  }, [newsId])

  useEffect(() => {
    news &&
      setEscapeRoom(
        escaperooms.find((hookedEscaperoom) => hookedEscaperoom._id === news.taggedRoom)
      )
    // eslint-disable-next-line
  }, [news])

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container spacing={5}>
        {!isLoading && news !== null ? (
          <>
            <Helmet>
              <title>{news.title} | Всички стаи</title>
            </Helmet>
            <Grid className={classes.content} item xs={12}>
              <Box display="flex" justifyContent="center" mb={2}>
                <CardMedia
                  className={classes.image}
                  image={news.image ? news.image : '/image/missing_image.png'}
                  alt="News"
                />
              </Box>
              <Typography variant="h2">{news.title}</Typography>
              <Box mt={2} display="flex" alignItems="center">
                <Avatar alt="Avatar" src={news.author?.avatar} />
                <Link
                  style={{ marginLeft: 8, marginRight: 16 }}
                  component={RouterLink}
                  to={`${PageURLs.Profile}/${news.author?._id}`}>
                  {news.author?.name}
                </Link>
                <Typography style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
                  <TodayIcon color="primary" style={{ marginRight: 4 }} />
                  {Intl.DateTimeFormat('bg-BG', {
                    dateStyle: 'short',
                    timeStyle: 'medium',
                  }).format(new Date(news.createdAt))}
                </Typography>
                <Typography style={{ display: 'flex', alignItems: 'center' }}>
                  <CommentOutlinedIcon color="primary" style={{ marginRight: 4 }} />
                  {news.comments.length === 1
                    ? `${news.comments.length} коментар`
                    : `${news.comments.length} коментара`}
                </Typography>
              </Box>
              <Box mt={3} mb={3} dangerouslySetInnerHTML={{ __html: news.content }} />
              {news.taggedRoom && (
                <Box>
                  <Typography>Отбелязана ескейп стая:</Typography>
                  <EscapeRoom escaperoom={escaperoom} />
                </Box>
              )}
              <Typography variant="h5">Коментари</Typography>
              <CommentSection
                allComments={allComments}
                setAllComments={setAllComments}
                postCommentUrl={`/api/customer/news/comment/${news.shortname}`}
                deleteCommentUrl={`/api/customer/news/comment/${news.shortname}/delete/`}
                editCommentUrl={`/api/customer/news/comment/${news.shortname}/edit/`}
                context="news"
              />
            </Grid>
          </>
        ) : (
          <Loading />
        )}
      </Grid>
    </div>
  )
}

export default News
