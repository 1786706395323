import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import {
  AdditionalInformation,
  Badges as Badge,
  MainInformation,
  Image,
  Buttons,
} from './components'
import { Link } from 'react-router-dom'
import { PageURLs } from 'Routes'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: theme.sizing.homePage.escapeRoom.width,
    borderRadius: 10,
    height: theme.sizing.homePage.escapeRoom.height,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.sizing.main.spaceBetweenContents,
    marginBottom: theme.sizing.main.spaceBetweenContents,

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },

    '&:hover $additionalInformation, &:hover $buttons': {
      display: 'flex',
      opacity: 1,
      transition: 'all 0.3s',
    },

    '&:hover $badge': {
      opacity: 0,
      transition: 'all 0.3s',
    },
  },
  additionalInformation: {
    opacity: 0,
    transition: 'all 0.3s',

    [theme.breakpoints.down('lg')]: {
      opacity: 1,
    },
  },
  buttons: {
    opacity: 0,
    transition: 'all 0.3s',

    [theme.breakpoints.down('lg')]: {
      opacity: 1,
    },
  },
  badge: {
    opacity: 1,
    transition: 'all 0.3s',
  },
  badgesWrapper: {
    position: 'absolute',
    top: theme.spacing(2),
    left: -theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5),
    },

    [theme.breakpoints.down('lg')]: {
      top: theme.spacing(6),
    },
  },
}))

const EscapeRoom = ({ escaperoom, className }) => {
  const classes = useStyles()

  return (
    <div style={{ position: 'relative' }} className={className}>
      <Paper className={classes.root} elevation={3}>
        <Link to={`${PageURLs.EscapeRooms}/${escaperoom.shortname}`}>
          <div className={classes.badgesWrapper}>
            {escaperoom.badges.map((badge) => (
              <Badge
                className={classes.badge}
                label={badge.label}
                backgroundColor={badge.color}
                textColor={badge.textColor}
                key={badge.label}
              />
            ))}
          </div>
          <Image url={escaperoom.thumbnail} />
          <AdditionalInformation
            className={classes.additionalInformation}
            players={escaperoom.people}
            complexity={escaperoom.complexity}
            time={escaperoom.gameLength}
          />
          <MainInformation title={escaperoom.title} />
        </Link>
        <Buttons className={classes.buttons} escaperoomId={escaperoom._id} />
      </Paper>
    </div>
  )
}

export default React.memo(EscapeRoom)
