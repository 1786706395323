import React, { useState } from 'react'
import { TableRow, TableCell, Avatar, Typography, makeStyles, IconButton } from '@material-ui/core'
import { getInitials, displayUserRole, USER_GROUP } from 'helpers'
import { EditUser, EnableUser, DeleteUser } from './components'
import moment from 'moment'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import { useAuth } from 'core'
import { Visibility } from '@material-ui/icons'
import { PageURLs } from 'Routes'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'space-between',
  },
  active: {
    color: theme.palette.success.main,
  },
  inactive: {
    color: theme.palette.error.main,
  },
}))

const Row = ({ user }) => {
  const classes = useStyles()
  const [initialUser, setInitialUser] = useState(user)
  const { user: loggedInUser } = useAuth()

  return (
    <TableRow className={classes.tableRow} hover>
      <TableCell>
        <div className={classes.nameContainer}>
          <Avatar className={classes.avatar} src={initialUser.avatar}>
            {getInitials(initialUser.name)}
          </Avatar>
          <Typography variant="body1">{initialUser.name}</Typography>
        </div>
      </TableCell>
      <TableCell>{initialUser.email}</TableCell>
      <TableCell>{initialUser.phone}</TableCell>
      <TableCell>{moment(initialUser.createdAt).format('DD/MM/YYYY')}</TableCell>
      <TableCell align="center">
        {initialUser.isEnabled ? (
          <CheckIcon className={classes.active} />
        ) : (
          <CancelIcon className={classes.inactive} />
        )}
      </TableCell>
      <TableCell>{displayUserRole(initialUser.userGroup)}</TableCell>
      <TableCell align="right">
        <IconButton
          size="small"
          component="a"
          target="_blank"
          href={`${PageURLs.Profile}/${initialUser._id}`}>
          <Visibility />
        </IconButton>
        {loggedInUser.email === 'cattz0r@gmail.com' && user.email !== 'cattz0r@gmail.com' && (
          <>
            <EditUser user={initialUser} setInitialUser={setInitialUser} />
            {initialUser.isEnabled ? (
              <DeleteUser user={initialUser} setInitialUser={setInitialUser} />
            ) : (
              <EnableUser user={initialUser} setInitialUser={setInitialUser} />
            )}
          </>
        )}
        {initialUser.userGroup !== USER_GROUP.admin.id &&
          loggedInUser.email !== 'cattz0r@gmail.com' && (
            <>
              <EditUser user={initialUser} setInitialUser={setInitialUser} />
              {initialUser.isEnabled ? (
                <DeleteUser user={initialUser} setInitialUser={setInitialUser} />
              ) : (
                <EnableUser user={initialUser} setInitialUser={setInitialUser} />
              )}
            </>
          )}
      </TableCell>
    </TableRow>
  )
}

export default Row
