import React from 'react'
import { Grid, Divider, Button, LinearProgress, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { PageURLs } from 'Routes'
import { Link } from 'react-router-dom'
import { useReviews } from 'core'

import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorderOutlined'
import FavoriteIcon from '@material-ui/icons/Favorite'
import BookIcon from '@material-ui/icons/Book'
import ChatBubbleIcon from '@material-ui/icons/ChatBubbleOutline'

import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.spacing(2),
    background: theme.palette.mainLayout.divider,
  },
  button: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  percentage: {
    display: 'flex',
    width: '100%',
  },
}))

function LinearProgressWithLabel(props) {
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      flexDirection="column"
      justifyContent="center"
      style={{ width: '100%' }}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}% от изигралите препоръчват тази стая`}</Typography>
      </Box>
    </Box>
  )
}

const Buttons = ({ escaperoomId, percentage, site }) => {
  const classes = useStyles()
  const { allReviewedRooms, allReviews } = useReviews()
  const { user, removeFavoriteRoom, addFavoriteRoom, isAuthenticated } = useAuth()

  const myReview = allReviews.find((review) => review.roomId === escaperoomId)?._id

  const favoriteRooms = user?.favoriteRooms

  return (
    <>
      <Grid xs={12} item className={classes.root}>
        <Grid container justifyContent="space-between">
          <Grid item lg={8} xs={12}>
            <Button
              className={classes.button}
              size="small"
              variant="contained"
              color="primary"
              startIcon={<BookIcon />}>
              <a style={{ color: 'white' }} rel="noopener noreferrer" href={site} target="_blank">
                Резервирай
              </a>
            </Button>
            {isAuthenticated ? (
              !favoriteRooms?.includes(escaperoomId) ? (
                <Button
                  className={classes.button}
                  size="small"
                  startIcon={<FavoriteBorderIcon color="primary" />}
                  onClick={() => {
                    addFavoriteRoom(escaperoomId)
                  }}>
                  Добави в любими
                </Button>
              ) : (
                <Button
                  className={classes.button}
                  size="small"
                  startIcon={<FavoriteIcon color="primary" />}
                  onClick={() => {
                    removeFavoriteRoom(escaperoomId)
                  }}>
                  Премахни от любими
                </Button>
              )
            ) : (
              <Link to={PageURLs.Login}>
                <Button
                  className={classes.button}
                  size="small"
                  startIcon={<FavoriteBorderIcon color="primary" />}
                  onClick={() => {
                    addFavoriteRoom(escaperoomId)
                  }}>
                  Добави в любими
                </Button>
              </Link>
            )}
            {isAuthenticated && allReviewedRooms.includes(escaperoomId) ? (
              <Link
                to={{
                  pathname: `${PageURLs.Profile}/${user._id}/review/${myReview}`,
                  state: { id: escaperoomId },
                }}>
                <Button
                  className={classes.button}
                  size="small"
                  startIcon={<ChatBubbleIcon color="primary" />}>
                  Виж ревюто си
                </Button>
              </Link>
            ) : (
              <Link
                to={{
                  pathname: PageURLs.CreateReview,
                  state: { id: escaperoomId },
                }}>
                <Button
                  className={classes.button}
                  size="small"
                  startIcon={<ChatBubbleIcon color="primary" />}>
                  Напиши ревю
                </Button>
              </Link>
            )}
          </Grid>

          <Grid item lg={4} xs={12} className={classes.percentage}>
            {percentage ? <LinearProgressWithLabel value={percentage} /> : ''}
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Buttons
