import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from 'core'

const UnauthenticatedRouteWithLayout = (props) => {
  const { group, layout: Layout, component: Component, ...rest } = props
  const { isAuthenticated, user } = useAuth()

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        !isAuthenticated && !user ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

UnauthenticatedRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
}

export default UnauthenticatedRouteWithLayout
