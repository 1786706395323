import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import Complexity from './Complexity'
// import { Tooltip } from '@material-ui/core'
// import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(1.5),
  },
  middleElement: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1.5),
  },
  divider: {
    [theme.breakpoints.up('sm')]: {
      background: theme.palette.mainLayout.divider,
    },
    background: 'transparent',
  },
}))

const Details = ({ complexity, people, gameLength, favorite }) => {
  const classes = useStyles()
  // const { isAuthenticated, user } = useAuth()

  return (
    <Grid container>
      <Grid xs={12} sm={4} item className={classes.root}>
        <Box display="flex" justifyContent="center">
          <Complexity complexity={complexity} />
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="overline" color="textPrimary">
            трудност
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} sm={4} item className={classes.middleElement}>
        <Divider orientation="vertical" className={classes.divider} />
        <Box>
          <Box display="flex" justifyContent="center">
            <Typography color="primary" variant="h4">
              {people[0]} - {people[1]}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="overline" color="textPrimary">
              играчи
            </Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" className={classes.divider} />
      </Grid>
      <Grid xs={12} sm={4} item>
        <span></span>
        <Box>
          <Box display="flex" justifyContent="center">
            <Typography color="primary" variant="h4">
              {gameLength}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="overline" color="textPrimary">
              минути
            </Typography>
          </Box>
        </Box>
        {/* <Divider orientation="vertical" className={classes.divider} /> */}
      </Grid>
      {/* <Tooltip title={`${favorite.length} души са добавили стаята в любими`}>
        <Grid xs={12} sm={3} item className={classes.root}>
          <Box display="flex" justifyContent="center">
            <Typography color="primary" variant="h4">
              {favorite.length}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography variant="overline" color="textPrimary">
              {favorite.length === 1 ? 'обичащ тази стая' : 'обичащи тази стая'}{' '}
              {isAuthenticated && favorite.find((userId) => userId === user._id) && '(с теб)'}
            </Typography>
          </Box>
        </Grid>
      </Tooltip> */}
    </Grid>
  )
}

export default Details
