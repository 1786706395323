import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { Image } from './components'
import { makeStyles } from '@material-ui/styles'
import { ComponentTitle } from '../../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}))

const Gallery = ({ images }) => {
  const classes = useStyles()

  return (
    <Grid xs={12} item className={classes.root}>
      <ComponentTitle>Галерия</ComponentTitle>
      <Box display="flex" flexWrap="wrap">
        {images.map((image, index, array) => {
          return <Image key={image} src={image} images={array} index={index} />
        })}
      </Box>
    </Grid>
  )
}

export default Gallery
