import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import App from './App'
import { debugContextDevtool } from 'react-context-devtool'

const container = document.getElementById('root')

ReactDOM.render(<App />, container)

debugContextDevtool(container, {
  disable: process.env.NODE_ENV === 'production',
  disableAutoMode: true,
})
serviceWorker.unregister()
