import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

const Submit = ({ form, handleSubmit, emailRegex, isLoading }) => {
  const isDisabled =
    form.file &&
    form.roomId &&
    form.emails.length &&
    form.emails.filter((a) => !emailRegex.test(a)).length === 0 &&
    form.emails.filter((item, index) => form.emails.indexOf(item) !== index).length === 0
      ? false
      : true

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isDisabled || isLoading}
      onClick={handleSubmit}>
      {isLoading ? <CircularProgress style={{ width: 20, height: 20 }} /> : 'Запази'}
    </Button>
  )
}

export default Submit
