import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { Buttons, Details, Description, Gallery, Map } from './components'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2.5),
  },
  typography: {
    fontSize: 16,
  },
}))

const MainInformation = (props) => {
  const classes = useStyles()
  const {
    complexity,
    people,
    gameLength,
    description,
    images,
    city,
    address,
    escaperoomId,
    site,
    favorite,
  } = props
  const [percentage, setPercentage] = useState(null)

  useEffect(() => {
    const getPositiveReviewsPercentage = async () => {
      try {
        const { data } = await axios.get(`/api/room-recommendations/${escaperoomId}`)
        setPercentage(data)
      } catch (error) {}
    }

    getPositiveReviewsPercentage()
  }, [escaperoomId])

  return (
    <Grid xs={12} sm={8} item className={classes.root}>
      <Buttons escaperoomId={escaperoomId} percentage={percentage} site={site} />
      <Details
        complexity={complexity}
        people={people}
        gameLength={gameLength}
        favorite={favorite}
      />
      <Description>{description}</Description>
      {images.length ? <Gallery images={images} /> : ''}
      <Map city={city} address={address} />
    </Grid>
  )
}

export default MainInformation
