import React, { useState } from 'react'
import moment from 'moment'
import axios from 'axios'
import { makeStyles } from '@material-ui/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import CheckIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  Avatar,
  TableCell,
  TableRow,
  Typography,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  TextField,
  Button,
  Switch,
  Box,
  CircularProgress,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getInitials, USER_GROUP } from 'helpers'
import { useEscapeRooms, useAuth } from 'core'
import { Photo, DatePickerField, CommentSection } from 'components'
import { messages } from 'utils'
import { useSnackbar } from 'notistack'
import { isEqual } from 'lodash'
import { Visibility } from '@material-ui/icons'
import { PageURLs } from 'Routes'

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  active: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  inactive: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Row = (props) => {
  const { review, columns, setPage } = props
  const { escaperooms } = useEscapeRooms()
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState(review)
  const [initialReview, setInitialReview] = useState(review)
  const [open, setOpen] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [allComments, setAllComments] = useState(review.comments)
  const classes = useRowStyles()

  const handleEdit = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.put(`/api/admin/review/${review._id}`, form)
      setForm(data)
      setInitialReview(data)
      enqueueSnackbar(messages.admin.review.updateSuccess, { variant: 'success' })
      setIsLoading(false)
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(messages.admin.review.updateFail, { variant: 'error' })
      }
      setIsLoading(false)
    }
  }

  const handleApprove = async () => {
    try {
      const { data } = await axios.put(`/api/admin/review/approve/${review._id}`)
      setForm(data)
      setInitialReview(data)
      enqueueSnackbar(messages.admin.review.approve, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(messages.admin.review.updateFail, { variant: 'error' })
    }
  }

  const handleDisapprove = async () => {
    try {
      const { data } = await axios.put(`/api/admin/review/disapprove/${review._id}`)
      setForm(data)
      setInitialReview(data)
      enqueueSnackbar(messages.admin.review.disapprove, { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(messages.admin.review.updateFail, { variant: 'error' })
    }
  }

  const handleDeletePermanently = async () => {
    try {
      await axios.delete(`/api/admin/review/${review._id}`)
      enqueueSnackbar(messages.admin.review.deleteSuccess, { variant: 'success' })
      setOpenDeleteDialog(false)
      setPage(1)
    } catch (error) {
      enqueueSnackbar(messages.admin.review.deleteFail, { variant: 'error' })
    }
  }

  return (
    <>
      <TableRow className={classes.tableRow} hover key={review._id}>
        <TableCell>
          <div className={classes.nameContainer}>
            <Avatar className={classes.avatar} src={review.user?.avatar}>
              {getInitials(review.user?.name)}
            </Avatar>
            <Typography variant="body1">
              {review.user ? review.user.name : 'Нерегистриран потребител'}
            </Typography>
          </div>
        </TableCell>
        <TableCell>{initialReview.email}</TableCell>
        <TableCell>{moment(initialReview.date).format('DD/MM/YYYY')}</TableCell>
        <TableCell>
          {escaperooms.find((escaperoom) => escaperoom._id === initialReview.roomId)?.title}
        </TableCell>
        <TableCell>{moment(initialReview.createdAt).format('DD/MM/YYYY')}</TableCell>
        <TableCell align="center">
          {initialReview.trusted ? (
            <CheckIcon className={classes.active} />
          ) : (
            <CancelIcon className={classes.inactive} />
          )}
        </TableCell>
        <TableCell align="center">
          {initialReview.finished ? (
            <CheckIcon className={classes.active} />
          ) : (
            <CancelIcon className={classes.inactive} />
          )}
        </TableCell>
        <TableCell align="right">
          {initialReview?.user ? (
            user?.userGroup === USER_GROUP.owner.id &&
            (!review.finished || !review.trusted) ? null : (
              <IconButton
                size="small"
                component="a"
                target="_blank"
                style={{ marginRight: 8 }}
                href={`${PageURLs.Profile}/${
                  initialReview?.user?._id ?? initialReview?.user
                }/review/${initialReview._id}`}>
                <Visibility />
              </IconButton>
            )
          ) : null}
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.container}>
              <Grid className={classes.grid} container spacing={4}>
                <Grid item xs={12} lg={8}>
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <Autocomplete
                        className={classes.field}
                        size="small"
                        options={escaperooms}
                        getOptionLabel={(option) => option.title}
                        defaultValue={
                          escaperooms.filter(
                            (escaperoom) => escaperoom._id === initialReview.roomId
                          )[0]
                        }
                        onChange={(e, room) => setForm({ ...form, roomId: room?._id })}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            label={'Оценена стая'}
                            variant="outlined"
                            disabled={user.userGroup !== USER_GROUP.admin.id}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Typography>
                        Не препоръчва
                        <Switch
                          disabled={user.userGroup !== USER_GROUP.admin.id}
                          checked={form.recommend}
                          color="primary"
                          onChange={() => setForm({ ...form, recommend: !form.recommend })}
                        />
                        Препоръчва
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField
                        disabled={user.userGroup !== USER_GROUP.admin.id}
                        label="Ревю"
                        size="small"
                        variant="outlined"
                        defaultValue={initialReview.review}
                        onChange={(e) => setForm({ ...form, review: e.target.value })}
                        multiline
                        fullWidth
                        className={classes.field}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <DatePickerField
                        form={form}
                        setForm={setForm}
                        label={'Дата на игра'}
                        disabled={user.userGroup !== USER_GROUP.admin.id}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Typography>Коментари</Typography>
                      <CommentSection
                        allComments={allComments}
                        setAllComments={setAllComments}
                        postCommentUrl={`/api/customer/review/comment/${review._id}`}
                        deleteCommentUrl={`/api/customer/review/comment/${review._id}/delete/`}
                        editCommentUrl={`/api/customer/review/comment/${review._id}/edit/`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Photo
                    image={form.file}
                    adminView
                    handleDelete={() => setForm({ ...form, file: '' })}
                  />
                </Grid>
              </Grid>
              {user.userGroup === USER_GROUP.admin.id && (
                <Button
                  size="small"
                  onClick={handleEdit}
                  variant="outlined"
                  style={{ marginRight: 4 }}
                  disabled={isLoading || isEqual(form, initialReview)}>
                  {isLoading && <CircularProgress size={14} style={{ marginRight: 4 }} />} Запази
                  промените
                </Button>
              )}
              {initialReview.trusted ? (
                user.userGroup === USER_GROUP.admin.id && (
                  <Button
                    className={classes.inactive}
                    size="small"
                    onClick={handleDisapprove}
                    startIcon={<CancelIcon />}>
                    Премахни от потвърдени
                  </Button>
                )
              ) : (
                <Button
                  className={classes.active}
                  size="small"
                  onClick={handleApprove}
                  startIcon={<CheckIcon />}>
                  Добави в потвърдени
                </Button>
              )}
              <Typography variant="caption" style={{ opacity: 0.75 }}>
                Последна промяна:{' '}
                {Intl.DateTimeFormat('bg-BG', { dateStyle: 'short', timeStyle: 'medium' }).format(
                  new Date(initialReview.updatedAt)
                )}
              </Typography>

              {user.email === 'cattz0r@gmail.com' && (
                <Button
                  style={{ marginLeft: 8 }}
                  size="small"
                  onClick={() => setOpenDeleteDialog(true)}
                  startIcon={<CancelIcon />}>
                  Изтрий ревюто перманентно
                </Button>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Dialog onClose={() => setOpenDeleteDialog(false)} open={openDeleteDialog}>
        <DialogTitle>Изтрий ревюто</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Сигурен ли си, че искаш да изтриеш това ревю? Действието е необратимо.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleDeletePermanently}>
            Изтрий
          </Button>
          <Button onClick={() => setOpenDeleteDialog(false)}>Отмени</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Row
