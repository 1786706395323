import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper, TextField, Button } from '@material-ui/core'
import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  contentBody: {
    flexGrow: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    padding: theme.spacing(4),
    flexBasis: 700,
    margin: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const ForgottenPassword = () => {
  const classes = useStyles()
  const { forgottenPassword } = useAuth()

  const [email, setEmail] = useState('')

  const handleChangePassword = () => {
    forgottenPassword(email)
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentBody}>
        <Paper className={classes.form}>
          <Typography variant="h2" gutterBottom>
            Забравена парола
          </Typography>
          <Typography>
            Въведи имейла, с който си се регистрирал, за да ти изпратим имейл с последователни
            действия
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            size="small"
            label="Имейл"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleChangePassword}
            disabled={email === ''}>
            Изпрати имейл
          </Button>
        </Paper>
      </div>
    </div>
  )
}

export default ForgottenPassword
