import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'
import { useQuests } from 'core'
import { Quest } from './components'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    maxWidth: theme.sizing.main.contentWidth / 1.5,
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const Quests = () => {
  const classes = useStyles()
  const { quests } = useQuests()

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Куестове | Всички стаи</title>
      </Helmet>
      <Grid container className={classes.container} spacing={5}>
        {quests
          .filter(
            (quest) => new Date(quest.expiryDate).getTime() > Date.now() && quest.status === true
          )
          .map((quest) => (
            <Grid item xs={12} key={quest._id}>
              <Quest quest={quest} />
            </Grid>
          ))}
      </Grid>
    </div>
  )
}

export default Quests
