export default (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'REVIEWS_LOADED':
      return {
        loadingReviews: false,
        errorReviews: false,
        errorMessageReviews: null,
        legacyReviews: payload.legacyReviews,
        allReviews: payload.reviews,
        allReviewedRooms: payload.reviews
          .map((review) => review.roomId)
          .reduce((a, b) => {
            if (a.indexOf(b) < 0) a.push(b)
            return a
          }, []),
        approvedAndFinishedReviews: payload.reviews.filter(
          (review) => review.finished && review.trusted
        ),
        waitingForApprovalReviews: payload.reviews.filter(
          (review) => review.finished && !review.trusted
        ),
        approvedAndUnfinishedReviews: payload.reviews.filter(
          (review) => !review.finished && review.trusted
        ),
      }
    case 'REVIEWS_FAIL':
      return {
        ...state,
        loadingReviews: false,
        errorReviews: true,
        errorMessageReviews: payload,
      }
    default:
      return state
  }
}
