import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import {
  CardMedia,
  CardContent,
  CardHeader,
  Typography,
  Dialog,
  DialogActions,
  IconButton,
  Avatar,
  Card,
  Grid,
  Collapse,
  Tooltip,
  Hidden,
  useMediaQuery,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { CardTitle, CardSubTitle, CardButtons, UserRecommendation } from './components'
import { PageURLs } from 'Routes'
import { reviewStyles } from 'helpers/functions'

const Review = (props) => {
  const { user, image, date, room, recommends, review, reviewId } = props
  const ref = useRef(null)
  const theme = useTheme()
  const classes = reviewStyles()
  const collapsedHeight = 65
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const [open, setOpen] = useState(false)
  const [openReview, setOpenReview] = useState(false)
  const [height, setHeight] = useState(0)

  const handleOpenImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCollapse = () => {
    setOpenReview((prev) => !prev)
  }

  useEffect(() => {
    setHeight(ref.current.scrollHeight)
  }, [])

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        style={
          isDesktop
            ? { paddingTop: 0 }
            : { paddingTop: 0, marginBottom: theme.spacing(1), marginTop: theme.spacing(1) }
        }>
        <Card elevation={2}>
          <Hidden mdDown>
            <CardHeader
              className={classes.header}
              disableTypography
              action={<CardButtons room={room} profileId={user?._id} reviewId={reviewId} />}
              avatar={
                <Link to={`${PageURLs.Profile}/${user?._id}`}>
                  <Avatar alt="User Avatar" src={user?.avatar} className={classes.avatar} />
                </Link>
              }
              title={
                <Link to={`${PageURLs.Profile}/${user?._id}`}>
                  <CardTitle name={user?.name} />
                </Link>
              }
              subheader={
                <CardSubTitle>
                  Игра на {Intl.DateTimeFormat('bg-BG').format(new Date(date))}
                </CardSubTitle>
              }
            />
          </Hidden>
          <CardMedia
            className={classes.media}
            title={date}
            image={image ? image : '/image/missing_image.png'}
            onClick={handleOpenImage}
          />
          <UserRecommendation
            name={user?.name}
            userId={user?._id}
            room={room}
            recommends={recommends}
          />
          <CardContent className={classes.content}>
            <Collapse ref={ref} in={openReview} collapsedHeight={collapsedHeight}>
              <Typography align="justify" className={classes.review} onClick={handleCollapse}>
                {review}
              </Typography>
            </Collapse>
            {height > collapsedHeight ? (
              <div
                className={classes.fullReviewButton}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title={openReview ? 'Скрий ревюто' : 'Прочети ревюто'}>
                  <IconButton onClick={handleCollapse} color="primary" size="small">
                    {openReview ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      </Grid>

      <Dialog maxWidth={false} open={open} onClose={handleClose}>
        <DialogActions className={classes.actions}>
          <IconButton style={{ color: theme.palette.white }} size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <img
          alt={date}
          src={image ? image : '/image/missing_image.png'}
          style={{ maxWidth: '100%' }}
        />
      </Dialog>
    </>
  )
}

export default React.memo(Review)
