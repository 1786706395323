import { createTheme } from '@material-ui/core/styles'

import palette from './palette'
import sizing from './sizing'
import typography from './typography'
import overrides from './overrides'

const theme = createTheme({
  palette,
  sizing,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
})

export default theme
