import React, { useState } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Button, TextField, Link, Checkbox, Typography, Paper } from '@material-ui/core'
import { PageURLs } from 'Routes'
import { useAuth } from 'core'
import { useSnackbar } from 'notistack'
import { messages } from 'utils'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    flexBasis: 700,
    margin: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  policyCheckbox: {
    marginLeft: '-14px',
  },
  signUpButton: {
    margin: theme.spacing(2, 0),
  },
}))

const SignUp = () => {
  const classes = useStyles()
  const { register } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [successfulReg, setSuccessfulReg] = useState(false)
  const [formState, setFormState] = useState({
    email: '',
    phone: '',
    password: '',
    name: '',
    policy: false,
    recaptcha: null,
  })

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }))
  }

  const handleSignUp = async (event) => {
    event.preventDefault()
    if (formState.email !== '' && formState.password !== '' && formState.name !== '') {
      const success = await register({
        email: formState.email.toLowerCase(),
        password: formState.password,
        phone: formState.phone,
        name: formState.name,
        recaptcha: formState.recaptcha,
      })
      success && setSuccessfulReg(success)
    } else {
      enqueueSnackbar(messages.form.notFilled, { variant: 'error' })
    }
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Регистрирай се | Всички стаи</title>
      </Helmet>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              {!successfulReg ? (
                <form onSubmit={handleSignUp}>
                  <Paper className={classes.form}>
                    <Typography className={classes.title} variant="h2">
                      Създай нов профил
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      Ползвай имейл, за да създадеш нов профил или просто{' '}
                      <Link component={RouterLink} to={PageURLs.Login} variant="h6">
                        влез с Facebook
                      </Link>
                    </Typography>
                    <TextField
                      required
                      className={classes.textField}
                      fullWidth
                      label="Име"
                      name="name"
                      onChange={handleChange}
                      type="name"
                      value={formState.name || ''}
                      variant="outlined"
                    />
                    <TextField
                      required
                      className={classes.textField}
                      fullWidth
                      label="Имейл"
                      name="email"
                      onChange={handleChange}
                      type="email"
                      value={formState.email || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Телефон"
                      name="phone"
                      onChange={handleChange}
                      type="phone"
                      value={formState.phone || ''}
                      variant="outlined"
                    />
                    <TextField
                      required
                      className={classes.textField}
                      fullWidth
                      label="Парола"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.password || ''}
                      variant="outlined"
                    />
                    <div className={classes.policy}>
                      <Checkbox
                        checked={formState.policy || false}
                        className={classes.policyCheckbox}
                        color="primary"
                        name="policy"
                        required
                        onChange={handleChange}
                      />
                      <Typography
                        className={classes.policyText}
                        color="textSecondary"
                        variant="body1">
                        Прочетох и се съгласявам с{' '}
                        <Link
                          color="primary"
                          component={RouterLink}
                          target="_blank"
                          to="/terms"
                          underline="always"
                          variant="h6">
                          общите условия
                        </Link>
                      </Typography>
                    </div>
                    <ReCAPTCHA
                      sitekey="6Ld1XCQjAAAAAJFmapG8YwbLb_ACSoAQQlxtGm3G"
                      onChange={(value) => setFormState({ ...formState, recaptcha: value })}
                    />
                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      disabled={
                        !(
                          formState.policy &&
                          formState.email !== '' &&
                          formState.password !== '' &&
                          formState.name !== '' &&
                          formState.recaptcha !== null
                        )
                      }
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained">
                      Регистрирай се
                    </Button>
                    <Typography color="textSecondary" variant="body1">
                      Имаш профил?{' '}
                      <Link component={RouterLink} to={PageURLs.Login} variant="h6">
                        Влез
                      </Link>
                    </Typography>
                  </Paper>
                </form>
              ) : (
                <Paper className={classes.form} style={{ paddingBottom: 48 }}>
                  <Typography className={classes.title} variant="h2" align="center">
                    Успешна регистрация
                  </Typography>
                  <Typography color="textSecondary" align="center">
                    Изпратихме имейл за потвърждение до {formState.email}, с който да потвърдиш
                    профила си
                  </Typography>
                </Paper>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignUp.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SignUp)
