import React, { useEffect, useState } from 'react'
import { makeStyles, Grid, CircularProgress } from '@material-ui/core'
import { BigHeading, SmallHeading } from 'layouts/Main/components'
import { MainNews, OtherNews } from './components'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.white,
  },
  grid: {
    maxWidth: theme.sizing.main.contentWidth,
    width: '100%',
  },
}))

const News = () => {
  const classes = useStyles()
  const [latestNews, setLatestNews] = useState(null)

  useEffect(() => {
    const getLatestNews = async () => {
      try {
        const { data } = await axios(`/api/latest-news`)
        setLatestNews(data)
      } catch (error) {
        console.log(error)
      }
    }

    getLatestNews()
  }, [])

  return (
    <div className={classes.root}>
      <BigHeading>Последни ескейпини</BigHeading>
      <SmallHeading></SmallHeading>
      <Grid className={classes.grid} container spacing={5}>
        {latestNews !== null ? (
          <>
            <Grid item xs={12} md={7}>
              <MainNews news={latestNews?.mainNews} />
            </Grid>
            <Grid item xs={12} md={5}>
              <OtherNews news={latestNews?.otherNews} />
            </Grid>
          </>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </div>
  )
}

export default News
