import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@material-ui/core'
import { Search, Row } from './components'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'space-between',
  },
}))

const UsersTable = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [users, setUsers] = useState([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(`/api/admin/users?page=${page}&limit=${rowsPerPage}`)
        setUsers(data.users)
        setCount(data.count)
        setIsLoading(false)
      } catch (error) {
        enqueueSnackbar('Неуспех при търсене на потребители')
        console.error(error)
        setIsLoading(false)
      }
    }

    email === '' && fetchUsers()
    // eslint-disable-next-line
  }, [page, rowsPerPage, email])

  const handlePageChange = (event, page) => {
    setPage(page + 1)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value)
  }

  return (
    <Card {...rest}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Име</TableCell>
                  <TableCell>Имейл</TableCell>
                  <TableCell>Телефон</TableCell>
                  <TableCell>Регистрация</TableCell>
                  <TableCell align="center">Активиран</TableCell>
                  <TableCell>Роля</TableCell>
                  <TableCell align="right">Действия</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  users.map((user) => <Row key={user._id} user={user} />)
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Search
          page={page}
          rowsPerPage={rowsPerPage}
          email={email}
          setEmail={setEmail}
          setIsLoading={setIsLoading}
          setUsers={setUsers}
          setCount={setCount}
        />
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page - 1}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={'Редове на страница:'}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} от ${count !== -1 ? count : `повече от ${to}`}`
          }
        />
      </CardActions>
    </Card>
  )
}

UsersTable.propTypes = {
  className: PropTypes.string,
}

export default UsersTable
