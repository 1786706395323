import React, { useState, useRef } from 'react'
import {
  makeStyles,
  TextField,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Card,
} from '@material-ui/core'
import { useAuth } from 'core'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { isEqual } from 'lodash'
import { resizeImage } from 'helpers'

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  avatar: {
    width: 100,
    height: 100,
    marginRight: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
}))

const MyProfile = () => {
  const classes = useStyles()
  const photo = useRef(null)
  const { user, editProfile } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const initialUser = {
    name: user.name,
    bio: user.bio,
    phone: user.phone,
    avatar: user.avatar,
  }

  const [uploading, setUploading] = useState(false)
  const [form, setForm] = useState(initialUser)

  const handleSubmit = async () => {
    editProfile(form)
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleUploadFile = async (e) => {
    const originalFile = e.target.files[0]
    const resizedFile = await resizeImage(originalFile, 400, 400, 90)
    const formData = new FormData()
    formData.append('file', resizedFile)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/auth/upload', formData, config)

      setForm({ ...form, avatar: data })
      setUploading(false)
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: 'error' })
      setUploading(false)
    }
  }

  return (
    <Card className={classes.root}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" className={classes.field}>
          <Avatar className={classes.avatar} src={form.avatar} />
          <Button component="label" variant="outlined" disabled={uploading} size="small">
            {uploading && <CircularProgress />} Качи друга снимка
            <input
              ref={photo}
              accept="image/*"
              type="file"
              onChange={handleUploadFile}
              style={{ display: 'none' }}
            />
          </Button>
        </Box>
        <TextField
          value={form.name ?? ''}
          variant="outlined"
          name="name"
          label="Име"
          onChange={handleChange}
          className={classes.field}
        />
        <TextField
          value={form.bio ?? ''}
          variant="outlined"
          name="bio"
          label="За мен"
          onChange={handleChange}
          className={classes.field}
        />
        <TextField
          value={form.phone ?? ''}
          variant="outlined"
          name="phone"
          label="Телефон"
          onChange={handleChange}
          className={classes.field}
        />
        <Button
          onClick={handleSubmit}
          className={classes.field}
          variant="contained"
          disabled={isEqual(form, initialUser)}
          color="primary">
          Запази
        </Button>
      </Box>
    </Card>
  )
}

export default MyProfile
