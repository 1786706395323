import React from 'react'
import { Typography, Switch, Grid, FormControl, FormLabel, Box } from '@material-ui/core'

const SetRecommendation = ({ form, setForm }) => {
  const handleChange = () => {
    setForm({ ...form, recommend: !form.recommend })
  }

  return (
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend" required>
          Препоръчваш ли стаята?
        </FormLabel>
        <Box display="flex" alignItems="center" mt={2} mb={1}>
          <Typography variant="overline">Не препоръчвам</Typography>
          <Switch color="primary" checked={form.recommend} onChange={handleChange} />
          <Typography variant="overline">препоръчвам</Typography>
        </Box>
      </FormControl>
    </Grid>
  )
}

export default React.memo(SetRecommendation)
