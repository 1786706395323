import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, DialogActions } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  imageHolder: {
    maxWidth: 183,
    width: '100%',
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  thumbnail: {
    marginRight: theme.spacing(1.5),
    width: '100%',
    cursor: 'pointer',
    height: 0,
    paddingTop: '56.25%', // 16:9
    position: 'relative',
    display: 'block',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
}))

const Image = ({ src, index, images }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [selectedImage, setSelectedImage] = useState(src)
  const [currentIndex, setCurrentIndex] = useState(index)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setSelectedImage(src)
      setCurrentIndex(index)
    }
  }, [src, index, open])

  const handleOpenImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handlePrev = () => {
    if (currentIndex - 1 < 0) {
      setSelectedImage(images[images.length - 1])
      setCurrentIndex(images.length - 1)
    } else {
      setSelectedImage(images[currentIndex - 1])
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleNext = () => {
    if (currentIndex + 1 >= images.length) {
      setSelectedImage(images[0])
      setCurrentIndex(0)
    } else {
      setSelectedImage(images[currentIndex + 1])
      setCurrentIndex(currentIndex + 1)
    }
  }

  return (
    <>
      <div className={classes.imageHolder}>
        <div
          className={classes.thumbnail}
          style={{ backgroundImage: `url(${src})` }}
          onClick={handleOpenImage}
        />
      </div>

      <Dialog maxWidth={false} open={open} onClose={handleClose}>
        <DialogActions className={classes.actions}>
          <IconButton
            style={{ color: theme.palette.white, zIndex: 1001 }}
            size="small"
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        {images.length > 1 && (
          <IconButton
            disableRipple
            style={{
              width: '50%',
              height: '100%',
              color: theme.palette.white,
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              justifyContent: 'flex-start',
            }}
            onClick={handlePrev}>
            <ChevronLeft fontSize="large" />
          </IconButton>
        )}
        {images.length > 1 && (
          <IconButton
            disableRipple
            style={{
              width: '50%',
              height: '100%',
              color: theme.palette.white,
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              justifyContent: 'flex-end',
            }}
            onClick={handleNext}>
            <ChevronRight fontSize="large" />
          </IconButton>
        )}
        <img alt="Gallery" src={selectedImage} style={{ maxWidth: '100%' }} />
      </Dialog>
    </>
  )
}

export default Image
