import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { MenuItem } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  select: {
    '&.MuiInputBase-root': {
      fontSize: 18,
      fontWeight: 700,
      fontFamily: 'Ubuntu',
    },

    '& .MuiOutlinedInput-input': {
      paddingTop: 7,
      paddingBottom: 7,
    },

    '& .MuiSelect-select': {
      backgroundColor: 'transparent !important',
      border: '1px solid #b4b4b4',
      borderRadius: 6,
      color: '#b4b4b4',
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      borderWidth: 1,
    },

    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
  },
  helperText: {
    fontSize: 12,
    color: 'transparent',
    textAlign: 'center',
    textTransform: 'uppercase',
    textIndent: -9999,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

const SelectCity = (props) => {
  const { onChange, helperText, value, children } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Select className={classes.select} onChange={onChange} value={value} variant="outlined">
        <MenuItem value="nocity" disabled>
          град...
        </MenuItem>
        {children}
      </Select>
      <Typography className={classes.helperText}>{helperText}</Typography>
    </div>
  )
}

export default React.memo(SelectCity)
