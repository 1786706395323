import { Button, Link, Snackbar, Typography } from '@material-ui/core'
import React, { Fragment, useState } from 'react'

const CookiesAlert = () => {
  const [open, setOpen] = useState(
    localStorage.getItem('acceptCookiesAlert') === 'true' ? false : true
  )

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
    localStorage.setItem('acceptCookiesAlert', true)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
      message={
        <Typography style={{ color: '#fff' }}>
          Този сайт използва бисквитки (cookies). Като продължавате да използвате този сайт,
          <br /> вие се съгласявате с използваните от нас бисквитки.{' '}
          <Link href="/privacy-and-cookies" target="_blank">
            Научи повече
          </Link>
        </Typography>
      }
      action={
        <Fragment>
          <Button color="primary" size="small" onClick={handleClose}>
            Съгласен съм
          </Button>
        </Fragment>
      }
    />
  )
}

export default CookiesAlert
