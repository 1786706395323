export default {
  common: {
    success: 'Операцията беше успешна',
    fail: 'Опа... нещо се обърка и операцията не беше успешна',
    logout: 'Успешно излезе от профила си, до скоро!',
    notLoggedIn: 'Трябва ти профил за тази част от сайта',
  },
  form: {
    notFilled: 'Моля, попълни формата',
  },
  review: {
    success: 'Оценката е успешно запазена',
    submitted: 'Оценката беше успешно изпратена и очаква преразглеждане от администратор',
    noRights: 'Нямаш право да попълваш тази оценка',
    failurePlural: 'Не можахме да вземем ревютата за тази стая :(',
  },
  admin: {
    review: {
      updateFail: 'Нещо се обърка и ревюто не беше променено',
      updateSuccess: 'Успешно редактира ревюто',
      approve: 'Ревюто е успешно потвърдено',
      disapprove: 'Ревюто е премахнато от потвърдени',
      createSuccess: 'Ревюто е създадено успешно',
      createFail: 'Ревюто не беше създадено успешно',
      deleteSuccess: 'Ревюто е изтрито успешно.',
      deleteFail: 'Нещо се обърка и ревюто не беше изтрито',
    },
    user: {
      deleteSuccess: 'Потребителят беше деактивиран успешно',
      deleteFail: 'Нещо се обърка и потребителят не беше деактивиран',
      enableSuccess: 'Потребителят беше активиран успешно',
      enableFail: 'Нещо се обърка и потребителят не беше активиран',
    },
    escapeRoom: {
      updateFail: 'Нещо се обърка и стаята не беше запазена',
      updateSuccess: 'Успешно редактира стаята',
      createFail: 'Нещо се обърка и стаята не беше създадена',
    },
    quest: {
      updateFail: 'Нещо се обърка и куестът не беше запазен',
      updateSuccess: 'Успешно редактира куеста',
      createFail: 'Нещо се обърка и куестът не беше създаден',
    },
    news: {
      updateFail: 'Нещо се обърка и новината не беше променена',
      updateSuccess: 'Успешно редактира новината',
      approve: 'Новината е успешно потвърдена',
      disapprove: 'Новината е премахната от потвърдени',
      createSuccess: 'Новината е създадена успешно',
      createFail: 'Новината не беше създадена успешно',
      deleteSuccess: 'Новината е изтрита успешно.',
      deleteFail: 'Нещо се обърка и новината не беше изтрита',
    },
  },
}
