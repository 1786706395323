import React from 'react'
import { withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

const SloganTypography = withStyles((theme) => ({
  root: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 400,
    background: theme.palette.white,
    color: theme.palette.mainLayout.grey,
    width: '100%',
    marginTop: theme.spacing(2),
  },
}))(Typography)

const SloganHeading = ({ children }) => {
  return <SloganTypography>{children}</SloganTypography>
}

export default React.memo(SloganHeading)
