import React, { useState, useRef } from 'react'
import { Box, Button, Grid, CircularProgress } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ExistingPhoto } from '..'
import axios from 'axios'
import { resizeImage } from 'helpers'

const UploadPhoto = ({ form, setForm }) => {
  const photo = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [uploading, setUploading] = useState(false)

  const handleUploadFile = async (e) => {
    const originalFile = e.target.files[0]
    const resizedFile = await resizeImage(originalFile, 1600, 1080, 90)
    const formData = new FormData()
    formData.append('file', resizedFile)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/auth/upload', formData, config)

      setForm({ ...form, file: data })
      setUploading(false)
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: 'error' })
      setUploading(false)
    }
  }

  return (
    <Grid item xs={12} style={{ height: 400, overflow: 'hidden', position: 'relative' }}>
      {form.file !== '' ? (
        <Box display="flex" alignItems="center" flexDirection="column">
          <ExistingPhoto rectangle image={form.file} />
          <Button
            style={{ position: 'absolute', bottom: 16 }}
            component="label"
            variant="contained"
            disabled={uploading}>
            {uploading && <CircularProgress />} Качи друга снимка
            <input
              ref={photo}
              accept="image/*"
              type="file"
              onChange={handleUploadFile}
              style={{ display: 'none' }}
            />
          </Button>
        </Box>
      ) : (
        <Button
          style={{ width: '100%', height: '100%' }}
          component="label"
          variant="outlined"
          disabled={uploading}>
          {uploading && <CircularProgress />} Качи снимка от играта
          <input
            ref={photo}
            accept="image/*"
            type="file"
            onChange={handleUploadFile}
            style={{ display: 'none' }}
          />
        </Button>
      )}
    </Grid>
  )
}

export default React.memo(UploadPhoto)
