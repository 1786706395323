import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { UsersToolbar, UsersTable } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))

const UserList = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <UsersToolbar />
      <UsersTable />
    </div>
  )
}

export default UserList
