import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/styles'

const Title = ({ children }) => {
  const theme = useTheme()
  return (
    <Typography variant="h1" style={{ color: theme.palette.white }}>
      {children}
    </Typography>
  )
}

export default Title
