import React, { createContext, useReducer, useContext, useEffect } from 'react'
import { ContextDevTool } from 'react-context-devtool'
import reducer from './reducer'
import axios from 'axios'
import { useAuth } from 'core'

const ReviewsContext = createContext([])

const initialState = {
  loadingReviews: true,
  errorReviews: false,
  errorMessageReviews: null,
  allReviews: [],
  legacyReviews: [],
  allReviewedRooms: [],
  approvedAndFinishedReviews: [],
  waitingForApprovalReviews: [],
  approvedAndUnfinishedReviews: [],
}

export const ReviewsProvider = ({ children }) => {
  const { user } = useAuth()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    loadReviews()
    // eslint-disable-next-line
  }, [user])

  const loadReviews = async () => {
    try {
      const res = await axios(`/api/customer/reviews/${user._id}`)
      dispatch({
        type: 'REVIEWS_LOADED',
        payload: res.data,
      })
    } catch (error) {
      dispatch({
        type: 'REVIEWS_FAIL',
        payload: error.message,
      })
    }
  }

  return (
    <ReviewsContext.Provider
      value={{
        loadingReviews: state.loadingReviews,
        errorReviews: state.errorReviews,
        errorMessageReviews: state.errorMessageReviews,
        legacyReviews: state.legacyReviews,
        allReviews: state.allReviews,
        allReviewedRooms: state.allReviewedRooms,
        approvedAndFinishedReviews: state.approvedAndFinishedReviews,
        waitingForApprovalReviews: state.waitingForApprovalReviews,
        approvedAndUnfinishedReviews: state.approvedAndUnfinishedReviews,
        loadReviews,
      }}>
      <ContextDevTool context={ReviewsContext} id="reviews" displayName="Reviews" />
      {children}
    </ReviewsContext.Provider>
  )
}

export const useReviews = () => {
  const context = useContext(ReviewsContext)

  if (context === undefined) {
    throw new Error('useReviews can only be used inside ReviewsProvider')
  }

  return context
}
