import React from 'react'
import { CardMedia, makeStyles, Typography, Grid, Hidden, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  news: {
    position: 'relative',
    display: 'flex',
    marginBottom: '5%',
    cursor: 'pointer',
    transition: 'all 0.2s',

    '&:hover': {
      filter: 'brightness(0.85)',
      transition: 'all 0.2s',
    },
  },
  media: {
    height: 0,
    borderRadius: 4,
    marginRight: theme.spacing(2),
    paddingTop: '56.25%',

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  category: {
    position: 'absolute',
    bottom: 8,
    left: theme.spacing(1),
    cursor: 'pointer',
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  heading: {
    lineHeight: '28px',
  },
  transparent: {
    color: 'transparent',
  },
}))

const OtherNews = ({ news = [] }) => {
  const classes = useStyles()

  return news.length
    ? news.map((initialNews) => (
      <Link key={initialNews._id} to={`/news/${initialNews.shortname}`}>
        <Grid container className={classes.news}>
          <Grid item xs={12} md={5}>
            <Box style={{ position: 'relative' }}>
              <CardMedia
                className={classes.media}
                image={initialNews.image ? initialNews.image : '/image/missing_image.png'}
              />
              {/* <Box display="flex" className={classes.category}>
                  {initialNews.rubrics.map((rubric) => (
                    <Chip
                      style={{ marginRight: 4, cursor: 'pointer' }}
                      key={rubric}
                      label={rubric}
                    />
                  ))}
                </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} className={classes.texts}>
            <Typography gutterBottom variant="body2">
              {Intl.DateTimeFormat('bg-BG').format(new Date(initialNews.createdAt))} |{' '}
              {initialNews.comments.length === 1
                ? `${initialNews.comments.length} коментар`
                : `${initialNews.comments.length} коментара`}
            </Typography>

            <Typography className={classes.heading} variant="h5">
              {initialNews.title}
            </Typography>
            <Hidden mdDown>
              <div className={classes.transparent}>.</div>
            </Hidden>
          </Grid>
        </Grid>
      </Link>
    ))
    : ''
}

export default OtherNews
