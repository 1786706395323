import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    paddingTop: 20,
    width: '100%',
  },
  svg: {
    transform: 'translateX(-50%) scaleX(-1)',
    position: 'absolute',
    padding: 0,
    margin: 0,
    left: '50%',
    bottom: 0,
    top: 'auto',
    width: 'calc(100% + 1.5px)',
  },
})

const CurvedSeparator = (props) => {
  const theme = useTheme()
  const {
    topColor = theme.palette.white,
    bottomColor = theme.palette.background.default,
    height = 160,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ height: height }}>
      <svg
        style={{ backgroundColor: topColor, height: height }}
        className={classes.svg}
        fill={bottomColor}
        viewBox="0 0 4.66666 0.333331"
        preserveAspectRatio="none">
        <path d="M-7.87402e-006 0.0148858l0.00234646 0c0.052689,0.0154094 0.554437,0.154539 1.51807,0.166524l0.267925 0c0.0227165,-0.00026378 0.0456102,-0.000582677 0.0687992,-0.001 1.1559,-0.0208465 2.34191,-0.147224 2.79148,-0.165524l0.0180591 0 0 0.166661 -7.87402e-006 0 0 0.151783 -4.66666 0 0 -0.151783 -7.87402e-006 0 0 -0.166661z"></path>
      </svg>
    </div>
  )
}

export default CurvedSeparator
