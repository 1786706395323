import React, { useState } from 'react'
import MinimizeIcon from '@material-ui/icons/Minimize'
import { UploadImage } from './components'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from '@ckeditor/ckeditor5-build-classic'
import {
  Typography,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Grid,
  Link,
  FormLabel,
} from '@material-ui/core'
import { linkify, USER_GROUP } from 'helpers'
import { useAuth, useQuests } from 'core'
import { Link as RouterLink } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { transport } from 'utils'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ setOpen, open }) {
  const { user } = useAuth()
  const { createQuestOwner } = useQuests()

  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    image: '',
    title: '',
    shortname: '',
    company: {},
    city: 'София',
    address: '',
    transport: 'Автомобил',
    price: 0,
    description: '',
    complexity: 3,
    people: [2, 6],
    gameLength: 60,
    ageRestriction: '',
    site: '',
    email: '',
    phone: '',
    facebook: '',
    instagram: '',
    twitter: '',
    tripadvisor: '',
    messenger: '',
  })

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCreate = async () => {
    setIsLoading(true)
    createQuestOwner(form, handleClose)
    setIsLoading(false)
  }

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <DialogTitle>Създай нов куест</DialogTitle>
      <DialogContent>
        {user.userGroup !== USER_GROUP.admin.id && (
          <DialogContentText>Куестът трябва да бъде одобрен от администратор</DialogContentText>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Изображения
            </Typography>
          </Grid>
          <UploadImage form={form} setForm={setForm} />

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Детайли за куеста
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="title"
              label="Име на куеста"
              fullWidth
              value={form.title}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="shortname"
              helperText={`Линкът ще изглежда така: https://vsichkistai.bg/quests#${linkify(
                form.shortname
              )}`}
              label="Линк към куеста"
              fullWidth
              value={linkify(form.shortname)}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              select
              size="small"
              variant="outlined"
              name="company"
              label="Компания"
              helperText={
                isEmpty(form.company) ? (
                  <>
                    Не си избрал компания.{' '}
                    <Link component={RouterLink} to="/admin/companies">
                      Искаш ли да създадеш?
                    </Link>
                  </>
                ) : (
                  `Избрана компания: ${form.company?.name}`
                )
              }
              fullWidth
              value={form.company}
              onChange={handleChange}>
              {user.companies.map((company) => (
                <MenuItem key={company} value={company}>
                  {company.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Адресна информация
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="city"
              label="Градове, в които се провежда"
              helperText='Напиши "Онлайн", ако куестът е само в дигитален формат'
              fullWidth
              value={form.city}
              onChange={handleChange}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <TextField
              select
              size="small"
              variant="outlined"
              name="transport"
              label="Транспорт"
              helperText='Избери "Никакъв", ако куестът е само в дигитален формат'
              fullWidth
              value={form.transport}
              onChange={handleChange}>
              {transport.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Основна информация
            </Typography>
          </Grid>

          <Grid item lg={12} xs={12}>
            <FormLabel component="legend">Описание на куеста</FormLabel>
            <CKEditor
              editor={Editor}
              data={form.description}
              onChange={(event, editor) => {
                const data = editor.getData()
                setForm({ ...form, description: data })
              }}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="complexity"
              label="Трудност"
              type="number"
              helperText="Число по-голямо или равно на 1 и по-малко или равно на 5"
              fullWidth
              value={form.complexity >= 5 ? 5 : form.complexity <= 0 ? 1 : form.complexity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              size="small"
              variant="outlined"
              name="people"
              label="Играчи"
              type="number"
              helperText="от"
              value={form.people[0]}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: [+e.target.value, form.people[1]] })
              }
            />
            <MinimizeIcon />
            <TextField
              size="small"
              variant="outlined"
              name="people"
              label="Играчи"
              type="number"
              helperText="до"
              value={form.people[1]}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: [form.people[0], +e.target.value] })
              }
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="gameLength"
              label="Продължителност на играта"
              type="number"
              helperText="в минути"
              fullWidth
              value={form.gameLength}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="ageRestriction"
              label="Възрастово ограничение"
              type="number"
              fullWidth
              value={form.ageRestriction}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="price"
              label="Цена (в лева)"
              helperText="Ако цената не е фиксирана, раздели най-малката сума и най-голямата с тире (напр. 100-200)"
              fullWidth
              value={form.price}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Контактна информация
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="site"
              label="Уебсайт на куеста"
              helperText="Препоръчително е да води до резервационната форма"
              fullWidth
              value={form.site}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="email"
              label="Имейл на куеста"
              type="email"
              fullWidth
              value={form.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="phone"
              label="Телефонен номер"
              type="phone"
              fullWidth
              value={form.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="facebook"
              label="Facebook страница"
              helperText="Моля, напишете пълния линк (пример: https://facebook.com/SawRoomBG/)"
              fullWidth
              value={form.facebook}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="messenger"
              label="Messenger линк"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.messenger}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="instagram"
              label="Instagram страница"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.instagram}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="tripadvisor"
              label="Tripadvisor страница"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.tripadvisor}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="twitter"
              label="Twitter страница"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.twitter}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} color="primary" disabled={isLoading}>
          Създай
        </Button>
        <Button onClick={handleClose}>Отмени</Button>
      </DialogActions>
    </Dialog>
  )
}
