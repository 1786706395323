export default [
  'София',
  'Пловдив',
  'Варна',
  'Бургас',
  'Русе',
  'Стара Загора',
  'Плевен',
  'Сливен',
  'Добрич',
  'Шумен',
  'Перник',
  'Ямбол',
  'Хасково',
  'Пазарджик',
  'Благоевград',
  'Враца',
  'Габрово',
  'Велико Търново',
  'Видин',
  'Казанлък',
  'Асеновград',
  'Кюстендил',
  'Монтана',
  'Димитровград',
  'Кърджали',
  'Ловеч',
  'Силистра',
  'Търговище',
  'Разград',
  'Дупница',
  'Горна Оряховица',
  'Смолян',
  'Свищов',
  'Петрич',
  'Лом',
  'Самоков',
  'Сандански',
  'Карлово',
  'Нова Загора',
  'Велинград',
  'Севлиево',
  'Троян',
]
