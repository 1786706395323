import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
})

const ComponentTitle = ({ children, color = 'primary', noGutter = false }) => {
  const classes = useStyles()

  return (
    <Typography
      className={classes.root}
      color={color}
      variant="h6"
      gutterBottom={!noGutter ? true : false}>
      {children}
    </Typography>
  )
}

export default ComponentTitle
