import React from 'react'
import { Box, Button, Grid } from '@material-ui/core'

const Submit = ({ form, handleSubmit }) => {
  const isDisabled = form.image && form.title && form.content ? false : true

  return (
    <Grid item xs={12}>
      <Box display="flex" mb={2}>
        <Button variant="contained" color="primary" disabled={isDisabled} onClick={handleSubmit}>
          Запази
        </Button>
      </Box>
    </Grid>
  )
}

export default Submit
