import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Typography } from '@material-ui/core'
import { useAuth } from 'core'
import { Link } from 'react-router-dom'
import { PageURLs } from 'Routes'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}))

const Profile = (props) => {
  const { className, ...rest } = props
  const { user } = useAuth()

  const classes = useStyles()

  return (
    <Link to={PageURLs.Profile}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Avatar alt="Person" className={classes.avatar} src={user.avatar} />
        <Typography className={classes.name} variant="h4">
          {user.name}
        </Typography>
        <Typography variant="body2">{user.email}</Typography>
      </div>
    </Link>
  )
}

Profile.propTypes = {
  className: PropTypes.string,
}

export default Profile
