import React from 'react'
import { useTheme } from '@material-ui/styles'

const Logo = ({ url }) => {
  const theme = useTheme()

  return (
    <img
      alt="Company Logo"
      src={url}
      style={{
        maxHeight: 60,
        height: '100%',
        marginRight: theme.spacing(2),
      }}
    />
  )
}

export default Logo
