import React, { useState, useEffect } from 'react'
import { EscapeRoom, Filters } from './components'
import { makeStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider'
import Isotope from 'isotope-layout'
import { useEscapeRooms } from 'core'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import random from 'random'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  container: {
    paddingLeft: theme.sizing.main.spaceBetweenContents,
    maxWidth: theme.sizing.main.contentWidth,
    width: '100%',
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  test: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  divider: {
    width: '100%',
  },
}))

const EscapeRooms = () => {
  const location = useLocation()
  const classes = useStyles()
  const { escaperooms, mainCity } = useEscapeRooms()
  const [filteredGenre, setFilteredGenre] = useState(location.state?.genre || '*')
  const [filteredCity, setFilteredCity] = useState(location.state?.city || mainCity)
  const [isotope, setIsotope] = useState(null)

  useEffect(() => {
    setIsotope(
      new Isotope('#filter-container', {
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
      })
    )
  }, [])

  useEffect(() => {
    if (isotope) {
      filteredGenre === '*'
        ? isotope.arrange({ filter: `*.${filteredCity}` })
        : isotope.arrange({ filter: `.${filteredGenre}.${filteredCity}` })
    }
  }, [isotope, filteredGenre, filteredCity])

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Ескейп стаи в {filteredCity} | Всички стаи</title>
      </Helmet>
      <Divider className={classes.divider} />

      <Filters
        filteredCity={filteredCity}
        setFilteredCity={setFilteredCity}
        filteredGenre={filteredGenre}
        setFilteredGenre={setFilteredGenre}
      />

      <div id="filter-container" className={classes.container}>
        {escaperooms
          .sort(() => random.float(0.4, 0.6) - random.float())
          .filter(
            (escaperoom) =>
              new Date(escaperoom.expiryDate).getTime() > Date.now() && escaperoom.status === true
          )
          .sort(() => random.float(0.4, 0.6) - random.float())
          .map((escaperoom) => (
            <EscapeRoom
              key={escaperoom._id}
              escaperoom={escaperoom}
              className={`filter-item ${escaperoom.genres.join(' ')} ${escaperoom.city} ${
                classes.test
              }`}
            />
          ))
          .sort(() => random.float(0.4, 0.6) - random.float())}
      </div>
    </div>
  )
}

export default React.memo(EscapeRooms)
