import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  userLevel: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 12,
  },
}))

const CardTitle = ({ name }) => {
  const classes = useStyles()

  return (
    <Typography component="div" className={classes.root}>
      {name}
    </Typography>
  )
}

export default CardTitle
