import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@material-ui/core'
import { Toolbar, Row } from './components'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'space-between',
  },
}))

const NewsTable = (props) => {
  const { className, ...rest } = props
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [news, setNews] = useState([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  const url = `/api/owner/news`

  const columns = [
    { name: 'Заглавие', headAlign: 'inherit' },
    { name: 'Линк', headAlign: 'inherit' },
    { name: 'Автор', headAlign: 'inherit' },
    { name: 'Създадена', headAlign: 'inherit' },
    { name: 'Тагната стая', headAlign: 'inherit' },
    { name: 'Активирана', headAlign: 'center' },
    { name: 'Още', headAlign: 'right' },
  ]

  useEffect(() => {
    const fetchNews = async () => {
      setIsLoading(true)
      setIsUpdated(false)
      try {
        const { data } = await axios.get(`${url}?page=${page}&limit=${rowsPerPage}`)
        setNews(data.news)
        setCount(data.count)
        setIsLoading(false)
      } catch (error) {
        enqueueSnackbar('Неуспех при търсене на ревюта')
        console.error(error)
        setIsLoading(false)
      }
    }

    fetchNews()
    // eslint-disable-next-line
  }, [page, rowsPerPage, isUpdated])

  const handlePageChange = (event, page) => {
    setPage(page + 1)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value)
  }

  return (
    <>
      <Toolbar setPage={setPage} />
      <Card {...rest} className={classes.root}>
        <CardContent className={classes.content}>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.name} align={column.headAlign}>
                      {column.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  news.map((singleNews) => (
                    <Row
                      key={singleNews._id}
                      columns={columns}
                      news={singleNews}
                      setIsUpdated={setIsUpdated}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={columns.length}>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <span />
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={'Редове на страница:'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} от ${count !== -1 ? count : `повече от ${to}`}`
            }
          />
        </CardActions>
      </Card>
    </>
  )
}

NewsTable.propTypes = {
  className: PropTypes.string,
}

export default NewsTable
