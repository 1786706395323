import React, { Suspense, lazy } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import {
  RouteWithLayout,
  PrivateRouteWithLayout,
  AuthenticatedRouteWithLayout,
  UnauthenticatedRouteWithLayout,
} from './components'
import { Admin as AdminLayout, Main as MainLayout } from './layouts'
import { useEscapeRooms, useAuth } from 'core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import TextsmsIcon from '@material-ui/icons/Textsms'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'
import BusinessIcon from '@material-ui/icons/Business'
import ReceiptIcon from '@material-ui/icons/Receipt'
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong'
import LocationCityIcon from '@material-ui/icons/LocationCity'

import { useQuests, useReviews } from 'core/contexts'
import { USER_GROUP } from 'helpers'

import { Loading } from './views'

// Main Views
const HomeView = lazy(() => import('./views/Home'))
const EscapeRoomsView = lazy(() => import('./views/EscapeRooms'))
const EscapeRoomView = lazy(() => import('./views/EscapeRoom'))
const CreateReviewView = lazy(() => import('./views/CreateReview'))
const NotFoundView = lazy(() => import('./views/NotFound'))
const UnauthorizedView = lazy(() => import('./views/Unauthorized'))
const ProfileView = lazy(() => import('./views/Profile'))
const NewsView = lazy(() => import('./views/News'))
const QuestsView = lazy(() => import('./views/Quests'))
const TermsView = lazy(() => import('./views/Terms'))
const TermsToApplyView = lazy(() => import('./views/TermsToApply'))
const PrivacyAndCookiesView = lazy(() => import('./views/PrivacyAndCookies'))

// Admin Views
const DashboardView = lazy(() => import('./views/Dashboard'))
const UserListView = lazy(() => import('./views/UserList'))
const ReviewListView = lazy(() => import('./views/ReviewList'))
const CreateReviewOwnerView = lazy(() => import('./views/CreateReviewOwner'))
const CreateNewsView = lazy(() => import('./views/CreateNews'))
const EscapeRoomListView = lazy(() => import('./views/EscapeRoomList'))
const QuestListView = lazy(() => import('./views/QuestList'))
const CompanyListView = lazy(() => import('./views/CompanyList'))
const NewsListView = lazy(() => import('./views/NewsList'))
const ChosenRoomsView = lazy(() => import('./views/ChosenRooms'))

// Minimal Views
const ChangePasswordView = lazy(() => import('./views/ChangePassword'))
const ForgottenPasswordView = lazy(() => import('./views/ForgottenPassword'))
const VerifyView = lazy(() => import('./views/Verify'))
const SignUpView = lazy(() => import('./views/SignUp'))
const SignInView = lazy(() => import('./views/SignIn'))

export const PageURLs = {
  Home: '/',
  EscapeRooms: '/escape-rooms',
  Quests: '/quests',
  Login: '/login',
  Register: '/register',
  CreateReview: '/create-review',
  Profile: '/profile',
  Store: '/store',
  News: '/news',
}

export const NavbarLinks = [
  {
    title: 'Начало',
    href: PageURLs.Home,
    isAuthenticated: false,
    icon: <DashboardIcon />,
    exact: true,
  },
  {
    title: 'Ескейп стаи',
    href: PageURLs.EscapeRooms,
    isAuthenticated: false,
    icon: <MeetingRoomIcon />,
  },
  {
    title: 'Куестове',
    href: PageURLs.Quests,
    isAuthenticated: false,
    icon: <LocationCityIcon />,
  },
  {
    title: 'Моите игри',
    href: `${PageURLs.Profile}`,
    isAuthenticated: true,
    icon: <TextsmsIcon />,
  },
  {
    title: 'Оцени стая',
    href: `${PageURLs.CreateReview}`,
    isAuthenticated: false,
    icon: <CenterFocusStrongIcon />,
  },
]

export const AdminNavbarLinks = [
  {
    title: 'Табло',
    href: '/admin/dashboard',
    icon: <DashboardIcon />,
    isVisible: [USER_GROUP.admin.id, USER_GROUP.owner.id],
  },
  {
    title: 'Потребители',
    href: '/admin/users',
    icon: <PeopleIcon />,
    isVisible: [USER_GROUP.admin.id],
  },
  {
    title: 'Игри',
    href: '/admin/reviews',
    icon: <TextsmsIcon />,
    isVisible: [USER_GROUP.admin.id, USER_GROUP.owner.id],
  },
  {
    title: 'Моите Компании',
    href: '/admin/companies',
    icon: <BusinessIcon />,
    isVisible: [USER_GROUP.admin.id, USER_GROUP.owner.id],
  },
  {
    title: 'Ескейп стаи',
    href: '/admin/escape-rooms',
    icon: <MeetingRoomIcon />,
    isVisible: [USER_GROUP.admin.id, USER_GROUP.owner.id],
  },
  {
    title: 'Куестове',
    href: '/admin/quests',
    icon: <LocationCityIcon />,
    isVisible: [USER_GROUP.admin.id, USER_GROUP.owner.id],
  },
  {
    title: 'Новини',
    href: '/admin/news',
    icon: <ReceiptIcon />,
    isVisible: [USER_GROUP.admin.id, USER_GROUP.owner.id],
  },
  {
    title: 'Избрани стаи',
    href: '/admin/chosen-rooms',
    icon: <CenterFocusStrongIcon />,
    isVisible: [USER_GROUP.admin.id],
  },
]

const Routes = () => {
  const { loadingAuth, user, isAuthenticated } = useAuth()
  const { loadingEscapeRooms } = useEscapeRooms()
  const { loadingReviews } = useReviews()
  const { loadingQuests } = useQuests()

  return !loadingEscapeRooms && !loadingAuth && !loadingReviews && !loadingQuests ? (
    <Suspense fallback={<Loading />}>
      <Switch>
        {/* Redirects */}
        <Redirect from="/escape-rooms/sawroom-2-revenge" to="/escape-rooms/sawroom-the-revenge" />
        <Redirect
          from="/escape-rooms/el-patrón-по-стъпките-на-пабло"
          to="/escape-rooms/el-patron"
        />
        <Redirect
          from="/escape-rooms/artificial-intelligence-изкуствен-интелект-ex-dextrophobia-vol2"
          to="/escape-rooms/artificial-intelligence"
        />
        <Redirect from="/escape-rooms/атлантида" to="/escape-rooms/atlantida" />
        <Redirect
          from="/escape-rooms/room-66-ii-по-действителен-случай"
          to="/escape-rooms/room66-2"
        />
        <Redirect from="/escape-rooms/тайният-бар-на-ал-капоне" to="/escape-rooms/al-capone-bar" />
        <Redirect
          from="/escape-rooms/детектива-maшината-на-времето"
          to="/escape-rooms/the-detective-and-the-time-machine"
        />
        <Redirect from="/escape-rooms/тротил" to="/escape-rooms/trotil" />
        <Redirect from="/escape-rooms/железният-трон" to="/escape-rooms/iron-throne" />
        <Redirect from="/escape-rooms/клопката" to="/escape-rooms/klopkata" />
        <Redirect
          from="/escape-rooms/детективи-на-бъдещето"
          to="/escape-rooms/detektivi-na-badeshteto"
        />
        <Redirect from="/escape-rooms/ей-там" to="/escape-rooms/ey-tam" />
        <Redirect from="/escape-rooms/имение-клифстоун" to="/escape-rooms/cliffstone-mansion" />
        <Redirect
          from="/escape-rooms/имало-едно-време-забранено-за-възрастни"
          to="/escape-rooms/once-upon-a-time"
        />
        {/* Main Views */}
        <RouteWithLayout component={HomeView} exact layout={MainLayout} path={PageURLs.Home} />
        {isAuthenticated ? (
          <Redirect exact from={PageURLs.Profile} to={`${PageURLs.Profile}/${user._id}`} />
        ) : (
          <Redirect exact from={PageURLs.Profile} to={`${PageURLs.Login}`} />
        )}
        <RouteWithLayout component={TermsView} layout={MainLayout} exact path={'/terms'} />
        <RouteWithLayout
          component={TermsToApplyView}
          layout={MainLayout}
          exact
          path={'/terms-to-apply'}
        />
        <RouteWithLayout
          component={PrivacyAndCookiesView}
          layout={MainLayout}
          exact
          path={'/privacy-and-cookies'}
        />
        <RouteWithLayout
          component={ProfileView}
          layout={MainLayout}
          exact
          path={`${PageURLs.Profile}/:profileId`}
        />
        <RouteWithLayout
          component={ProfileView}
          layout={MainLayout}
          exact
          path={`${PageURLs.Profile}/:profileId/review/:reviewId`}
        />
        <RouteWithLayout
          component={ProfileView}
          layout={MainLayout}
          exact
          path={`${PageURLs.Profile}/:profileId/legacy-review/:reviewId`}
        />
        <RouteWithLayout
          component={ProfileView}
          layout={MainLayout}
          exact
          path={`${PageURLs.Profile}/:profileId/settings`}
        />
        <RouteWithLayout
          component={EscapeRoomsView}
          exact
          layout={MainLayout}
          path={PageURLs.EscapeRooms}
        />
        <RouteWithLayout
          component={NewsView}
          exact
          layout={MainLayout}
          path={`${PageURLs.News}/:newsId`}
        />
        <AuthenticatedRouteWithLayout
          component={CreateReviewView}
          exact
          layout={MainLayout}
          path={`${PageURLs.CreateReview}`}
        />
        <AuthenticatedRouteWithLayout
          component={CreateReviewView}
          exact
          layout={MainLayout}
          path={`${PageURLs.CreateReview}/:id`}
        />
        <RouteWithLayout
          component={EscapeRoomView}
          exact
          layout={MainLayout}
          path={`${PageURLs.EscapeRooms}/:shortName`}
        />
        <Redirect from="/all-real-life-quests" to={PageURLs.Quests} />
        <Redirect from="/ескейп-стаи" to={PageURLs.EscapeRooms} />
        <Redirect from="/ескейп-стаи/:city" to={PageURLs.EscapeRooms} />
        <RouteWithLayout component={QuestsView} exact layout={MainLayout} path={PageURLs.Quests} />

        {/* Admin Views */}
        <Redirect exact from="/admin" to="/admin/dashboard" />
        <PrivateRouteWithLayout
          component={DashboardView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/dashboard"
        />
        <PrivateRouteWithLayout
          component={NewsListView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/news"
        />
        <PrivateRouteWithLayout
          component={UserListView}
          acceptedGroups={[USER_GROUP.admin.id]}
          exact
          layout={AdminLayout}
          path="/admin/users"
        />
        <PrivateRouteWithLayout
          component={ReviewListView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/reviews"
        />
        <PrivateRouteWithLayout
          component={EscapeRoomListView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/escape-rooms"
        />
        <PrivateRouteWithLayout
          component={QuestListView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/quests"
        />
        <PrivateRouteWithLayout
          component={CreateReviewOwnerView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/create-review"
        />
        <PrivateRouteWithLayout
          component={CreateNewsView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/create-news"
        />
        <PrivateRouteWithLayout
          component={CompanyListView}
          acceptedGroups={[USER_GROUP.admin.id, USER_GROUP.owner.id]}
          exact
          layout={AdminLayout}
          path="/admin/companies"
        />
        <PrivateRouteWithLayout
          component={ChosenRoomsView}
          acceptedGroups={[USER_GROUP.admin.id]}
          exact
          layout={AdminLayout}
          path="/admin/chosen-rooms"
        />
        {/* Everything else */}
        <UnauthenticatedRouteWithLayout
          component={SignUpView}
          exact
          layout={MainLayout}
          path={PageURLs.Register}
        />
        <UnauthenticatedRouteWithLayout
          component={SignInView}
          exact
          layout={MainLayout}
          path={PageURLs.Login}
        />
        <UnauthenticatedRouteWithLayout
          component={VerifyView}
          exact
          layout={MainLayout}
          path="/verify/:userId/:registrationHash"
        />
        <UnauthenticatedRouteWithLayout
          component={ChangePasswordView}
          exact
          layout={MainLayout}
          path="/change-password/:userId/:forgottenPasswordHash"
        />
        <UnauthenticatedRouteWithLayout
          component={ForgottenPasswordView}
          exact
          layout={MainLayout}
          path="/forgotten-password"
        />
        <AuthenticatedRouteWithLayout
          component={ChangePasswordView}
          exact
          layout={MainLayout}
          path="/change-password"
        />
        <RouteWithLayout component={NotFoundView} exact layout={MainLayout} path="/404" />
        <RouteWithLayout component={UnauthorizedView} exact layout={MainLayout} path="/403" />
        <Redirect to="/404" />
      </Switch>
    </Suspense>
  ) : (
    <Loading />
  )
}

export default Routes
