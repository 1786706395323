import { cloneElement } from 'react'
import { useScrollTrigger } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

const ScrollLogic = ({ children }) => {
  const theme = useTheme()
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 30 })

  return cloneElement(children, {
    elevation: trigger ? 1 : 0,
    style: trigger
      ? { height: theme.sizing.header.scrollHeight, transition: 'height 0.3s' }
      : { height: theme.sizing.header.height, transition: 'height 0.3s' },
  })
}

export default ScrollLogic
