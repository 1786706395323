import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  list: {
    '& li': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))

const Dashboard = () => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom paragraph>
        Табло
      </Typography>
      <Typography gutterBottom>
        Здравейте, колеги. Добре дошли в администраторския панел на сайта.
      </Typography>
      <Typography>
        По-долу ще намерите повече информация за това как се оперира с панела.
      </Typography>
      <Typography gutterBottom>
        Препоръчително е да прочетете цялата информация, за да не се чудите как стават някои от
        нещата и да няма грешки.
      </Typography>
      <div style={{ maxWidth: 600 }}>
        <Accordion
          style={{ marginTop: 16 }}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header">
            <Typography className={classes.heading}>Процес по оценяване на игра</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Typography>
              Вече собствениците на стаите (и гейм мастърите) ще създават оценките, вместо
              потребителите, а самите те само ще "довършват" оценките, като напишат ревю и изберат
              дали препоръчват стаята или не.
              <br />
              Самият процес е описан подробно по-долу:
              <br />
              <ul className={classes.list}>
                <li>
                  След като отборът излезе от стаята, гейм мастърът пита потребителите за техните
                  имейли (необходим е поне един имейл)
                </li>
                След като играчите изиграят стаята ви, гейм мастърът ще ги пита за имейлите им и ще
                им обясни, че ще създаде игра във vsichkistai, където ще могат да видят и снимката
                от играта си.
                <li>Гейм мастърът оформя оценката в администраторския панел</li>
                След като получи поне един имейл (може да ползва само този от резервацията), гейм
                мастърът влиза в администраторския панел, където натиска бутона за създаване на
                игра. Там въвежда всички необходими данни - качва снимка, въвежда всички имейли,
                които е получил от отбора, избира дата на игра и стаята, която трябва да бъде
                оценена и след това натиска "Запази". С това задачата на гейм мастъра е изпълнена и
                не се изискват повече действия.
                <li>Потребителите получават имейли, че имат неоценена игра</li>
                Всички от играчите, чийто имейл е въведен от гейм мастъра, ще получи на имейла си,
                че има неоценена игра. За да види съответната игра в сайта, той трябва да последва
                линка от имейла и или да се регистрира със същия имейл, или да влезе в профила си,
                ако вече има такъв (Важно: профилите от стария сайт са изтрити напълно, но ако
                потребителите се регистрират със същите имейли, ще видят старите си игри!).
                <li>Потребителят довършва оценката</li>
                След като потребителят се логне в профила си, ще има notification, че има неоценена
                игра. За да я оцени, той трябва просто да напише ревю от тази игра и да избере дали
                препоръчва стаята или не. До този момент тази игра е видима само и единствено за
                него, но след като натисне "Изпрати", тя става видима за всички посетители на сайта.
              </ul>
              <Divider />
              Всеки от потребителите има възможност да качи сам своята игра при негово желание. За
              целта трябва да предостави валидна снимка от играта, да напише коментар и да даде
              дата. След като бъде изпратена, тази оценка трябва да бъде прегледана и одобрена от
              администратор, за да бъде напълно публикувана на сайта за останалите потребители.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header">
            <Typography className={classes.heading}>Как се създава оценка за стая?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Процесът е елементарен. Гейм мастърът трябва да влезе в администраторския панел и да
              мине с мишката през големия "+" в долния десен ъгъл на страницата и да избере "Създай
              игра" или да избере "Игри" в навигационното меню и след това да натисне "Създай игра".
              <br />
              <br />
              От там насетне, трябва задължително да качи снимка от играта на отбора, да избере коя
              стая трябва да бъде оценена, да избере дата на игра и, разбира се, имейлите на
              играчите.
              <br />
              <br />
              Ако иска да добави повече от един имейл, трябва да въведе един валиден, след което да
              натисне на "+" под полето за имейл.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header">
            <Typography className={classes.heading}>Как се създава ескейп стая/куест?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Препоръчително е първо да си създадете компания. За да го направите, трябва да влезете
              в "Моите компании" и да създадете такава, като качите логото на фирмата и напишете
              нейното име. Когато обвържете няколко стаи с една компания, в страницата на някоя
              ескейп стая, ще се виждат и останалите стаи на тази компания.
              <br />
              <br />
              За целта трябва да изберете "Ескейп стаи" от навигационното меню в администраторския
              панел. Натискате на бутона в горния десен ъгъл "Създай стая". Ще ви се появи модален
              прозорец, в който просто трябва да въведете нужните данни за стаята.
              <br />
              <br />
              Ако получите съобщение "Нещо се обърка и стаята не беше създадена", то значи някое от
              полетата не е правилно въведено. Имайте предвид, че задължително трябва да
              предоставите голяма част от информацията.
              <br /> Задължителните полета са: Cover и Thumbnail изображения, име на стаята, линк
              към стаята, улица, описание, жанр/ове, уебсайт, имейл и телефон.
              <br />
              <br />
              Ако някое от тези полета не е валидно, няма да можете да създадете стаята и сайтът ще
              ви каже кое поле трябва да оправите. (Има валидации за всяко от полетата - примерно
              "asd@asd" не се третира като валиден имейл адрес)
              <br />
              <br />
              След като сте попълнили нужните полета и натиснете на "Създай", стаята ще бъде
              изпратена към администратор, който да погледне информацията, която сте въвели и ако
              има някакъв проблем да я редактира, преди да я пусне като "Активна".
              <br />
              <br />
              <b>Важно!</b> Стаята ви ще бъде автоматично деактивирана, ако не сте платили вноската
              си за съответния месец. Когато натиснете на "Ескейп стаи", виждате още колко ще се
              показва стаята в сайта, преди да бъде деактивирана за потребителите. Дори и да е
              "Активна" (тоест потвърдена от администратор), датата на валидност ще попречи на
              стаята да се показва там.
              <br />
              <br />
              <i>
                Процесът по създаване на куест е същия, просто изберете "Създай куест", когато
                влезете в "Куестове" през навигационното меню
              </i>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header">
            <Typography className={classes.heading}>Поглед върху "Игри"</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Когато влезете на "Игри", ще видите всички игри, които се отнасят за вашите стаи.
              Можете да филтрирате игри по имейл, по непотвърдени и по недовършени.
              <br />
              <br />
              Можете да забележите, че в таблицата има две колони, които отразяват статуса на
              играта. Ако едно от двете полета е с червен "Х", то играта не се вижда на сайта. Ще ги
              разгледаме по-долу:
              <br />
              <b>Потвърдена</b>
              <br />
              Непотвърдени игри са тези игри, които са изпратени от потребител, а не са създадени от
              гейм мастър. Тези игри трябва да бъдат допълнително прегледани от администратор или от
              вас, собственика на стаята, за да прецените нейната достоверност и адекватност. Ако
              оценката е валидна, можете да я потвърдите, като натиснете на стрелкичката в
              най-дясната част на реда и да изберете "Добави в потвърдени". След като го направите,
              играта ще се покаже на сайта.
              <br />
              Важно! Само администратор може да премахва игри от потвърдени, но и администраторите и
              собственикът на стаята могат да добавят играта към потвърдени.
              <br />
              <br />
              <b>Написано ревю</b>
              <br />
              Ако потребителят е написал ревю за стаята, то това поле ще излезе със зелено тикче.
              Ако все още не е написал ревю, то той още не е довършил тази оценка. Повече за процеса
              за създаване и довършване на игра, можете да прочетете в първия таб.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

export default Dashboard
