import React from 'react'
import { Box, Button, Grid } from '@material-ui/core'

const Submit = ({ form, handleSubmit }) => {
  const isDisabled = form.file && form.roomId && form.review ? false : true

  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          disabled={isDisabled}
          onClick={handleSubmit}>
          Запази
        </Button>
      </Box>
    </Grid>
  )
}

export default Submit
