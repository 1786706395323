import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Chart } from 'react-chartjs-2'
import { Store } from 'core'
import { InitialScrollToTop, CookiesAlert } from 'components'

import { chartjs } from './helpers'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.css'
import Routes from './Routes'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-J48B0PS83F')

const browserHistory = createBrowserHistory()

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
})

const App = () => (
  <Store>
    <Router history={browserHistory}>
      <CookiesAlert />
      <InitialScrollToTop />
      <Routes />
    </Router>
  </Store>
)

export default App
