import React from 'react'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  container: {
    maxWidth: theme.sizing.main.contentWidth / 1.5,
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'column',
  },
}))

const Quests = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Общи условия | Всички стаи</title>
      </Helmet>
      <Grid container className={classes.container} spacing={5}>
        <Typography
          variant="h1"
          align="center"
          gutterBottom
          style={{ marginTop: 50, marginBottom: 40 }}>
          Общи условия
        </Typography>
        <Typography gutterBottom>Уважаеми потребители, </Typography>
        <Typography gutterBottom>
          Моля, запознайте се с тези общи условия внимателно, преди да използвате интернет сайта
          www.vsichkistai.bg (наричан по-долу за удобство Сайта). Със зареждането на тази интернет
          страница, Вие заявявате, че разбирате тези общи условия, съгласявате се да сте обвързани с
          тях, и се задължавате да ги спазвате стриктно. В случай че не сте съгласни с което и да е
          от посочените по-долу, моля, прекратете използването на Сайта. За каквито и да е спорове,
          възникнали във връзка със Сайта, и неуредени от настоящите общи условия, ще се прилага
          действащото българско законодателство.
        </Typography>
        <Typography variant="h3" gutterBottom>
          Общи условия за използване на сайта vsichkistai.bg
        </Typography>
        <Typography variant="h4" gutterBottom>
          1. Мисия и цели
        </Typography>
        <Typography gutterBottom>
          Мисията на www.VsichkiStai.bg e да бъде единна информационна платформа за real-life escape
          room преживявания в България. До момента сме качили информация относно всички ескейп стаи
          в България, за които знаем че съществуват, както и такива, които имат предстоящо
          откриване. Нашата визия е тази информация да е винаги актуална, да има функционалност за
          рейтинг на стаите, коментари на регистрирани потребители относно техните впечатления,
          класация на отборите с най-добри времена за всяка стая, както и единна класация. Целта ни
          е най-вече потребителите винаги да могат на едно място да намерят информация за стаите, но
          и да се промотира самият тип отборно забавление.
        </Typography>
        <Typography variant="h4" gutterBottom>
          2. Авторски права
        </Typography>
        <Typography gutterBottom>
          Авторските права и всички други права на интелектуална собственост в материали, съдържащи
          се на информационната платформа www.VsichkiStai.bg , заедно с техния дизайн, изображения,
          принадлежат на техните автори и са под закрилата на действащото в областта на
          интелектуалната собственост национално и международно законодателство. Oтговорността по
          тяхната валидност, пригодност и право на ползване е изцяло в патримониума на съответния
          автор. Всяко неоторизирано възпроизвеждане, разпространение или каквото и да е
          нерегламентирано използване на какъвто и да е елемент от Сайта представлява нарушение на
          Закона за авторското право и сродните му права, и ще се преследва и наказва по съответния
          ред.
          <br />
          Ако притежавате права върху публикувани на сайта материали и искате същите да бъдат
          свалени от него, моля свържете се с нас на vsichkistai@vsichkistai.bg .
        </Typography>

        <Typography variant="h4" gutterBottom>
          3. Съдържание{' '}
        </Typography>
        <Typography gutterBottom>
          Екипът на vsichkistai.bg си запазва правото да изтрива, премества и/или редактира всяка
          част от съдържанието, касаещо real-life ескейп стаите и/или рекламни банери на партньори,
          рекламодатели и др., разположени в информационната платформа www.vsichkistai.bg .
          Запазваме си и правото да изтриваме, редактираме и ограничаваме всяко съдържание, което
          преценим, че нарушава или може да наруши настоящите общи условия, не съответства на
          мисията на сайта и/или по друг начин е неприемливо и/или води до изводи за нелоялна
          търговска практика, нелоялна конкуренция, некоректни бизнес и/или други взаимоотношения.
        </Typography>
        <Typography variant="h4" gutterBottom>
          4. Защита на информацията и личните данни{' '}
        </Typography>
        <Typography gutterBottom>
          Екипът на сайта не е администратор на лични данни по смисъла на Закона за защита на
          личните данни, но се задължава да гарантира опазването на конфиденциалността относно
          всички предоставени данни от потребителите при регистрация, вкл. чрез Фейсбук, по имейл,
          или по друг начин. Потребителите на сайта се съгласяват чрез приемането на тези общи
          условия, че статистическата информация относно посещаемостта на сайта може да бъде
          предоставяна за използване с маркетингови цели на настоящи или бъдещи рекламодатели, както
          и може да бъде използвана за вътрешна статистика и анализи, или за целите на директния
          маркетинг. С приемането на настоящите Общи условия, потребителят се съгласява с
          възможността екипът на Vsichkistai.bg да му изпраща информационни съобщения относно
          наличието на нови стаи и/или информация за актуални промоции или др.
        </Typography>
        <Typography variant="h4" gutterBottom>
          5. Ограничения на отговорността{' '}
        </Typography>

        <Typography gutterBottom>
          Част от нашата мисия е да предоставим на вниманието на потребителите на Сайта навременна,
          точна и изчерпателна информация за всички real-life escape room преживявания в България.
          Информацията публикувана на сайта ни е с цел улесняване на потребителите и почитателите на
          escape game стаите. Всички текстове, снимки, ценова информация и други са взети от
          интернет-страницата на съответните escape стаи или поставени от самите тях. Екипът на
          Vsichkistai.bg не носи отговорност за актуалността, качеството на предлаганите услуги,
          както и некоректно предоставена информация от предоставящите. Екипът на Сайта не носи
          отговорност за изчерпателността и актуалността на информацията както на този сайт, така и
          във връзка с информация, съдържаща се в сайтове, към които Сайтът съдържа връзки и/или
          препраща – с рекламна, или каквато и да е друга цел. В случай на недобросъвестно атакуване
          на Сайта от страна на трети лица, настъпилите последици не ангажират отговорността на
          екипа на този сайт.
        </Typography>
        <Typography variant="h4" gutterBottom>
          6. Промени
        </Typography>
        <Typography gutterBottom>
          Екипът на този сайт си запазва правото при настъпване на каквито и да е изменения в
          обективните обстоятелства, които биха могли да имат отношение към Сайта, да внася
          съответни промени в така публикуваните общи условия. Екипът на сайта е длъжен да публикува
          в най-кратък срок новите общи условия, с отразените изменения в тях, и да оповести
          публикацията на видно място на Сайта, така че да даде възможност на настоящите и бъдещи
          потребители да се запознаят по максимално удобен начин с тях. Потребителите се считат
          уведомени за промените от момента на публикуването на съобщението за промени на видно
          място на Сайта. От този момент нататък презумпцията за знание и съгласие, описана подробно
          в преамбюла на настоящото, важи по отношение на новите/изменените общи условия.
        </Typography>
        <Typography>
          Приятно ползване! Надяваме се заедно да популяризираме и подобрим escape стаите в
          България.{' '}
        </Typography>
      </Grid>
    </div>
  )
}

export default Quests
