import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    paddingTop: 150,
    textAlign: 'center',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    height: 360,
  },
}))

const Unauthorized = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Helmet>
        <title>403 | Всички стаи</title>
      </Helmet>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <div className={classes.content}>
            <Typography variant="h1">Нямаш нужните права, за да достъпиш тази страница</Typography>
            <Typography variant="subtitle2">
              Надяваме се в ескейп стаите да си по-послушен и да не се опитваш да правиш шмекерии!
            </Typography>
            <img
              alt="Unauthorized"
              className={classes.image}
              src="/image/undraw_access_account_re_8spm.svg"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Unauthorized
