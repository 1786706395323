import React from 'react'
import { Alert } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { PageURLs } from 'Routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faThumbsUp as ThumbsUpIcon,
  faThumbsDown as ThumbsDownIcon,
} from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  link: { color: theme.palette.black },
}))

const UserRecommendation = ({ name, userId, recommends, room }) => {
  const classes = useStyles()

  return (
    <Alert
      className={classes.root}
      icon={
        recommends ? (
          <FontAwesomeIcon size="1x" icon={ThumbsUpIcon} />
        ) : (
          <FontAwesomeIcon size="1x" icon={ThumbsDownIcon} />
        )
      }
      severity={recommends ? 'success' : 'error'}>
      <Link className={classes.link} to={`${PageURLs.Profile}/${userId}`}>
        {name?.split(' ')[0]}
      </Link>{' '}
      {recommends ? 'препоръчва' : 'не препоръчва'} {room}
    </Alert>
  )
}

export default React.memo(UserRecommendation)
