import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    top:
      theme.sizing.homePage.escapeRoomNew.height -
      theme.sizing.homePage.escapeRoom.additionalInformationHeight,
    width: '100%',
    height: theme.sizing.homePage.escapeRoom.additionalInformationHeight,
  },
}))

const Heading = withStyles({
  root: {
    fontSize: 22,
    fontWeight: 700,
    color: 'white',
    textAlign: 'center',
  },
})(Typography)

const MainInformation = ({ className, title }) => {
  const classes = useStyles()

  return (
    <div className={clsx(className, classes.root)}>
      <Heading variant="h4" align="center">
        {title}
      </Heading>
    </div>
  )
}

export default MainInformation
