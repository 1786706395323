import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
} from '@material-ui/core'
import { UploadPhoto } from './components'
import { useAuth } from 'core'

const useStyles = makeStyles(() => ({
  root: {},
}))

const CreateCompany = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()
  const { addCompany } = useAuth()

  const [values, setValues] = useState({
    name: '',
    logo: '',
  })

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader title="Създай компания" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={2} xs={12} style={{ alignItems: 'center', display: 'flex' }}>
              <UploadPhoto setValues={setValues} values={values} />
            </Grid>
            <Grid item md={10} xs={12} style={{ alignItems: 'center', display: 'flex' }}>
              <TextField
                fullWidth
                label="Име на компанията"
                margin="dense"
                name="name"
                onChange={handleChange}
                required
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={values.name === '' && values.logo !== ''}
            onClick={() => addCompany(values)}>
            Изпрати
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

CreateCompany.propTypes = {
  className: PropTypes.string,
}

export default CreateCompany
