import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: theme.sizing.homePage.escapeRoom.height,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}))

const Image = ({ url }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: `url(${url})`,
      }}></div>
  )
}

export default Image
