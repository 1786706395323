import React, { useState } from 'react'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  TextField,
  DialogActions,
  Button,
  Switch,
  FormControl,
  FormLabel,
  Box,
} from '@material-ui/core'
import 'date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { useReviews } from 'core'
import { isEqual } from 'lodash'

const useStyles = makeStyles((theme) => ({
  icon: { margin: theme.spacing(1) },
  field: { marginBottom: theme.spacing(2) },
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const CustomDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton size="small" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

const EditReviewModal = ({ onClose, review, open, finished, buttonText, setReview }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { loadReviews } = useReviews()

  const [form, setForm] = useState(review)

  const url = review.trusted
    ? `/api/customer/review/finalize/${review._id}`
    : `/api/customer/review/edit/${review._id}`

  const handleSend = async () => {
    try {
      const { data } = await axios.put(url, form)
      enqueueSnackbar(data.message, { variant: 'success' })
      await loadReviews()
      setReview(data.review)
      handleClose()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      } else {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <CustomDialogTitle onClose={handleClose}>{buttonText} ревюто си</CustomDialogTitle>
      <DialogContent>
        {finished && (
          <DialogContentText>
            Ако редактираш ревюто си, то ще спре да се показва за останалите посетители на сайта,
            докато не бъде (потвърдено) от администратор или от собственика на стаята. Ако ревюто е
            валидно, то ще бъде публикувано до няколко часа.
          </DialogContentText>
        )}
        <div className={classes.field}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={!finished}
              style={{ width: '100%' }}
              autoOk
              size="small"
              margin="normal"
              inputVariant="outlined"
              variant="inline"
              label="Датата, на която игра"
              format="dd/MM/yyyy"
              value={form.date}
              onChange={(date) => setForm({ ...form, date })}
              InputAdornmentProps={{ position: 'start' }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <TextField
          size="small"
          className={classes.field}
          fullWidth
          variant="outlined"
          label="Ревю"
          multiline
          value={form.review}
          onChange={(e) => setForm({ ...form, review: e.target.value })}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Препоръчваш ли стаята?</FormLabel>
          <Box display="flex" alignItems="center" mt={1} mb={1}>
            <Typography variant="overline">Не препоръчвам</Typography>
            <Switch
              color="primary"
              checked={form.recommend}
              onChange={(e) => setForm({ ...form, recommend: e.target.checked })}
            />
            <Typography variant="overline">препоръчвам</Typography>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSend} disabled={isEqual(review, form)}>
          {buttonText}
        </Button>
        <Button onClick={handleClose}>Отмени</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(EditReviewModal)
