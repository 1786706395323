import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from 'core'
import { useSnackbar } from 'notistack'
import { messages } from 'utils'

const AuthenticatedRouteWithLayout = (props) => {
  const { group, layout: Layout, component: Component, ...rest } = props
  const { isAuthenticated } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isAuthenticated ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <>
            <Redirect to="/login" />
            {enqueueSnackbar(messages.common.notLoggedIn, { variant: 'warning' })}
          </>
        )
      }
    />
  )
}

AuthenticatedRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
}

export default AuthenticatedRouteWithLayout
