import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import NotificationsIcon from '@material-ui/icons/Notifications'
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Card,
  Badge,
  CardHeader,
  Avatar,
  makeStyles,
  Paper,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { useAuth } from 'core'
import { useSnackbar } from 'notistack'
import { messages } from 'utils'
import { PageURLs } from 'Routes'
import { Link, useHistory } from 'react-router-dom'
import { getInitials } from 'helpers'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.black,
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
  },
  badge: { cursor: 'pointer' },
  card: { maxWidth: 345 },
  avatar: { backgroundColor: theme.palette.primary.main },
  notifications: { padding: theme.spacing(2) },
}))

const UserInformation = ({ isDesktop }) => {
  const { user, logout, isAuthenticated } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const { notifications } = user
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()

  const [notificationsCount, setNotificationsCount] = useState(
    notifications.filter((notification) => notification.seen === false).length
  )
  const [anchorUserEl, setAnchorUserEl] = useState(null)
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null)

  const handleReadNotifications = async () => {
    try {
      await axios.get(`/api/customer/read-notifications`)
    } catch {
      console.error('Не можах да прочета нотификациите')
    }
  }

  const ITEM_HEIGHT = 48

  return (
    <>
      {isAuthenticated && (
        <Badge
          onClick={(event) => {
            setNotificationsCount(0)
            setAnchorNotificationsEl(event.currentTarget)
            notificationsCount > 0 && handleReadNotifications()
          }}
          className={classes.badge}
          badgeContent={notificationsCount}
          overlap="rectangular"
          max={20}
          color="primary">
          <NotificationsIcon color="action" />
        </Badge>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        onClick={(event) => setAnchorUserEl(event.currentTarget)}
        className={classes.root}>
        <Avatar src={user.avatar} size="small">
          {getInitials(user.name)}
        </Avatar>{' '}
        {isDesktop && (
          <>
            <Typography
              style={{ color: theme.palette.black, marginLeft: theme.spacing(1), fontSize: 13 }}
              variant="overline">
              {user.name.split(' ')[0]}
            </Typography>
            <ExpandMoreIcon />
          </>
        )}
      </Box>

      <Menu
        anchorEl={anchorUserEl}
        keepMounted
        open={Boolean(anchorUserEl)}
        onClose={() => setAnchorUserEl(null)}>
        <Card className={classes.card} elevation={0}>
          <CardHeader
            avatar={
              <Avatar className={classes.avatar} src={user.avatar}>
                {getInitials(user.name)}
              </Avatar>
            }
            title={user.name}
            subheader={user.email}
          />
        </Card>
        {(user.userGroup === 0 || user.userGroup === 1) && (
          <Box>
            <Divider style={{ marginBottom: theme.spacing(1) }} />
            <Link to={'/admin'}>
              <MenuItem
                style={{ marginBottom: theme.spacing(1) }}
                onClick={() => setAnchorUserEl(null)}>
                Администраторски панел
              </MenuItem>
            </Link>
          </Box>
        )}
        <Divider style={{ marginBottom: theme.spacing(1) }} />
        <Link to={`${PageURLs.Profile}/${user._id}`}>
          <MenuItem onClick={() => setAnchorUserEl(null)}>Профил</MenuItem>
        </Link>
        <Link to={`${PageURLs.Profile}/${user._id}/settings`}>
          <MenuItem onClick={() => setAnchorUserEl(null)}>Настройки</MenuItem>
        </Link>
        <Link to={`/change-password`}>
          <MenuItem onClick={() => setAnchorUserEl(null)}>Смени парола</MenuItem>
        </Link>
        <Divider style={{ margin: `${theme.spacing(1)}px 0` }} />
        <MenuItem
          onClick={() => {
            enqueueSnackbar(messages.common.logout, {
              variant: 'success',
            })
            history.push('/')
            logout()
          }}>
          Излез
        </MenuItem>
      </Menu>

      <Menu
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            maxWidth: 230,
          },
        }}
        anchorEl={anchorNotificationsEl}
        open={Boolean(anchorNotificationsEl)}
        onClose={() => setAnchorNotificationsEl(null)}>
        {notifications
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((notification) => {
            if (notification.link) {
              return (
                <div key={notification._id}>
                  <Link to={notification.link} onClick={() => setAnchorNotificationsEl(null)}>
                    <Paper elevation={0} square className={classes.notifications}>
                      <Badge
                        color="secondary"
                        variant="dot"
                        invisible={notification.seen}
                        overlap="rectangular">
                        <Typography>{notification.message}</Typography>
                      </Badge>
                      <Typography variant="overline">
                        {Intl.DateTimeFormat('bg-BG', {
                          dateStyle: 'short',
                          timeStyle: 'medium',
                        }).format(new Date(notification.date))}
                      </Typography>
                    </Paper>
                  </Link>
                  <Divider />
                </div>
              )
            }

            return (
              <div key={notification._id}>
                <MenuItem>
                  <Badge color="secondary" variant="dot" overlap="rectangular">
                    <Typography>{notification.message}</Typography>
                  </Badge>
                  <Typography variant="overline">
                    {Intl.DateTimeFormat('bg-BG', {
                      dateStyle: 'short',
                      timeStyle: 'medium',
                    }).format(new Date(notification.date))}
                  </Typography>
                </MenuItem>
                <Divider />
              </div>
            )
          })}
      </Menu>
    </>
  )
}

export default UserInformation
