import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  Button,
} from '@material-ui/core'
import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 110,
    width: 110,
    flexShrink: 0,
    flexGrow: 0,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginRight: theme.spacing(2),
  },
}))

const ExistingCompany = (props) => {
  const { company, className, ...rest } = props

  const classes = useStyles()
  const { removeCompany } = useAuth()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <Avatar className={classes.avatar} src={company.logo} />
          <div style={{ marginLeft: 8 }}>
            <Typography gutterBottom variant="h3">
              {company.name}
            </Typography>
          </div>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button variant="text" color="primary" onClick={() => removeCompany(company)}>
          Изтрий компанията
        </Button>
      </CardActions>
    </Card>
  )
}

ExistingCompany.propTypes = {
  className: PropTypes.string,
}

export default ExistingCompany
