import React from 'react'
import { Typography, Switch, Grid } from '@material-ui/core'

const SetRecommendation = ({ form, setForm }) => {
  const handleChange = () => {
    setForm({ ...form, recommend: !form.recommend })
  }

  return (
    <Grid item xs={12}>
      <Typography>
        <Switch
          checked={form.recommend}
          color="primary"
          onChange={handleChange}
        />
        Препоръчваш ли стаята?
      </Typography>
    </Grid>
  )
}

export default React.memo(SetRecommendation)
