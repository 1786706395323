import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import Complexity from './Complexity'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import TimerIcon from '@material-ui/icons/Timer'
import { Box, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    top:
      theme.sizing.homePage.escapeRoom.height -
      2 * theme.sizing.homePage.escapeRoom.additionalInformationHeight +
      20,
    width: '100%',
    height: theme.sizing.homePage.escapeRoom.additionalInformationHeight,
  },
}))

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const Heading = withStyles((theme) => ({
  root: {
    color: theme.palette.white,
    marginLeft: theme.spacing(0.5),
    fontSize: 12,
  },
}))(Typography)

const AdditionalInformation = ({ className, complexity, players, time }) => {
  const classes = useStyles()

  const showPlayers = (players) => {
    return players.join(' - ')
  }

  return (
    <div className={clsx(className, classes.root)}>
      <LightTooltip title="Трудност" placement="top">
        <Box display="flex" alignItems="center">
          <Heading>
            <Complexity complexity={complexity} />
          </Heading>
        </Box>
      </LightTooltip>
      <LightTooltip title="Брой играчи" placement="top">
        <Box display="flex" alignItems="center">
          <PeopleAltIcon color="primary" />
          <Heading variant="overline">{showPlayers(players)}</Heading>
        </Box>
      </LightTooltip>
      <LightTooltip title="Продължителност на играта" placement="top">
        <Box display="flex" alignItems="center">
          <TimerIcon color="primary" />
          <Heading variant="overline">{time} min</Heading>
        </Box>
      </LightTooltip>
    </div>
  )
}

export default AdditionalInformation
