import React, { useState, useEffect, useRef } from 'react'
import { useTheme } from '@material-ui/styles'
import {
  CardMedia,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  IconButton,
  Card,
  Grid,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { UserRecommendation } from './components'
import { reviewStyles } from 'helpers/functions'

const Review = (props) => {
  const { user = null, image, date, room, score, review, team } = props
  const ref = useRef(null)
  const theme = useTheme()
  const classes = reviewStyles()
  const collapsedHeight = 65

  const [open, setOpen] = useState(false)
  const [openReview, setOpenReview] = useState(false)
  const [height, setHeight] = useState(0)

  const handleOpenImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCollapse = () => {
    setOpenReview((prev) => !prev)
  }

  useEffect(() => {
    setHeight(ref.current.scrollHeight)
  }, [])

  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} style={{ paddingTop: 0 }}>
        <Card elevation={2}>
          <CardMedia
            className={classes.media}
            title={date}
            image={image ? image : '/image/missing_image.png'}
            onClick={handleOpenImage}
          />
          <UserRecommendation userId={user} team={team} room={room} score={score} />
          <CardContent className={classes.content}>
            <Collapse ref={ref} in={openReview} collapsedHeight={collapsedHeight}>
              <Typography align="justify" className={classes.review} onClick={handleCollapse}>
                {review}
              </Typography>
            </Collapse>
            {height > collapsedHeight ? (
              <div
                className={classes.fullReviewButton}
                style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title={openReview ? 'Скрий ревюто' : 'Прочети ревюто'}>
                  <IconButton onClick={handleCollapse} color="primary" size="small">
                    {openReview ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      </Grid>

      <Dialog maxWidth={false} open={open} onClose={handleClose}>
        <DialogActions className={classes.actions}>
          <IconButton style={{ color: theme.palette.white }} size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <img
          alt={date}
          src={image ? image : '/image/missing_image.png'}
          style={{ maxWidth: '100%' }}
        />
      </Dialog>
    </>
  )
}

export default React.memo(Review)
