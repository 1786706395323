import React from 'react'
import { EscapeRoom } from './components'
import { makeStyles } from '@material-ui/styles'
import { BigHeading } from 'layouts/Main/components'
import { useEscapeRooms } from 'core'
import { Grid } from '@material-ui/core'
import random from 'random'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    maxWidth: theme.sizing.main.contentWidth,
    width: '100%',
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}))

const EscapeRooms = () => {
  const classes = useStyles()
  const { chosenRooms, randomChosenRooms } = useEscapeRooms()
  const maxChosenRooms = 4

  return (
    <div className={classes.root}>
      <BigHeading>Препоръчани ескейп стаи</BigHeading>

      <Grid container className={classes.container} spacing={4}>
        {chosenRooms
          .map((escaperoom) => {
            return <EscapeRoom key={escaperoom._id} escaperoom={escaperoom} />
          })
          .sort(() => 0.5 - random.float())}
        {randomChosenRooms
          .sort(() => 0.5 - random.float())
          .filter(
            (escaperoom, index, self) =>
              index === self.findIndex((t) => t.company?.name === escaperoom.company?.name)
          )
          .sort(() => 0.5 - random.float())
          .sort(() => 0.5 - random.float())
          .map((escaperoom) => {
            return <EscapeRoom key={escaperoom._id} escaperoom={escaperoom} />
          })
          .slice(0, maxChosenRooms - chosenRooms.length)}
      </Grid>
    </div>
  )
}

export default React.memo(EscapeRooms)
