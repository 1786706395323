export default (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'ADS_LOADED':
      return {
        loadingAds: false,
        errorAds: false,
        errorMessageAds: null,
        ads: payload,
      }
    case 'ADS_FAIL':
      return {
        ...state,
        loadingAds: false,
        errorAds: true,
        errorMessageAds: payload,
      }
    default:
      return state
  }
}
