import React, { useEffect, useState } from 'react'
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Collapse,
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import { Helmet } from 'react-helmet'
import { useTheme } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'
import { useAuth, useEscapeRooms } from 'core'
import clsx from 'clsx'
import { Share } from 'layouts/Main/components'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { Link, useParams } from 'react-router-dom'
import MuiLink from '@material-ui/core/Link'
import { CommentSection, EditReviewModal } from 'components'
import { USER_GROUP } from 'helpers'
import { PageURLs } from 'Routes'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  media: {
    height: 0,
    cursor: 'pointer',
    paddingTop: '75%',
    maxHeight: 300,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: `${theme.spacing(1)}px !important`,

    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  mobileContentBox: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  date: {
    position: 'absolute',
    bottom: 16,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: theme.palette.white,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    textAlign: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  commentsWrapper: {
    margin: theme.spacing(1),
    marginTop: 0,
  },
  comments: {
    maxHeight: 500,
    overflowY: 'scroll',
    padding: theme.spacing(2),
    background: theme.palette.background.default,
  },
}))

const Review = ({ fullReview, isOwn = false, expandedComments = false, isOnOwnPage = false }) => {
  const classes = useStyles()
  const { profileId } = useParams()
  const { escaperooms } = useEscapeRooms()
  const { isAuthenticated, user } = useAuth()
  const theme = useTheme()
  const [review, setReview] = useState(fullReview)
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [expanded, setExpanded] = useState(expandedComments)
  const [allComments, setAllComments] = useState(review?.comments)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    review ? setIsLoading(false) : setIsLoading(true)
  }, [review])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleOpenShareDialog = () => {
    setOpenShareDialog(true)
  }

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false)
  }

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true)
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
  }

  const handleOpenImage = () => {
    setOpenImage(true)
  }

  const handleCloseImage = () => {
    setOpenImage(false)
  }

  return !isLoading ? (
    <>
      {isOnOwnPage && (
        <Helmet>
          <title>
            Игра в {escaperooms.find((escaperoom) => escaperoom._id === fullReview.roomId)?.title}
          </title>
          <meta
            property="og:title"
            content={`Игра в ${
              escaperooms.find((escaperoom) => escaperoom._id === fullReview.roomId)?.title
            }`}
          />
          <meta property="og:description" content={fullReview?.review} />
          <meta property="og:image" content={`https://vsichkistai.bg${fullReview?.file}`} />
          <meta
            property="og:url"
            content={`https://vsichkistai.bg${PageURLs.Profile}/${profileId}/review/${fullReview?._id}`}
          />
        </Helmet>
      )}
      <Card className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={2} style={{ position: 'relative' }}>
            <CardMedia
              className={classes.media}
              image={review.file ? review.file : '/image/missing_image.png'}
              title="Review"
              onClick={handleOpenImage}
            />
            <Link to={`${PageURLs.Profile}/${profileId}/review/${review._id}`}>
              <Typography className={classes.date}>
                {Intl.DateTimeFormat('bg-BG').format(new Date(review.date))}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={10}>
            <CardContent className={classes.content}>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                className={classes.mobileContentBox}>
                <Typography variant="h5" component="h2">
                  <MuiLink
                    component={Link}
                    to={`/escape-rooms/${
                      escaperooms.find((escaperoom) => escaperoom._id === review.roomId)?.shortname
                    }`}>
                    {escaperooms.find((escaperoom) => escaperoom._id === review.roomId)?.title}
                  </MuiLink>
                </Typography>
                {review.finished && (
                  <Alert
                    style={{ marginLeft: 16, marginRight: 16, paddingTop: 0, paddingBottom: 0 }}
                    icon={false}
                    severity={review.recommend ? 'success' : 'error'}>
                    {isOwn
                      ? `Ти ${review.recommend ? '' : 'не'} препоръчваш тази стая`
                      : `Потребителят ${review.recommend ? '' : 'не'} препоръчва тази стая`}
                  </Alert>
                )}
                {isAuthenticated && user.userGroup === USER_GROUP.admin.id && !isOwn && (
                  <>
                    <Typography
                      variant="overline"
                      style={
                        review.trusted
                          ? { marginLeft: 16, marginRight: 8, color: theme.palette.success.main }
                          : { marginLeft: 16, marginRight: 8, color: theme.palette.error.main }
                      }>
                      {review.trusted ? 'Потвърдено' : 'Непотвърдено'}
                    </Typography>
                    <Typography
                      variant="overline"
                      style={
                        review.finished
                          ? { marginRight: 8, color: theme.palette.success.main }
                          : { marginRight: 8, color: theme.palette.error.main }
                      }>
                      {review.finished ? 'Написано ревю' : 'Няма написано ревю'}
                    </Typography>
                  </>
                )}
                {review.finished && !review.trusted && isOwn && (
                  <Typography variant="overline">
                    Ревюто е видимо само за теб, докато не се одобри от администратор
                  </Typography>
                )}

                {review.trusted && !review.finished && isOwn && (
                  <Typography style={{ marginLeft: 16 }} variant="overline">
                    Ревюто е видимо само за теб, докато не го напишеш
                  </Typography>
                )}
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                style={{ marginBottom: 16, marginTop: 16, fontSize: 13 }}>
                {review.trusted && review.finished ? (
                  review.review
                ) : !review.finished ? (
                  <Button
                    size="small"
                    onClick={handleOpenEditDialog}
                    variant="contained"
                    startIcon={<EditIcon />}>
                    Напиши ревю
                  </Button>
                ) : (
                  review.review
                )}
              </Typography>
              <div>
                {!review.finished || !review.trusted ? (
                  <Tooltip
                    title="Не можеш да споделиш ревю, което не е публикувано"
                    placement="right">
                    <span>
                      <Button
                        style={{ marginRight: 16 }}
                        size="small"
                        onClick={handleOpenShareDialog}
                        startIcon={<ShareIcon />}
                        disabled>
                        Сподели
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    size="small"
                    style={{ marginRight: 16 }}
                    onClick={handleOpenShareDialog}
                    startIcon={<ShareIcon />}
                    disabled={!review.finished}>
                    Сподели
                  </Button>
                )}
                <Button
                  style={{ marginRight: 16 }}
                  size="small"
                  disabled={!review.finished}
                  onClick={handleExpandClick}
                  startIcon={<CommentOutlinedIcon />}>
                  Коментари (
                  {allComments?.length === 1
                    ? `${allComments?.length} коментар`
                    : `${allComments?.length} коментара`}
                  )
                  <ExpandMoreIcon
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                  />
                </Button>
                {(isOwn || (isAuthenticated && user.userGroup === USER_GROUP.admin.id)) &&
                  ((!review.trusted && review.finished) || (review.trusted && review.finished)) && (
                    <Button size="small" onClick={handleOpenEditDialog} startIcon={<EditIcon />}>
                      Редактирай
                    </Button>
                  )}
                <Share
                  title={`Ескейп игра в ${
                    escaperooms.find((escaperoom) => escaperoom._id === review.roomId)?.title
                  }`}
                  shareUrl={`${PageURLs.Profile}/${profileId}/review/${review._id}`}
                  open={openShareDialog}
                  onClose={handleCloseShareDialog}
                />
                <EditReviewModal
                  review={{
                    trusted: review.trusted || false,
                    _id: review._id,
                    review: review.review,
                    date: review.date,
                    recommend: review.recommend,
                  }}
                  finished={review.finished}
                  open={openEditDialog}
                  onClose={handleCloseEditDialog}
                  buttonText={review.finished ? 'Редактирай' : 'Потвърди'}
                  setReview={setReview}
                />
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </Card>

      <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.commentsWrapper}>
        <CommentSection
          allComments={allComments}
          setAllComments={setAllComments}
          postCommentUrl={`/api/customer/review/comment/${review._id}`}
          deleteCommentUrl={`/api/customer/review/comment/${review._id}/delete/`}
          editCommentUrl={`/api/customer/review/comment/${review._id}/edit/`}
        />
      </Collapse>

      <Dialog maxWidth={false} open={openImage} onClose={handleCloseImage}>
        <DialogActions className={classes.actions}>
          <IconButton style={{ color: '#fff' }} size="small" onClick={handleCloseImage}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <img alt="Game" src={review.file} style={{ maxHeight: 'calc(100vh - 64px)' }} />
      </Dialog>
    </>
  ) : (
    <CircularProgress />
  )
}

export default React.memo(Review)
