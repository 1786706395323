import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'

const useFetchData = (url) => {
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false)
      setIsLoading(true)

      try {
        const { data } = await axios(url)
        setData(data)
      } catch (error) {
        setIsError(true)
        enqueueSnackbar(
          errorMessage || error.response.data.message || error.response.data
        )
      }

      setIsLoading(false)
    }

    fetchData()
    // eslint-disable-next-line
  }, [url, errorMessage])

  return [ data, isLoading, isError , setErrorMessage]
}

export default useFetchData
