import React, { useState, useEffect } from 'react'
import { makeStyles, Typography, Box, Divider } from '@material-ui/core'
import { useAuth, useReviews } from 'core'
import { LegacyReview, Review } from 'components'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { USER_GROUP } from 'helpers'

const useStyles = makeStyles((theme) => ({
  typography: {
    fontSize: 14,
  },
  field: {
    marginTop: theme.spacing(2),
  },
  game: {
    margin: theme.spacing(1),
  },
}))

const Reviews = ({ isOwnProfile, profileId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const {
    approvedAndFinishedReviews,
    legacyReviews,
    waitingForApprovalReviews,
    approvedAndUnfinishedReviews,
  } = useReviews()
  const { isAuthenticated, user } = useAuth()

  const [reviews, setReviews] = useState([])
  const [legacyReviewsState, setLegacyReviews] = useState([])

  const initialUrl = `/api/reviews/user/${profileId}`
  const url = isAuthenticated
    ? user.userGroup === USER_GROUP.admin.id
      ? `/api/admin/reviews/byUserId/${profileId}`
      : initialUrl
    : initialUrl

  useEffect(() => {
    const getReviews = async () => {
      try {
        const { data } = await axios(url)
        setReviews(data.reviews)
        setLegacyReviews(data.legacyReviews)
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }
    }

    !isOwnProfile && getReviews()

    // eslint-disable-next-line
  }, [profileId, isOwnProfile])

  return (
    <div className={classes.root}>
      {isOwnProfile ? (
        <>
          {approvedAndUnfinishedReviews.length ? (
            <Box display="flex" flexDirection="column" className={classes.field}>
              <Typography
                className={classes.typography}
                color="primary"
                align="left"
                component="div"
                gutterBottom
                variant="overline">
                Чакащи твоето оценяване
              </Typography>
              <Typography gutterBottom align="left" component="div" variant="overline">
                За да бъдат публикувани е нужно само да напишеш ревю
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column">
                {approvedAndUnfinishedReviews.map((review) => (
                  <Review key={review._id} fullReview={review} isOwn />
                ))}
              </Box>
            </Box>
          ) : (
            ''
          )}
          {waitingForApprovalReviews.length ? (
            <Box display="flex" flexDirection="column" className={classes.field}>
              <Typography
                className={classes.typography}
                color="primary"
                align="left"
                component="div"
                gutterBottom
                variant="overline">
                Чакащи потвърждение от администратор
              </Typography>
              <Typography gutterBottom align="left" component="div" variant="overline">
                За да бъдат потвърдени е нужно да имат валидно ревю
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column">
                {waitingForApprovalReviews.map((review) => (
                  <Review key={review._id} fullReview={review} isOwn />
                ))}
              </Box>
            </Box>
          ) : (
            ''
          )}
          {approvedAndFinishedReviews.length ? (
            <Box display="flex" flexDirection="column" className={classes.field}>
              <Typography
                className={classes.typography}
                color="primary"
                align="left"
                component="div"
                gutterBottom
                variant="overline">
                Публикувани ревюта
              </Typography>
              <Typography gutterBottom align="left" component="div" variant="overline">
                Всички ревюта, които са достъпни за посетителите на сайта
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column">
                {approvedAndFinishedReviews.map((review) => (
                  <Review key={review._id} fullReview={review} isOwn />
                ))}
              </Box>
            </Box>
          ) : (
            ''
          )}
          {legacyReviews.length ? (
            <Box display="flex" flexDirection="column" className={classes.field}>
              <Typography
                className={classes.typography}
                color="primary"
                align="left"
                component="div"
                gutterBottom
                variant="overline">
                Ревюта от стария сайт
              </Typography>
              <Typography gutterBottom align="left" component="div" variant="overline">
                Всички ревюта, които си попълнил в стария сайт (игрите с избран отбор)
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column">
                {legacyReviews.map((review) => (
                  <LegacyReview key={review._id} fullReview={review} isOwn />
                ))}
              </Box>
            </Box>
          ) : (
            ''
          )}
        </>
      ) : (
        <>
          <Typography
            className={classes.typography}
            color="primary"
            align="left"
            component="div"
            gutterBottom
            variant="overline">
            Публикувани ревюта
          </Typography>
          <Typography gutterBottom align="left" component="div" variant="overline">
            Всички ревюта, които са достъпни за посетителите на сайта
          </Typography>
          <Divider />
          <Box display="flex" flexDirection="column" mt={2}>
            {reviews.map((review) => (
              <Review key={review._id} fullReview={review} />
            ))}
          </Box>
          <Typography
            className={classes.typography}
            color="primary"
            align="left"
            component="div"
            gutterBottom
            variant="overline">
            Ревюта от стария сайт
          </Typography>
          <Typography gutterBottom align="left" component="div" variant="overline">
            Всички ревюта, които потребителят е попълнил в стария сайт
          </Typography>
          <Divider />
          <Box display="flex" flexDirection="column" mt={2}>
            {legacyReviewsState.map((review) => (
              <LegacyReview key={review._id} fullReview={review} />
            ))}
          </Box>
        </>
      )}
    </div>
  )
}

export default React.memo(Reviews)
