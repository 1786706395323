import React from 'react'
import { Grid, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { filters } from 'utils'
import { intersection } from 'lodash'
import { useHistory } from 'react-router'
import { PageURLs } from 'Routes'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(0.5),
  },
}))

const Categories = ({ items }) => {
  const classes = useStyles()
  const history = useHistory()

  const rawFiltersForSelectedCity = intersection(
    filters.map((filter) => filter.name),
    items
  )

  const filtersForSelectedCity = filters.filter((filter) =>
    rawFiltersForSelectedCity.includes(filter.name)
  )

  return (
    <Grid xs={12} item className={classes.root}>
      {filtersForSelectedCity.map((category) => {
        return (
          <Chip
            key={category.value}
            className={classes.chip}
            variant="outlined"
            size="small"
            onClick={() =>
              history.push({ pathname: PageURLs.EscapeRooms, state: { genre: category.name } })
            }
            label={category.value}
          />
        )
      })}
    </Grid>
  )
}

export default Categories
