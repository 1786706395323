import React from 'react'
import { useTheme } from '@material-ui/styles'

import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Link,
  Typography,
  Divider,
  Box,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF as FacebookIcon,
  faTwitter as TwitterIcon,
  faInstagram as InstagramIcon,
  faFacebookMessenger as MessengerIcon,
} from '@fortawesome/free-brands-svg-icons'
import {
  faPhone as PhoneIcon,
  faMapMarkerAlt as MapMarkerIcon,
  faEnvelope as EnvelopeIcon,
  faGlobeEurope as GlobeIcon,
} from '@fortawesome/free-solid-svg-icons'
import { Categories } from './components'
import { useReviews } from 'core'

const AdditionalInformation = (props) => {
  const {
    phone,
    facebook,
    site,
    email,
    messenger,
    tripadvisor,
    instagram,
    twitter,
    city,
    address,
    ageRestriction,
    alerts,
    genres,
    escaperoomId,
  } = props
  const theme = useTheme()
  const { allReviewedRooms } = useReviews()

  return (
    <Grid xs={12} sm={4} item>
      <List>
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon color={theme.palette.primary.main} size="lg" icon={GlobeIcon} />
          </ListItemIcon>

          <Link target="_blank" href={site} style={{ fontSize: 14 }}>
            Уебсайт на стаята
          </Link>
        </ListItem>
        <Divider
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            background: theme.palette.mainLayout.divider,
          }}
        />
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon color={theme.palette.primary.main} size="lg" icon={PhoneIcon} />
          </ListItemIcon>

          <Link href={`tel:${phone}`}>
            <Typography>{phone}</Typography>
          </Link>
        </ListItem>
        <Divider
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            background: theme.palette.mainLayout.divider,
          }}
        />
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon color={theme.palette.primary.main} size="lg" icon={EnvelopeIcon} />
          </ListItemIcon>

          <Link href={`mailto:${email}`}>
            <Typography>{email}</Typography>
          </Link>
        </ListItem>
        <Divider
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            background: theme.palette.mainLayout.divider,
          }}
        />
        {facebook !== '' && (
          <>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon color={theme.palette.primary.main} size="lg" icon={FacebookIcon} />
              </ListItemIcon>
              <Link target="_blank" href={facebook}>
                <Typography>Facebook страница</Typography>
              </Link>
            </ListItem>
            <Divider
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
                background: theme.palette.mainLayout.divider,
              }}
            />
          </>
        )}
        {messenger !== '' && (
          <>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  color={theme.palette.primary.main}
                  size="lg"
                  icon={MessengerIcon}
                />
              </ListItemIcon>
              <Link target="_blank" href={messenger}>
                <Typography>Свържи се чрез Messenger</Typography>
              </Link>
            </ListItem>
            <Divider
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
                background: theme.palette.mainLayout.divider,
              }}
            />
          </>
        )}
        {instagram !== '' && (
          <>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon
                  color={theme.palette.primary.main}
                  size="lg"
                  icon={InstagramIcon}
                />
              </ListItemIcon>
              <Link target="_blank" href={instagram}>
                <Typography>Instagram профил</Typography>
              </Link>
            </ListItem>
            <Divider
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
                background: theme.palette.mainLayout.divider,
              }}
            />
          </>
        )}
        {tripadvisor !== '' && (
          <>
            <ListItem>
              <ListItemIcon>
                <img
                  src="/image/tripadvisor.svg"
                  style={{ width: 21, height: 14 }}
                  alt="tripadvisor"
                />
              </ListItemIcon>
              <Link target="_blank" href={tripadvisor}>
                <Typography>Оцени и в Tripadvisor</Typography>
              </Link>
            </ListItem>
            <Divider
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
                background: theme.palette.mainLayout.divider,
              }}
            />
          </>
        )}
        {twitter !== '' && (
          <>
            <ListItem>
              <ListItemIcon>
                <FontAwesomeIcon color={theme.palette.primary.main} size="lg" icon={TwitterIcon} />
              </ListItemIcon>
              <Link target="_blank" href={twitter}>
                <Typography>Twitter страница</Typography>
              </Link>
            </ListItem>
            <Divider
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
                background: theme.palette.mainLayout.divider,
              }}
            />
          </>
        )}

        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon color={theme.palette.primary.main} size="lg" icon={MapMarkerIcon} />
          </ListItemIcon>

          <Typography>
            {city}, {address}
          </Typography>
        </ListItem>
        <Divider
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            background: theme.palette.mainLayout.divider,
          }}
        />
      </List>
      {allReviewedRooms.includes(escaperoomId) && (
        <Box mb={1}>
          <Alert severity="warning">Вече си играл тази стая</Alert>
        </Box>
      )}
      {ageRestriction > 14 && (
        <Box mb={1}>
          <Alert severity="error">Стаята е с възрастово ограничение {ageRestriction}+</Alert>
        </Box>
      )}
      {alerts.map((alert) => (
        <Box key={alert.message} mb={1}>
          <Alert severity={alert.severity}>{alert.message}</Alert>
        </Box>
      ))}
      <Categories items={genres} />
    </Grid>
  )
}

export default AdditionalInformation
