import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button } from '@material-ui/core'
import { useAuth } from 'core'
import { USER_GROUP } from 'helpers'
import { CreateModal } from '../Row/components'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}))

const Toolbar = (props) => {
  const { setPage, filter, setFilter, ...rest } = props
  const { user } = useAuth()

  const classes = useStyles()
  const [openCreateModal, setOpenCreateModal] = useState(false)

  return (
    <div {...rest} className={classes.root}>
      <div className={classes.row}>
        <Typography variant="h3">
          {user.userGroup === USER_GROUP.admin.id && 'Ескейп стаи'}
          {user.userGroup === USER_GROUP.owner.id && 'Моите ескейп стаи'}
        </Typography>
        <span className={classes.spacer} />
        <Button variant="contained" color="primary" onClick={() => setOpenCreateModal(true)}>
          Създай стая
        </Button>
        <CreateModal open={openCreateModal} setOpen={setOpenCreateModal} />
      </div>
      <div className={classes.row}></div>
    </div>
  )
}

export default Toolbar
