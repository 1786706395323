import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMediaQuery } from '@material-ui/core'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { Link } from 'react-router-dom'

import { Sidebar, Topbar, Footer } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: 240,
  },
  content: {
    height: '100%',
  },
  speedDial: {
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    '& svg': {
      display: 'block',
    },
  },
}))

const Admin = (props) => {
  const { children } = props
  const withLink = (to, children) => <Link to={to}>{children}</Link>
  const actions = [
    {
      icon: withLink('/admin/create-review', <SportsEsportsIcon color="primary" />),
      name: 'Създай игра',
    },
    {
      icon: withLink('/admin/create-news', <ReceiptIcon color="primary" />),
      name: 'Създай новина',
    },
  ]

  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const [openSpeedDial, setOpenSpeedDial] = useState(false)
  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const handleSpeedDialClose = () => {
    setOpenSpeedDial(false)
  }

  const handleSpeedDialOpen = () => {
    setOpenSpeedDial(true)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
      <SpeedDial
        ariaLabel={'speedDial'}
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleSpeedDialClose}
        onOpen={handleSpeedDialOpen}
        open={openSpeedDial}
        direction={'up'}>
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={handleSpeedDialClose}
          />
        ))}
      </SpeedDial>
    </div>
  )
}

Admin.propTypes = {
  children: PropTypes.node,
}

export default Admin
