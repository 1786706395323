import React, { useState, useRef } from 'react'
import { Box, Button, Grid, CircularProgress, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { ExistingPhoto } from '..'
import axios from 'axios'
import { resizeImage } from 'helpers'

const UploadPhoto = ({ form, setForm }) => {
  const photo = useRef(null)
  const { enqueueSnackbar } = useSnackbar()
  const [uploading, setUploading] = useState(false)

  const handleUploadFile = async (e) => {
    const originalFile = e.target.files[0]
    const resizedFile = await resizeImage(originalFile, 1920, 600, 100)
    const formData = new FormData()
    formData.append('image', resizedFile)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await axios.post('/api/owner/upload/image', formData, config)

      setForm({ ...form, image: data })
      setUploading(false)
    } catch (error) {
      enqueueSnackbar(error.response.data, { variant: 'error' })
      setUploading(false)
    }
  }

  return (
    <Grid item xs={12}>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        style={{ position: 'relative', paddingTop: 48, border: '1px dashed gray' }}>
        {form.image !== '' && <ExistingPhoto rectangle image={form.image} />}
        <Typography>Изображението трябва да е в размер: 1920х500</Typography>
        <Button
          component="label"
          variant="outlined"
          disabled={uploading}
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            bottom: 32,
            left: '50%',
            transform: 'translateX(-50%)',
          }}>
          {uploading && <CircularProgress />} Качи cover снимка
          <input
            ref={photo}
            accept="image/*"
            type="file"
            onChange={handleUploadFile}
            style={{ display: 'none' }}
          />
        </Button>
      </Box>
    </Grid>
  )
}

export default React.memo(UploadPhoto)
