import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper } from '@material-ui/core'
import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  contentBody: {
    flexGrow: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    padding: theme.spacing(4),
    flexBasis: 700,
    margin: theme.spacing(1),
  },
}))

const Verify = () => {
  const classes = useStyles()
  const history = useHistory()
  const { userId, registrationHash } = useParams()
  const { verify, user } = useAuth()

  useEffect(() => {
    userId !== undefined && registrationHash !== undefined && verify(userId, registrationHash)
    // eslint-disable-next-line
  }, [userId, registrationHash])

  useEffect(() => {
    user !== null && history.push('/')
    // eslint-disable-next-line
  }, [user])

  return (
    <div className={classes.root}>
      <div className={classes.contentBody}>
        <Paper className={classes.form}>
          <Typography variant="h2">Потвърждаване на профилa...</Typography>
        </Paper>
      </div>
    </div>
  )
}

export default Verify
