import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import { ComponentTitle } from '../../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}))

const Map = (props) => {
  const { city, address } = props
  const classes = useStyles()

  return (
    <Grid xs={12} item className={classes.root}>
      <ComponentTitle>Карта</ComponentTitle>
      <iframe
        title="Escape Room Map"
        height="280"
        loading="lazy"
        style={{
          width: '100%',
        }}
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmOzhjZJMKoedSf0dAsjyFTkuximT6aSo
    &q=${city},${address}`}
      />
    </Grid>
  )
}

export default Map
