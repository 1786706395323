import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: theme.sizing.escapeRoom.imageHeight,
    filter: 'brightness(120%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topShadow: {
    background: 'rgb(0,0,0)', // Duplication for older browsers
    // eslint-disable-next-line
    background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%) ',
    height: 80,
  },
  bottomShadow: {
    background: 'rgb(0,0,0)', // Duplication for older browsers
    // eslint-disable-next-line
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%) ',
    height: 120,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  container: {
    maxWidth: theme.sizing.main.contentWidth - 2 * theme.sizing.main.spaceBetweenContents,
    width: '100%',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

const Image = ({ url, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root} style={{ backgroundImage: `url(${url})` }}>
      <div className={classes.topShadow}></div>
      <div className={classes.bottomShadow}>
        <div className={classes.container}>{children}</div>
      </div>
    </div>
  )
}

export default Image
