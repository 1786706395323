import React from 'react'
import {
  makeStyles,
  CardMedia,
  Card,
  Grid,
  CardContent,
  Typography,
  Avatar,
  Box,
  Link,
} from '@material-ui/core'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import TimeToLeaveIcon from '@material-ui/icons/TimeToLeave'
import TimerIcon from '@material-ui/icons/Timer'
import PublicIcon from '@material-ui/icons/Public'

const useStyles = makeStyles((theme) => ({
  media: {
    height: 300,
  },
  piece: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
  },
}))

const Quest = ({ quest }) => {
  const classes = useStyles()

  return (
    <Card id={quest.shortname}>
      <CardMedia className={classes.media} image={quest.image} title="Quest image" />
      <CardContent>
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{quest.title}</Typography>
          <Typography
            style={{ display: 'flex', alignItems: 'center' }}
            variant="overline"
            color="primary">
            <Avatar style={{ marginLeft: 4, marginRight: 4 }} src={quest.company.logo} />
            {quest.company.name}
          </Typography>
        </Box>
        <Box mt={3} mb={3} dangerouslySetInnerHTML={{ __html: quest.description }} />
        <Grid container justifyContent="space-around" alignItems="center" flexWrap="wrap">
          <Grid item xs={6} xl={2} className={classes.piece}>
            <PeopleAltIcon color="primary" fontSize="large" />
            <Typography variant="overline" color="primary">
              Играчи
            </Typography>
            <Typography variant="h6">{quest.people.join(' - ')}</Typography>
          </Grid>
          <Grid item xs={6} xl={2} className={classes.piece}>
            <LocationCityIcon color="primary" fontSize="large" />
            <Typography variant="overline" color="primary">
              Къде?
            </Typography>
            <Typography variant="h6">{quest.city}</Typography>
          </Grid>
          <Grid item xs={6} xl={2} className={classes.piece}>
            <AttachMoneyIcon color="primary" fontSize="large" />
            <Typography variant="overline" color="primary">
              Цена
            </Typography>
            <Typography variant="h6">{quest.price} лв.</Typography>
          </Grid>
          <Grid item xs={6} xl={2} className={classes.piece}>
            <TimeToLeaveIcon color="primary" fontSize="large" />
            <Typography variant="overline" color="primary">
              Транспорт
            </Typography>
            <Typography variant="h6">{quest.transport}</Typography>
          </Grid>
          <Grid item xs={6} xl={2} className={classes.piece}>
            <TimerIcon color="primary" fontSize="large" />
            <Typography variant="overline" color="primary">
              Продължителност
            </Typography>
            <Typography variant="h6">{quest.gameLength} мин.</Typography>
          </Grid>
          <Grid item xs={6} xl={2} className={classes.piece}>
            <PublicIcon color="primary" fontSize="large" />
            <Typography variant="overline" color="primary">
              Уебсайт
            </Typography>
            <Typography variant="h6">
              <Link href={quest.site} target="_blank">
                Резервирай
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Quest
