import React from 'react'
import { Alert } from '@material-ui/lab'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/styles'
import { PageURLs } from 'Routes'
import { useEscapeRooms } from 'core'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faThumbsUp as ThumbsUpIcon,
  faThumbsDown as ThumbsDownIcon,
} from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  link: { color: theme.palette.black },
}))

const UserRecommendation = ({ name, userId, recommends, room }) => {
  const classes = useStyles()
  const { escaperooms } = useEscapeRooms()

  const shortname = escaperooms.filter((escaperoom) => escaperoom.title === room)[0].shortname

  return (
    <Alert
      className={classes.root}
      icon={
        recommends ? (
          <FontAwesomeIcon size="1x" icon={ThumbsUpIcon} />
        ) : (
          <FontAwesomeIcon size="1x" icon={ThumbsDownIcon} />
        )
      }
      severity={recommends ? 'success' : 'error'}>
      <RouterLink className={classes.link} to={`${PageURLs.Profile}/${userId}`}>
        {name?.split(' ')[0]}
      </RouterLink>{' '}
      {recommends ? 'препоръчва' : 'не препоръчва'}{' '}
      <Link component={RouterLink} to={`${PageURLs.EscapeRooms}/${shortname}`}>
        {room}
      </Link>
    </Alert>
  )
}

export default React.memo(UserRecommendation)
