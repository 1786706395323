import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { useTheme, makeStyles } from '@material-ui/styles'
import { Logo } from 'components'

const useStyles = makeStyles({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundImage: "url('/image/key.png')",
    backgroundSize: 80,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '10px 10px 10px 15px rgba(244,246,248,1)',
    background: 'rgba(244,246,248,1)',
    width: 410,
    height: 410,
    borderRadius: '50%',
  },
})

const Loading = () => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.loading}>
      <div className={classes.loadingContainer}>
        <div style={{ marginBottom: theme.spacing(6) }}>
          <Logo />
        </div>
        <CircularProgress />
      </div>
    </div>
  )
}

export default Loading
