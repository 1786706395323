import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core'
import { Toolbar, Row } from './components'
import { useEscapeRooms, useAuth } from 'core'
import { USER_GROUP } from 'helpers'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}))

const CustomTable = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const { escaperooms } = useEscapeRooms()
  const { user } = useAuth()

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const isAdmin = user.userGroup === USER_GROUP.admin.id

  const columns = [
    { name: 'Име', headAlign: 'inherit', width: 360 },
    { name: 'Компания', headAlign: 'inherit', width: 200 },
    { name: 'Валидна до', headAlign: 'inherit', width: 'initial' },
    { name: 'Отбор', headAlign: 'center', width: 'initial' },
    { name: 'Адрес', headAlign: 'inherit', width: 270 },
    { name: 'Трудност', headAlign: 'center', width: 'initial' },
    { name: 'Времетраене', headAlign: 'center', width: 'initial' },
    { name: 'Активна', headAlign: 'center', width: 'initial' },
    { name: 'Действия', headAlign: 'right', width: 'initial' },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <Toolbar />
      <Card {...rest} className={classes.root}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.name}
                        align={column.headAlign}
                        style={{ width: column.width }}>
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isAdmin
                    ? escaperooms
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((escaperoom) => <Row key={escaperoom._id} escaperoom={escaperoom} />)
                    : escaperooms
                        .filter((escaperoom) => escaperoom.owner === user._id)
                        .map((escaperoom) => <Row key={escaperoom._id} escaperoom={escaperoom} />)}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={
              isAdmin
                ? escaperooms.length
                : escaperooms.filter((escaperoom) => escaperoom.owner === user._id).length
            }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={'Редове на страница:'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} от ${count !== -1 ? count : `повече от ${to}`}`
            }
          />
        </CardActions>
      </Card>
    </>
  )
}

CustomTable.propTypes = {
  className: PropTypes.string,
}

export default CustomTable
