import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Chip from '@material-ui/core/Chip'

const Badges = ({ label, textColor = 'white', backgroundColor = null, className, preview }) => {
  const useStyles = makeStyles((theme) => ({
    preview: {
      position: 'relative',
      zIndex: 2,
      color: 'white',
      fontSize: 12,
      marginLeft: 8,
    },
    chip: {
      textTransform: 'uppercase',
      fontSize: 10,
      fontWeight: 500,
      position: 'relative',
      border: 0,
      height: 18,
      zIndex: 2,
      color: textColor,

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        transform: 'skew(-20deg)',
        background: backgroundColor ? backgroundColor : theme.palette.primary.main,
        borderRadius: 2.5,
        zIndex: -1,
      },
    },
  }))

  const classes = useStyles()

  return (
    <div className={className}>
      <div style={preview && { display: 'flex' }}>
        <Chip size="small" variant="outlined" className={classes.chip} label={label} />
        {preview && <div className={classes.preview}>{`<-- Ще изглежда така`}</div>}
      </div>
    </div>
  )
}

export default Badges
