import React, { createContext, useReducer, useEffect, useContext } from 'react'
import axios from 'axios'
import { ContextDevTool } from 'react-context-devtool'
import reducer from './reducer'
import { setAuthToken } from 'helpers'
import { useSnackbar } from 'notistack'

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  loadingAuth: true,
  user: null,
}

const AuthContext = createContext(initialState)

const AuthProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    loadUser()
    // eslint-disable-next-line
  }, [localStorage.token])

  const loadUser = async () => {
    setAuthToken(localStorage.token)

    try {
      const { data } = await axios('/api/auth/users')
      dispatch({
        type: 'USER_LOADED',
        payload: data,
      })
    } catch (error) {
      dispatch({ type: 'AUTH_ERROR' })
    }
  }

  const register = async ({ email, password, name, phone, recaptcha }) => {
    const body = { email, password, name, phone, recaptcha }

    try {
      await axios.post('/api/auth/users', body)

      return true
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      }
      dispatch({
        type: 'REGISTER_FAIL',
      })
      return false
    }
  }

  const verify = async (userId, registrationHash) => {
    try {
      const { data } = await axios.post(`/api/user/verify/${userId}/${registrationHash}`)
      dispatch({
        type: 'VERIFY_SUCCESS',
        payload: data.user,
      })
      dispatch(loadUser())
      enqueueSnackbar('Успешно потвърди профила си. Приятно прекарване на сайта!', {
        variant: 'success',
      })
    } catch (error) {
      const errorMessage = error.response.data.message

      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: 'error' })
      } else {
        enqueueSnackbar('Такъв потребител не съществува', { variant: 'error' })
      }

      dispatch({
        type: 'VERIFY_FAIL',
      })
    }
  }

  const changePassword = async (userId, registrationHash, password) => {
    try {
      const { data } = await axios.post(
        `/api/user/control/change-password/${userId}/${registrationHash}`,
        {
          password,
        }
      )
      dispatch({
        type: 'VERIFY_SUCCESS',
        payload: data.user,
      })
      dispatch(loadUser())
      enqueueSnackbar('Успешно промени паролата си!', {
        variant: 'success',
      })
    } catch (error) {
      const errorMessage = error.response.data.message

      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: 'error' })
      } else {
        enqueueSnackbar('Такъв потребител не съществува', { variant: 'error' })
      }

      dispatch({
        type: 'VERIFY_FAIL',
      })
    }
  }

  const changePasswordOfCurrentUser = async (password) => {
    try {
      const { data } = await axios.post(`/api/auth/user/change-password`, {
        password,
      })
      dispatch({
        type: 'VERIFY_SUCCESS',
        payload: data.user,
      })
      dispatch(loadUser())
      enqueueSnackbar('Успешно промени паролата си!', {
        variant: 'success',
      })
    } catch (error) {
      const errorMessage = error.response.data.message

      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: 'error' })
      } else {
        enqueueSnackbar('Такъв потребител не съществува', { variant: 'error' })
      }

      dispatch({
        type: 'VERIFY_FAIL',
      })
    }
  }

  const forgottenPassword = async (email) => {
    try {
      await axios.post(`/api/user/control/forgotten-password`, { email })
      enqueueSnackbar('Изпратихме ти мейл, провери го, за да промениш паролата си!', {
        variant: 'success',
      })
    } catch (error) {
      const errorMessage = error.response.data.message

      if (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: 'error' })
      } else {
        enqueueSnackbar('Такъв потребител не съществува', { variant: 'error' })
      }

      dispatch({
        type: 'VERIFY_FAIL',
      })
    }
  }

  const login = async ({ email, password, recaptcha }) => {
    const body = { user: { email: email.toLowerCase(), password }, recaptcha }

    try {
      const res = await axios.post('/api/users/login', body)
      enqueueSnackbar('Добре дошъл обратно!', { variant: 'success' })
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: res.data.user,
      })
    } catch (error) {
      enqueueSnackbar(error.response.data.message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      })

      dispatch({
        type: 'LOGIN_FAIL',
      })
    }
  }

  const logout = async () => {
    if (localStorage.token) {
      dispatch({ type: 'LOGOUT' })
    } else {
      try {
        await axios.get('/api/auth/facebook/logout')
        dispatch({ type: 'LOGOUT' })
      } catch (error) {
        dispatch({ type: 'LOGOUT' })
      }
    }
  }

  const addCompany = async (company) => {
    try {
      const { data } = await axios.put(`/api/owner/company/add`, company)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadUser()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const removeCompany = async (company) => {
    try {
      const { data } = await axios.put(`/api/owner/company/remove`, company)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadUser()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const addFavoriteRoom = async (roomId) => {
    try {
      const { data } = await axios.put(`/api/customer/favorite-room/add/${roomId}`)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadUser()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const removeFavoriteRoom = async (roomId) => {
    try {
      const { data } = await axios.put(`/api/customer/favorite-room/remove/${roomId}`)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadUser()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      }
    }
  }

  const editProfile = async (form) => {
    try {
      const { data } = await axios.put(`/api/auth/user/update`, form)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadUser()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }))
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loadingAuth: state.loadingAuth,
        user: state.user,
        login,
        register,
        verify,
        logout,
        addCompany,
        removeCompany,
        addFavoriteRoom,
        removeFavoriteRoom,
        editProfile,
        changePassword,
        forgottenPassword,
        changePasswordOfCurrentUser,
      }}>
      <ContextDevTool context={AuthContext} id="auth" displayName="Authorization" />
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
