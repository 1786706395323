export default {
  header: {
    height: 70,
    scrollHeight: 55,
  },
  footer: {
    height: 100,
  },
  main: {
    contentWidth: 1495,
    spaceBetweenContents: 35,
  },
  homePage: {
    escapeRoom: {
      width: 330,
      height: 250,
      additionalInformationHeight: 60,
    },
    escapeRoomNew: {
      height: 160,
    },
  },
  escapeRoom: {
    imageHeight: 500,
  },
}
