import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { useEscapeRooms, useAuth } from 'core'
import { USER_GROUP } from 'helpers'

const SelectRoom = ({ form, setForm }) => {
  const { escaperooms } = useEscapeRooms()
  const { user } = useAuth()
  const ownerRooms = escaperooms.filter((escaperoom) => escaperoom.owner === user._id)

  return (
    <Grid item xs={12}>
      <Autocomplete
        options={user.userGroup === USER_GROUP.admin.id ? escaperooms : ownerRooms}
        getOptionLabel={(option) => option.title}
        onChange={(e, room) => setForm({ ...form, taggedRoom: room?._id })}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={'Стаята, за която се отнася новината'}
            variant="outlined"
          />
        )}
      />
    </Grid>
  )
}

export default React.memo(SelectRoom)
