import React, { useState } from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import {
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { messages } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
  },
}))

const DeleteUser = ({ user, setInitialUser }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleDeleteUser = async () => {
    setIsLoading(true)
    try {
      await axios.put(`/api/admin/user/delete/${user._id}`)
      enqueueSnackbar(messages.admin.user.deleteSuccess, { variant: 'success' })
      setInitialUser({ ...user, isEnabled: false })
      setIsLoading(false)
      handleClose()
    } catch (error) {
      enqueueSnackbar(messages.admin.user.deleteFail, { variant: 'error' })
      setIsLoading(false)
      handleClose()
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Деактивирай потребителя">
        <IconButton size="small" onClick={handleClickOpen}>
          <LockOutlinedIcon className={classes.root} />
        </IconButton>
      </Tooltip>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Деактивирай потребител</DialogTitle>
        <DialogContent>
          <Typography>
            Ако деактивираш този потребител, той няма да може да създава повече ревюта.
          </Typography>
          <Typography>
            Това НЕ изтрива потребителят от базата данни, а само го деактивира.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={isLoading} onClick={handleDeleteUser}>
            {isLoading && <CircularProgress size={14} style={{ marginRight: 4 }} />} Деактивирай
          </Button>
          <Button onClick={handleClose}>Отмени</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteUser
