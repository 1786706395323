export const USER_GROUP = {
  admin: {
    id: 0,
    name: 'Администратор',
  },
  owner: {
    id: 1,
    name: 'Собственик на стая',
  },
  user: {
    id: 100,
    name: 'Потребител',
  },
}
