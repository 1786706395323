import React, { useState, useEffect } from 'react'
import { Typography, Grid, makeStyles, CircularProgress } from '@material-ui/core'
import { SmallHeading, BigHeading } from 'layouts/Main/components'
import { Review } from './components'
import { useFetchData } from 'helpers'
import { useEscapeRooms } from 'core'
import { messages } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  grid: {
    maxWidth: theme.sizing.main.contentWidth,
    minHeight: 1158,
  },
}))

const LatestReviews = () => {
  const classes = useStyles()
  const { escaperooms } = useEscapeRooms()

  const [reviews, setReviews] = useState([])

  const url = `/api/reviews?page=1`
  const [data, isLoading, isError, setErrorMessage] = useFetchData(url)

  useEffect(() => {
    setErrorMessage(messages.review.failurePlural)
    !isLoading && setReviews(data.reviews)
    // eslint-disable-next-line
  }, [data, isLoading])

  return reviews && reviews.length >= 6 ? (
    <div className={classes.root} id="reviews-part">
      <BigHeading>Последни оценки</BigHeading>
      <SmallHeading></SmallHeading>
      <Grid container className={classes.grid} spacing={5}>
        {isLoading ? (
          <CircularProgress />
        ) : isError ? (
          ''
        ) : reviews !== undefined && reviews.length ? (
          <>
            {reviews.map((review) => (
              <Review
                key={review._id}
                reviewId={review._id}
                user={review.user}
                date={review.date}
                image={review.file}
                recommends={review.recommend}
                room={escaperooms.filter((escaperoom) => escaperoom._id === review.roomId)[0].title}
                review={review.review}
                interactions={{
                  comments: review.comments,
                  likes: review.likes,
                }}
              />
            ))}
          </>
        ) : (
          <Grid item>
            <Typography>Не бяха открити оценки</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  ) : (
    ''
  )
}

export default LatestReviews
