import React from 'react'
import { Box, Divider, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const DividerWithText = ({ color, text }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}>
      <Divider style={{ flexGrow: 5 }} />
      <Box display="flex" justifyContent="center" style={{ flexGrow: 1 }}>
        <Typography color={color}>{text}</Typography>
      </Box>
      <Divider style={{ flexGrow: 5 }} />
    </Box>
  )
}

export default DividerWithText
