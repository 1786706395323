import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  CircularProgress,
} from '@material-ui/core'
import { Toolbar, Search, Row } from './components'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useAuth } from 'core'
import { USER_GROUP } from 'helpers'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  actions: {
    justifyContent: 'space-between',
  },
}))

const ReviewsTable = (props) => {
  const { className, ...rest } = props
  const { user } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(1)
  const [reviews, setReviews] = useState([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [filter, setFilter] = useState('all')

  const adminUrl = `/api/admin/reviews/${filter}?page=${page}&limit=${rowsPerPage}`
  const ownerUrl = `/api/owner/reviews/${filter}?page=${page}&limit=${rowsPerPage}`
  const url = user.userGroup === USER_GROUP.admin.id ? adminUrl : ownerUrl

  const columns = [
    { name: 'Име', headAlign: 'inherit' },
    { name: 'Имейл', headAlign: 'inherit' },
    { name: 'Дата на игра', headAlign: 'inherit' },
    { name: 'Стая', headAlign: 'inherit' },
    { name: 'Създадена', headAlign: 'inherit' },
    { name: 'Потвърдена', headAlign: 'center' },
    { name: 'Написано ревю', headAlign: 'center' },
    { name: 'Още', headAlign: 'right' },
  ]

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(url)
        setReviews(data.reviews)
        setCount(data.count)
        setIsLoading(false)
      } catch (error) {
        enqueueSnackbar('Неуспех при търсене на ревюта')
        console.error(error)
        setIsLoading(false)
      }
    }

    email === '' && fetchReviews()
    // eslint-disable-next-line
  }, [page, rowsPerPage, email, filter])

  const handlePageChange = (event, page) => {
    setPage(page + 1)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value)
  }

  return (
    <>
      <Toolbar filter={filter} setFilter={setFilter} setPage={setPage} />
      <Card {...rest} className={classes.root}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.name} align={column.headAlign}>
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    reviews.map((review) => (
                      <Row key={review._id} columns={columns} review={review} setPage={setPage} />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={columns.length}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <Search
            email={email}
            setEmail={setEmail}
            setIsLoading={setIsLoading}
            setReviews={setReviews}
            setCount={setCount}
            setFilter={setFilter}
            page={page}
            rowsPerPage={rowsPerPage}
          />
          <TablePagination
            component="div"
            count={count}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page - 1}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={'Редове на страница:'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} от ${count !== -1 ? count : `повече от ${to}`}`
            }
          />
        </CardActions>
      </Card>
    </>
  )
}

ReviewsTable.propTypes = {
  className: PropTypes.string,
}

export default ReviewsTable
