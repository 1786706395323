import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Link, Grid } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { Logo } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.mineShaft,
    marginTop: theme.spacing(4),
  },
  footer: {
    '& a, span, p': {
      color: 'white',
    },
  },
}))

const Footer = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={classes.root}>
      <div className={clsx(classes.footer, className)}>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={8}>
            <Grid container>
              <Grid item style={{ paddingRight: 0, marginRight: 8 }}>
                <Logo color="white" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Link
              target="_blank"
              rel="noopener"
              href="/terms-to-apply"
              style={{ fontSize: 14, marginRight: 8 }}>
              Условия за присъединяване
            </Link>
            <Link
              target="_blank"
              rel="noopener"
              href="/terms"
              style={{ fontSize: 14, marginRight: 8 }}>
              Общи условия
            </Link>
            <Link
              target="_blank"
              rel="noopener"
              href="/privacy-and-cookies"
              style={{ fontSize: 14 }}>
              Лични данни
            </Link>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 8 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderTop: '1px solid #373737',
                paddingTop: 12,
                flexWrap: 'wrap',
              }}>
              <Typography variant="body1">
                &copy;{' '}
                <Link component={RouterLink} to="/">
                  Vsichkistai
                </Link>{' '}
                - {new Date().getUTCFullYear()}
              </Typography>
              <Typography variant="caption">
                Created with love for the escape room branch by{' '}
                <Link
                  target="_blank"
                  rel="noopener"
                  href="https://www.facebook.com/ivan.vladimirov.graphic/">
                  this guy
                </Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
