import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuth } from 'core'
import { USER_GROUP } from 'helpers'

const PrivateRouteWithLayout = (props) => {
  const { acceptedGroups, layout: Layout, component: Component, ...rest } = props
  const { isAuthenticated, user } = useAuth()

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isAuthenticated && user && user.group !== USER_GROUP.user.id ? (
          acceptedGroups.includes(user.userGroup) ? (
            <Layout>
              <Component {...matchProps} />
            </Layout>
          ) : (
            <Redirect to="/403" />
          )
        ) : (
          <Redirect to="/403" />
        )
      }
    />
  )
}

PrivateRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
}

export default PrivateRouteWithLayout
