import React, { useState } from 'react'
import { Box, Dialog, DialogActions, IconButton, Avatar, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import { USER_GROUP } from 'helpers'
import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  previewImage: {
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  actions: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    top: 0,
    right: 0,
  },
  iconButton: {
    color: theme.palette.white,
  },
}))

const Photo = ({
  image = '',
  circle = false,
  width = 400,
  height = 400,
  size = null,
  adminView = false,
  handleDelete = () => console.log('You need to mount a delete function'),
}) => {
  const classes = useStyles()
  const { user } = useAuth()

  const [open, setOpen] = useState(false)

  const handleOpenImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
      {image ? (
        circle ? (
          <Avatar
            alt="Team preview"
            src={image}
            className={classes.previewImage}
            style={size ? { width: size, height: size } : { width: width, height: height }}
            onClick={handleOpenImage}
          />
        ) : (
          <Box
            alt="Team preview"
            src={image}
            className={classes.previewImage}
            style={
              size
                ? {
                    backgroundImage: `url(${image})`,
                    width: size,
                    height: size,
                    borderRadius: 4,
                  }
                : {
                    backgroundImage: `url(${image})`,
                    width: width,
                    height: height,
                    borderRadius: 4,
                  }
            }
            onClick={handleOpenImage}
          />
        )
      ) : (
        'Няма качена снимка'
      )}

      <Dialog maxWidth={false} open={open} onClose={handleClose}>
        <DialogActions className={classes.actions}>
          {adminView && user.userGroup === USER_GROUP.admin.id ? (
            <IconButton
              className={classes.iconButton}
              size="small"
              onClick={() => {
                handleDelete()
                handleClose()
              }}>
              <DeleteIcon />
            </IconButton>
          ) : (
            <span style={{ opacity: 0 }}>.</span>
          )}
          <IconButton className={classes.iconButton} size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <img alt="Team" src={image} style={{ maxWidth: '100%' }} />
      </Dialog>
    </Box>
  )
}

export default React.memo(Photo)
