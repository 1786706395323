import React, { useState, useEffect } from 'react'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { UserDetails, Reviews, Settings } from './components'
import { useParams, Switch, Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useAuth } from 'core'
import { PageURLs } from 'Routes'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { Review } from 'components'
import { LegacyReview } from 'components'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  grid: {
    maxWidth: theme.sizing.main.contentWidth,
    width: '100%',
    display: 'flex',
  },
}))

const Profile = () => {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const { profileId, reviewId } = useParams()
  const { url } = useRouteMatch()
  const { user, isAuthenticated } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [review, setReview] = useState(null)

  const searchUrl =
    url.split('/')[3] === 'review' ? `/api/review/${reviewId}` : `/api/legacy-review/${reviewId}`
  const isOwnProfile = isAuthenticated && user._id === profileId

  useEffect(() => {
    const getReview = async () => {
      setReview(null)
      setIsLoading(true)
      try {
        const { data } = await axios.get(searchUrl)
        setReview(data.review)
        setIsLoading(false)
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
        setIsLoading(false)
        history.push('/')
      }
    }

    reviewId && getReview()
    // eslint-disable-next-line
  }, [reviewId])

  return (
    <div className={classes.root}>
      <Grid container className={classes.grid} spacing={2}>
        <UserDetails
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          isOwnProfile={isOwnProfile}
        />
        <Grid item xs={12}>
          <Switch>
            <Route exact path={`${PageURLs.Profile}/:profileId`}>
              <Reviews isOwnProfile={isOwnProfile} profileId={profileId} />
            </Route>
            <Route path={`${PageURLs.Profile}/:profileId/review/:reviewId`}>
              {reviewId && !isLoading && review !== null ? (
                <Review
                  isOwn={isOwnProfile}
                  expandedComments={review.finished ? true : false}
                  fullReview={review}
                  isOnOwnPage
                />
              ) : (
                <CircularProgress />
              )}
            </Route>
            <Route path={`${PageURLs.Profile}/:profileId/legacy-review/:reviewId`}>
              {!isLoading && review !== null ? (
                <LegacyReview isOwn={isOwnProfile} fullReview={review} isOnOwnPage />
              ) : (
                <CircularProgress />
              )}
            </Route>
            {isAuthenticated && (
              <Route path={`${PageURLs.Profile}/${user?._id}/settings`}>
                <Settings />
              </Route>
            )}
          </Switch>
        </Grid>
      </Grid>
    </div>
  )
}

export default Profile
