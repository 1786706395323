import {
  faGhost,
  faChild,
  faVrCardboard,
  faPuzzlePiece,
  faTheaterMasks,
  faTh,
  faDragon,
  faRadiation,
  faQuestion,
} from '@fortawesome/free-solid-svg-icons'

export default [
  { name: '*', value: 'Всички', icon: faTh, background: '#FF4800' },
  { name: 'horror', value: 'Страшни', icon: faGhost, background: '#ed0d0d' },
  { name: 'actors', value: 'С актьори', icon: faTheaterMasks, background: '#f100aa' },
  { name: 'adventure', value: 'Приключение', icon: faPuzzlePiece, background: '#c09a6b' },
  { name: 'vr', value: 'VR', icon: faVrCardboard, background: '#a0a0a5' },
  { name: 'kids', value: 'За деца', icon: faChild, background: '#6acb71' },
  { name: 'scifi', value: 'Sci-Fi/Фентъзи', icon: faDragon, background: '#40aed8' },
  { name: 'action', value: 'Екшън/Крими', icon: faRadiation, background: '#f87703' },
  { name: 'mystery', value: 'Мистерия', icon: faQuestion, background: '#744dd9' },
]
