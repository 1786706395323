import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar,
  Toolbar,
  Badge,
  Hidden,
  IconButton,
  Menu,
  Paper,
  Typography,
  Divider,
  MenuItem,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined'
import InputIcon from '@material-ui/icons/Input'
import { Logo } from 'components'
import { useAuth } from 'core'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  logo: {
    maxHeight: 20,
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  badge: { cursor: 'pointer' },
  card: { maxWidth: 345 },
  avatar: { backgroundColor: theme.palette.primary.main },
  notifications: { padding: theme.spacing(2) },
}))

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props

  const classes = useStyles()
  const { user, logout } = useAuth()
  const { notifications } = user

  const ITEM_HEIGHT = 48

  const [notificationsCount, setNotificationsCount] = useState(
    notifications.filter((notification) => notification.seen === false).length
  )
  const [anchorNotificationsEl, setAnchorNotificationsEl] = useState(null)

  const handleReadNotifications = async () => {
    try {
      await axios.get(`/api/customer/read-notifications`)
    } catch {
      console.error('Не можах да прочета нотификациите')
    }
  }

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            size="small"
            onClick={onSidebarOpen}
            style={{ color: 'white', marginRight: 16 }}>
            <MenuIcon style={{ fontSize: 28 }} />
          </IconButton>
        </Hidden>
        <RouterLink to="/admin">
          <Logo color="white" />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton
            color="inherit"
            onClick={(event) => {
              setNotificationsCount(0)
              setAnchorNotificationsEl(event.currentTarget)
              notificationsCount > 0 && handleReadNotifications()
            }}>
            <Badge
              className={classes.badge}
              badgeContent={notificationsCount}
              max={20}
              overlap="rectangular">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton className={classes.signOutButton} color="inherit" onClick={logout}>
            <InputIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Menu
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 6,
            maxWidth: 230,
          },
        }}
        anchorEl={anchorNotificationsEl}
        open={Boolean(anchorNotificationsEl)}
        onClose={() => setAnchorNotificationsEl(null)}>
        {notifications
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((notification) => {
            if (notification.link) {
              return (
                <div key={notification._id}>
                  <RouterLink to={notification.link} onClick={() => setAnchorNotificationsEl(null)}>
                    <Paper elevation={0} square className={classes.notifications}>
                      <Badge
                        color="secondary"
                        variant="dot"
                        invisible={notification.seen}
                        overlap="rectangular">
                        <Typography>{notification.message}</Typography>
                      </Badge>
                      <Typography variant="overline">
                        {Intl.DateTimeFormat('bg-BG', {
                          dateStyle: 'short',
                          timeStyle: 'medium',
                        }).format(new Date(notification.date))}
                      </Typography>
                    </Paper>
                  </RouterLink>
                  <Divider />
                </div>
              )
            }

            return (
              <div key={notification._id}>
                <MenuItem>
                  <Badge color="secondary" variant="dot" overlap="rectangular">
                    <Typography>{notification.message}</Typography>
                  </Badge>
                  <Typography variant="overline">
                    {Intl.DateTimeFormat('bg-BG', {
                      dateStyle: 'short',
                      timeStyle: 'medium',
                    }).format(new Date(notification.date))}
                  </Typography>
                </MenuItem>
                <Divider />
              </div>
            )
          })}
      </Menu>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

export default Topbar
