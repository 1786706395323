import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Typography, Paper, TextField, Button } from '@material-ui/core'
import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  contentBody: {
    flexGrow: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    padding: theme.spacing(4),
    flexBasis: 700,
    margin: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const ChangePassword = () => {
  const classes = useStyles()
  const history = useHistory()
  const { userId, forgottenPasswordHash } = useParams()
  const { changePassword, changePasswordOfCurrentUser, user } = useAuth()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(false)

  const checkPasswords = () => {
    if (password !== confirmPassword) {
      setError(true)
    } else {
      setError(false)
    }
  }

  const handleChangePassword = () => {
    if (userId && forgottenPasswordHash && user === null) {
      changePassword(userId, forgottenPasswordHash, password)
    } else {
      changePasswordOfCurrentUser(password)
    }
    history.push('/')
  }

  return (
    <div className={classes.root}>
      <div className={classes.contentBody}>
        <Paper className={classes.form}>
          <Typography variant="h2" gutterBottom>
            Смяна на парола
          </Typography>
          <TextField
            type="password"
            className={classes.textField}
            fullWidth
            variant="outlined"
            size="small"
            label="Нова парола"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            type="password"
            className={classes.textField}
            fullWidth
            variant="outlined"
            size="small"
            label="Повтори паролата"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={checkPasswords}
            helperText={error ? 'Паролите не съвпадат' : ''}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangePassword}
            disabled={password !== confirmPassword || password === ''}>
            Смени паролата
          </Button>
        </Paper>
      </div>
    </div>
  )
}

export default ChangePassword
