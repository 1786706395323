import React, { useState } from 'react'
import { Typography, Menu, MenuItem, IconButton } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ShareIcon from '@material-ui/icons/Share'
import { Share } from 'layouts/Main/components'
import { PageURLs } from 'Routes'

const CardButtons = ({ room, profileId, reviewId }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openShareDialog, setOpenShareDialog] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleShare = () => {
    setAnchorEl(null)
    setOpenShareDialog(true)
  }

  return (
    <Typography>
      <IconButton size="small" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleShare}>
        <MenuItem onClick={handleShare}>
          <ShareIcon style={{ marginRight: 8 }} /> Сподели
        </MenuItem>
      </Menu>
      <Share
        title={`Ескейп игра в ${room}`}
        shareUrl={`${PageURLs.Profile}/${profileId}/review/${reviewId}`}
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
      />
    </Typography>
  )
}

export default CardButtons
