import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import { useEscapeRooms, useReviews } from 'core'
import { useParams } from 'react-router-dom'

const SelectRoom = ({ form, setForm }) => {
  const { escaperooms, loadingEscapeRooms } = useEscapeRooms()
  const { allReviewedRooms } = useReviews()
  const params = useParams()
  const isExistingReview = params.id ? true : false

  return (
    <Grid item xs={12}>
      {!loadingEscapeRooms && (
        <>
          <Autocomplete
            disabled={isExistingReview}
            options={escaperooms
              .filter((escaperoom) => escaperoom.status)
              .filter((escaperoom) => !allReviewedRooms.includes(escaperoom._id))}
            noOptionsText={'Няма намерени стаи'}
            getOptionLabel={(option) => option.title}
            defaultValue={escaperooms.filter((escaperoom) => escaperoom._id === form.roomId)[0]}
            onChange={(e, room) => setForm({ ...form, roomId: room?._id })}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={
                  isExistingReview ? 'Стаята, която оценяваш' : 'Стаята, която искаш да оцениш'
                }
                variant="outlined"
              />
            )}
          />
        </>
      )}
    </Grid>
  )
}

export default React.memo(SelectRoom)
