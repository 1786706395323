import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Grid, IconButton, Hidden } from '@material-ui/core'
import { NavBar, ScrollLogic, Login, UserInformation } from './components'
import { Logo } from 'components'
import { useAuth } from 'core'
import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.sizing.header.height,
    minHeight: theme.sizing.header.scrollHeight,
    maxWidth: theme.sizing.main.contentWidth,
    margin: '0 auto',
    width: '100%',
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const Header = ({ isDesktop, handleSidebarOpen, ...rest }) => {
  const classes = useStyles()
  const { isAuthenticated, user } = useAuth()

  return (
    <ScrollLogic>
      <AppBar {...rest} className={classes.root} position="fixed">
        <Toolbar className={classes.header}>
          <Grid container className={classes.container}>
            <Grid
              item
              xs={isDesktop ? 2 : 10}
              className={classes.flexItem}
              style={{ justifyContent: 'flex-start' }}>
              <Hidden lgUp>
                <IconButton size="small" onClick={handleSidebarOpen} style={{ marginRight: 16 }}>
                  <MenuIcon style={{ fontSize: 28 }} />
                </IconButton>
              </Hidden>
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={8} className={classes.flexItem} style={{ justifyContent: 'center' }}>
                <NavBar />
              </Grid>
            </Hidden>
            <Grid item xs={2} className={classes.flexItem} style={{ justifyContent: 'flex-end' }}>
              {isAuthenticated && user ? (
                <UserInformation isDesktop={isDesktop} />
              ) : (
                <Login isDesktop={isDesktop} />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </ScrollLogic>
  )
}

export default React.memo(Header)
