import React, { createContext, useReducer, useContext, useEffect } from 'react'
import { ContextDevTool } from 'react-context-devtool'
import reducer from './reducer'
// import axios from 'axios'

const AdsContext = createContext([])

const initialState = {
  loadingAds: true,
  errorAds: false,
  errorMessageAds: null,
  ads: [],
}

export const AdsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const res = [{ page: 'EscapeRooms', title: 'My title', image: '', text: "Hey, that's an ad!" }]

  useEffect(() => {
    loadAds()
    // eslint-disable-next-line
  }, [])

  const loadAds = async () => {
    try {
      dispatch({
        type: 'ADS_LOADED',
        payload: res,
        // payload: res.data,
      })
    } catch (error) {
      dispatch({
        type: 'ADS_FAIL',
        payload: error.message,
      })
    }
  }

  return (
    <AdsContext.Provider
      value={{
        loadingAds: state.loadingAds,
        errorAds: state.errorAds,
        errorMessageAds: state.errorMessageAds,
        ads: state.ads,
      }}>
      <ContextDevTool context={AdsContext} id="ads" displayName="Ads" />
      {children}
    </AdsContext.Provider>
  )
}

export const useAds = () => {
  const context = useContext(AdsContext)

  if (context === undefined) {
    throw new Error('useAds can only be used inside AdsProvider')
  }

  return context
}
