import React from 'react'
import theme from 'theme'

// Import all stores
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import {
  EscapeRoomsProvider,
  AuthProvider,
  ReviewsProvider,
  AdsProvider,
  QuestsProvider,
} from './contexts'

const Store = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={6} preventDuplicate>
        <AuthProvider>
          <EscapeRoomsProvider>
            <QuestsProvider>
              <AdsProvider>
                <ReviewsProvider>{children}</ReviewsProvider>
              </AdsProvider>
            </QuestsProvider>
          </EscapeRoomsProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Store
