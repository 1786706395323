import React, { useEffect } from 'react'
import { TextField, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { useAuth } from 'core'
import { USER_GROUP } from 'helpers'

const Search = (props) => {
  const { user } = useAuth()
  const { email, setEmail, setIsLoading, setReviews, setCount, page, rowsPerPage } = props
  const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g
  const { enqueueSnackbar } = useSnackbar()

  const adminUrl = `/api/admin/reviews/byEmail/${email}?page=${page}&limit=${rowsPerPage}`
  const ownerUrl = `/api/owner/reviews/byEmail/${email}?page=${page}&limit=${rowsPerPage}`
  const url = user.userGroup === USER_GROUP.admin.id ? adminUrl : ownerUrl

  useEffect(() => {
    const handleSearch = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(url)
        setReviews(data.reviews)
        setCount(data.count)
        setIsLoading(false)
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' })
        setEmail('')

        setIsLoading(false)
      }
    }

    email.match(emailRegex) && handleSearch()
    // eslint-disable-next-line
  }, [page, email, rowsPerPage])

  return (
    <div>
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        size="small"
        variant="outlined"
        label={'Търси по имейл'}
      />
      <Button disabled={email === ''} onClick={() => setEmail('')}>
        Изчисти
      </Button>
    </div>
  )
}

export default Search
