import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, useLocation, Redirect, useHistory } from 'react-router-dom'
import { useTheme, makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import { useEscapeRooms, useReviews } from 'core'
import { CurvedSeparator, BigHeading, SmallHeading } from 'layouts/Main/components'
import {
  UploadPhoto,
  SelectRoom,
  PickDate,
  Opinion,
  SetRecommendation,
  Submit,
  ExistingPhoto,
} from './components'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { messages } from 'utils'
import { isEmpty } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  grid: {
    maxWidth: theme.sizing.main.contentWidth / 2,
    width: '100%',
  },
  heading: {
    backgroundColor: theme.palette.white,
    width: '100%',
    marginBottom: '0 !important',
    paddingTop: theme.spacing(5),
  },
  secondaryHeading: {
    backgroundColor: theme.palette.white,
    width: '100%',
    marginBottom: '0 !important',
  },
}))

const Review = () => {
  const theme = useTheme()
  const history = useHistory()
  const classes = useStyles()
  const params = useParams()
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar()
  const { escaperooms } = useEscapeRooms()
  const { loadReviews } = useReviews()

  const isExistingReview = params.id ? true : false

  const [redirect, setRedirect] = useState(false)
  const [form, setForm] = useState({})

  useEffect(() => {
    if (params.id) {
      axios
        .get(`/api/customer/review/${params.id}`)
        .then((review) => {
          setForm({
            id: review.data.review._id,
            roomId: review.data.review.roomId,
            date: review.data.review.date,
            file: review.data.review.file,
            recommend: review.data.review.recommend,
          })
        })
        .catch(() => {
          enqueueSnackbar(messages.review.noRights, { variant: 'error' })
          setRedirect(true)
        })
    } else {
      setForm({
        file: '',
        roomId: location.state?.id || '',
        date: new Date(),
        review: '',
        recommend: true,
      })
    }
    // eslint-disable-next-line
  }, [params.id])

  const handleSubmit = async () => {
    if (form.id) {
      try {
        await axios.put(`/api/customer/review/finalize/${params.id}`, form)
        enqueueSnackbar(messages.review.success, { variant: 'success' })
        loadReviews()
        history.push('/')
      } catch (error) {
        enqueueSnackbar(messages.common.fail, { variant: 'error' })
      }
    } else {
      try {
        await axios.post(`/api/customer/review/create`, form)
        enqueueSnackbar(messages.review.submitted, { variant: 'success' })
        loadReviews()
        history.push('/')
      } catch (error) {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }
    }
  }

  return (
    <div className={classes.root}>
      {!isEmpty(form) && (
        <>
          <BigHeading className={classes.heading}>
            Напиши ревю за{' '}
            {isExistingReview
              ? escaperooms.filter((escaperoom) => escaperoom._id === form.roomId)[0]?.title
              : 'стая'}
          </BigHeading>
          {isExistingReview && (
            <SmallHeading className={classes.secondaryHeading}>
              която игра на {new Intl.DateTimeFormat('bg-BG').format(new Date(form.date))}
            </SmallHeading>
          )}

          <CurvedSeparator
            topColor={theme.palette.white}
            bottomColor={theme.palette.background.default}
            height={120}
          />

          <Grid className={classes.grid} container spacing={2}>
            {isExistingReview ? (
              <ExistingPhoto form={form} />
            ) : (
              <UploadPhoto form={form} setForm={setForm} />
            )}
            <SelectRoom form={form} setForm={setForm} />
            <PickDate form={form} setForm={setForm} />
            <Opinion form={form} setForm={setForm} />
            <SetRecommendation form={form} setForm={setForm} />
            <Submit form={form} handleSubmit={handleSubmit} />
            {!isExistingReview && (
              <Grid item style={{ marginBottom: 24 }}>
                <Typography variant="overline" gutterBottom>
                  Когато запазиш оценката, тя ще бъде прегледана от администратор, преди да бъде
                  пусната официално в сайта. Мнението ти ще бъде проверено за достоверност, както и
                  за други критерии (вулгарно или обидно съдържание за раса, пол, религия, сексуална
                  ориентация и т.н.)
                </Typography>
              </Grid>
            )}
          </Grid>
          {redirect && <Redirect to="/403" />}
        </>
      )}
    </div>
  )
}

export default Review
