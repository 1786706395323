import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMediaQuery, Hidden } from '@material-ui/core'
import { Header, Footer, Sidebar } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.sizing.header.height,
    position: 'relative',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    flexGrow: 10,
  },
  container: {
    width: '100%',
    maxWidth: theme.sizing.main.contentWidth,
  },
}))

const Main = ({ children }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = () => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = () => {
    setOpenSidebar(false)
  }

  const shouldOpenSidebar = isDesktop ? true : openSidebar
  return (
    <div className={classes.root}>
      <Header handleSidebarOpen={handleSidebarOpen} isDesktop={isDesktop} />
      <Hidden lgUp>
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
      </Hidden>
      <main className={classes.content}>{children}</main>
      <Footer className={classes.container} />
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
