import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'
import { Badges, MainInformation, Image } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    width: theme.sizing.homePage.escapeRoom.width,
    borderRadius: 15,
    height: theme.sizing.homePage.escapeRoom.height,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  additionalInformation: {
    opacity: 0,
    transition: 'all 0.3s',

    [theme.breakpoints.down('lg')]: {
      opacity: 1,
    },
  },
  buttons: {
    opacity: 0,
    transition: 'all 0.3s',

    [theme.breakpoints.down('lg')]: {
      opacity: 1,
    },
  },
  badgesWrapper: {
    position: 'absolute',
    top: theme.spacing(2),
    left: -theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}))

const EscapeRoom = ({ escaperoom, className, previewBadge }) => {
  const classes = useStyles()

  return (
    <div style={{ position: 'relative' }} className={className}>
      <div className={classes.badgesWrapper}>
        <Badges
          label={previewBadge.label}
          backgroundColor={previewBadge.color}
          textColor={previewBadge.textColor}
          preview
        />
        {escaperoom.badges.map((badge) => (
          <Badges
            key={badge.label}
            label={badge.label}
            backgroundColor={badge.color}
            textColor={badge.textColor}
          />
        ))}
      </div>

      <Paper className={classes.root} elevation={3}>
        <Image url={escaperoom.thumbnail} />
        <MainInformation title={escaperoom.title} />
      </Paper>
    </div>
  )
}

export default React.memo(EscapeRoom)
