import React from 'react'
import LockFilled from '@material-ui/icons/Lock'
import LockNotFilled from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/styles'

const MAX_COMPLEXITY = 5

const useStyles = makeStyles((theme) => ({
  root: {
    '& svg': {
      paddingTop: 5,
      fontSize: 25,
      color: theme.palette.primary.main,
    },
  },
}))

const Complexity = ({ complexity }) => {
  const classes = useStyles()

  const showComplexity = (complexity) => {
    let calculatedComplexity = []

    for (let i = 0; i < complexity; i++) {
      calculatedComplexity.push(<LockFilled />)
    }

    for (let i = calculatedComplexity.length; i < MAX_COMPLEXITY; i++) {
      calculatedComplexity.push(<LockNotFilled />)
    }

    if (calculatedComplexity.length > MAX_COMPLEXITY) {
      calculatedComplexity = []

      for (let i = 0; i < MAX_COMPLEXITY; i++) {
        calculatedComplexity.push(<LockFilled />)
      }
    }

    return calculatedComplexity.map((lock, id) => {
      return (
        <span className={classes.root} key={id}>
          {lock}
        </span>
      )
    })
  }

  return showComplexity(complexity)
}

export default Complexity
