import React from 'react'
import { Grid, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ComponentTitle } from '../../../../components'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  divider: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    background: theme.palette.mainLayout.divider,
  },
}))

const Description = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid xs={12} item className={classes.root}>
      <Divider className={classes.divider} />
      <ComponentTitle>За стаята</ComponentTitle>
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </Grid>
  )
}

export default Description
