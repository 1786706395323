import React, { useState } from 'react'
import {
  TextField,
  Button,
  Box,
  makeStyles,
  Typography,
  Avatar,
  CardHeader,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({ button: { marginLeft: theme.spacing(1) } }))

const Search = (props) => {
  const { setIsLoading, setForm, form } = props
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [user, setUser] = useState({})
  const [message, setMessage] = useState(false)

  const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g

  const handleSearch = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get(`/api/admin/user/${email}`)
      setUser(data)
      setIsLoading(false)
      setMessage(false)
    } catch (error) {
      const errors = error.response.data.errors
      setUser({})

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.msg, { variant: 'error' }), setEmail(''))
      } else {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }

      setIsLoading(false)
      setMessage(false)
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          size="small"
          variant="outlined"
          label={'Търси по имейл'}
        />
        <Button
          className={classes.button}
          disabled={email === '' || !email.match(emailRegex)}
          variant="contained"
          color="primary"
          onClick={handleSearch}
          size="small">
          Търси
        </Button>

        <Button
          className={classes.button}
          disabled={email === ''}
          variant="contained"
          onClick={() => setEmail('')}
          size="small">
          Изчисти
        </Button>
      </Box>
      {user.email && (
        <Box mt={2}>
          <Typography>Намерен потребител:</Typography>
          <CardHeader
            avatar={<Avatar src={user.avatar} />}
            title={user.name}
            action={
              <Button
                size="small"
                variant="contained"
                style={{ marginTop: 12 }}
                color="primary"
                onClick={() => {
                  setForm({ ...form, owner: user._id })
                  setMessage(true)
                }}>
                Избери
              </Button>
            }
            subheader={user.email}
          />
          {message && <Typography variant="overline">Потребителят е избран успешно</Typography>}
        </Box>
      )}
    </>
  )
}

export default Search
