import React, { useEffect } from 'react'
import { TextField, Button } from '@material-ui/core'
import axios from 'axios'

const Search = (props) => {
  const { page, rowsPerPage, email, setEmail, setIsLoading, setUsers, setCount } = props
  const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g

  useEffect(() => {
    const handleSearch = async () => {
      setIsLoading(true)
      try {
        const { data } = await axios.get(`/api/admin/user/${email}`)
        setUsers([data])
        setCount(1)
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    }

    email.match(emailRegex) && emailRegex.test(email) && handleSearch()
    // eslint-disable-next-line
  }, [page, email, rowsPerPage])

  return (
    <div>
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        size="small"
        variant="outlined"
        label={'Търси по имейл'}
      />
      <Button disabled={email === ''} onClick={() => setEmail('')}>
        Изчисти
      </Button>
    </div>
  )
}

export default Search
