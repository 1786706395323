export default (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'USER_LOADED':
      return {
        ...state,
        isAuthenticated: true,
        loadingAuth: false,
        user: payload,
      }
    case 'VERIFY_SUCCESS':
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', payload.token)
      return {
        ...state,
        user: payload.user,
        isAuthenticated: true,
        loadingAuth: false,
      }
    case 'REGISTER_FAIL':
    case 'VERIFY_FAIL':
    case 'LOGIN_FAIL':
    case 'LOGOUT':
    case 'AUTH_ERROR':
      localStorage.removeItem('token')
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loadingAuth: false,
        user: null,
      }
    default:
      return state
  }
}
