import React, { useState } from 'react'
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { useAuth, useReviews } from 'core'
import { isEqual } from 'lodash'
import { USER_GROUP } from 'helpers'

const useStyles = makeStyles((theme) => ({
  icon: { margin: theme.spacing(1) },
  field: { marginBottom: theme.spacing(2) },
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const CustomDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton size="small" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
})

const EditLegacyReviewModal = (props) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { loadReviews } = useReviews()
  const { isAuthenticated, user } = useAuth()
  const { onClose, review, open } = props

  const [form, setForm] = useState(review)

  const url =
    isAuthenticated && user.userGroup === USER_GROUP.admin.id
      ? `/api/admin/legacy-review/edit/${review._id}`
      : `/api/customer/legacy-review/edit/${review._id}`

  const handleSend = async () => {
    try {
      const { data } = await axios.put(url, form)
      enqueueSnackbar(data.message, { variant: 'success' })
      loadReviews()
      handleClose()
    } catch (error) {
      const errors = error.response.data.errors

      if (errors) {
        errors.map((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      } else {
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
      }
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
      <CustomDialogTitle onClose={handleClose}>Напиши ревю</CustomDialogTitle>
      <DialogContent>
        <TextField
          size="small"
          className={classes.field}
          fullWidth
          variant="outlined"
          label="Ревю"
          multiline
          value={form.review}
          onChange={(e) => setForm({ ...form, review: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSend} disabled={isEqual(review, form)}>
          Запази
        </Button>
        <Button onClick={handleClose}>Отмени</Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(EditLegacyReviewModal)
