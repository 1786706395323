import React, { useState, useEffect } from 'react'
import {
  Box,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  makeStyles,
  FormControl,
  Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  field: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Emails = ({ form, setForm }) => {
  const classes = useStyles()

  const [fields, setFields] = useState([{ value: '' }])

  useEffect(() => {
    setForm({ ...form, emails: fields.map((field) => field.value) })
    // eslint-disable-next-line
  }, [fields])

  const handleInputChange = (index, event) => {
    const values = [...fields]
    values[index].value = event.target.value
    setFields(values)
  }

  const handleAddFields = () => {
    const values = [...fields]
    values.push({ value: '' })
    setFields(values)
  }

  const handleRemoveFields = (index) => {
    const values = [...fields]
    values.splice(index, 1)
    setFields(values)
  }

  return (
    <Box className={classes.root}>
      {fields.map((field, index) => (
        <FormControl variant="outlined" key={`${field}~${index}`} className={classes.field}>
          <InputLabel htmlFor={field.index}>Имейл на играча</InputLabel>
          <OutlinedInput
            id={field.index}
            type="email"
            value={field.value}
            onChange={(e) => handleInputChange(index, e)}
            labelWidth={112}
            endAdornment={
              fields.length > 1 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleRemoveFields(index)} edge="end">
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        </FormControl>
      ))}
      <div>
        <Tooltip title="Добави друг имейл">
          <span>
            <IconButton
              disabled={fields.some((field) => field.value === '')}
              color="primary"
              onClick={handleAddFields}>
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    </Box>
  )
}

export default React.memo(Emails)
