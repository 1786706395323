import React, { useState } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Grid, Button, TextField, Link, Typography, Paper } from '@material-ui/core'
import { PageURLs } from 'Routes'
import { DividerWithText } from 'components'
import { useAuth } from 'core'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    flexBasis: 700,
    margin: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  suggestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
}))

const SignIn = (props) => {
  const { history } = props

  const classes = useStyles()
  const { login } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const [formState, setFormState] = useState({ email: '', password: '', recaptcha: null })

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }))
  }

  const handleSignIn = (event) => {
    event.preventDefault()
    if (formState.email !== '' && formState.password !== '') {
      login({
        email: formState.email,
        password: formState.password,
        recaptcha: formState.recaptcha,
      })
      history.push('/')
    } else {
      enqueueSnackbar('Моля, попълни формата', { variant: 'error' })
    }
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Влез в профила си | Всички стаи</title>
      </Helmet>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form onSubmit={handleSignIn}>
                <Paper className={classes.form}>
                  <Typography className={classes.title} variant="h2">
                    Влез в профила си
                  </Typography>
                  <Grid className={classes.socialButtons} container spacing={2}>
                    <Grid item style={{ width: '100%' }}>
                      <Button
                        onClick={() =>
                          window.open('https://vsichkistai.bg/api/auth/facebook', '_self')
                        }
                        size="large"
                        variant="contained"
                        style={{ width: '100%' }}>
                        <img
                          src="/image/logos/facebook_logo.png"
                          style={{ maxHeight: 20, marginRight: 16 }}
                          alt="fblogo"
                        />
                        Влез с Facebook
                      </Button>
                    </Grid>
                  </Grid>
                  <DividerWithText text="или" color="textSecondary" />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Имейл"
                    name="email"
                    onChange={handleChange}
                    type="email"
                    value={formState.email || ''}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    label="Парола"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.password || ''}
                    variant="outlined"
                    helperText={
                      <Link component={RouterLink} to={'/forgotten-password'} variant="h6">
                        Забравена парола?
                      </Link>
                    }
                  />

                  <ReCAPTCHA
                    sitekey="6Ld1XCQjAAAAAJFmapG8YwbLb_ACSoAQQlxtGm3G"
                    onChange={(value) => setFormState({ ...formState, recaptcha: value })}
                  />

                  <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    disabled={!formState.recaptcha || !formState.password || !formState.email}
                    variant="contained">
                    Впиши се
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    Нямаш профил?{' '}
                    <Link component={RouterLink} to={PageURLs.Register} variant="h6">
                      Регистрирай се
                    </Link>
                  </Typography>
                </Paper>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SignIn.propTypes = {
  history: PropTypes.object,
}

export default withRouter(SignIn)
