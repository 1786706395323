import React, { useState } from 'react'
import MinimizeIcon from '@material-ui/icons/Minimize'
import { UploadImage, UploadThumbnail, UploadGallery } from './components'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from '@ckeditor/ckeditor5-build-classic'
import {
  Typography,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Grid,
  Link,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { linkify, USER_GROUP } from 'helpers'
import { cities, filters } from 'utils'
import { useAuth, useEscapeRooms } from 'core'
import { Link as RouterLink } from 'react-router-dom'
import { isEmpty } from 'lodash'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ setOpen, open }) {
  const { user } = useAuth()
  const { createEscapeRoomOwner } = useEscapeRooms()

  const [isLoading, setIsLoading] = useState(false)
  const [form, setForm] = useState({
    image: '',
    thumbnail: '',
    gallery: [],
    title: '',
    shortname: '',
    company: {},
    city: 'София',
    address: '',
    description: '',
    complexity: 3,
    genres: [],
    people: [2, 6],
    gameLength: 60,
    ageRestriction: '',
    site: '',
    email: '',
    phone: '',
    facebook: '',
    instagram: '',
    twitter: '',
    tripadvisor: '',
    messenger: '',
  })

  const handleChangeGenre = (e) => {
    e.target.checked
      ? setForm({ ...form, genres: [...form.genres, e.target.name] })
      : setForm({ ...form, genres: form.genres.filter((genre) => genre !== e.target.name) })
  }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCreate = async () => {
    setIsLoading(true)
    createEscapeRoomOwner(form, handleClose)
    setIsLoading(false)
  }

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
      <DialogTitle>Създай нова стая</DialogTitle>
      <DialogContent>
        {user.userGroup !== USER_GROUP.admin.id && (
          <DialogContentText>Стаята трябва да бъде одобрена от администратор</DialogContentText>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Изображения
            </Typography>
          </Grid>
          <UploadImage form={form} setForm={setForm} />
          <UploadThumbnail form={form} setForm={setForm} />
          <UploadGallery form={form} setForm={setForm} />

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Детайли за стаята
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="title"
              label="Име на стаята"
              fullWidth
              value={form.title}
              onChange={handleChange}
            />
          </Grid>
          {user.email === 'cattz0r@gmail.com' && (
            <Grid item lg={4} xs={12}>
              <TextField
                size="small"
                variant="outlined"
                name="shortname"
                helperText={`Линкът, чрез който ще се стига до стаята ви В САЙТА НА vsichkistai. НЕ ПИШЕТЕ уеб адреса до личния сайт на стаята! Ако името на стаята ви е "Примерна стая", можете да напишете този линк да е "primerna-staya". Линкът ще изглежда така: https://vsichkistai.bg/escape-rooms/${linkify(
                  form.shortname
                )}`}
                label="Линк към стаята (само на латиница!)"
                fullWidth
                value={linkify(form.shortname)}
                onChange={handleChange}
              />
            </Grid>
          )}
          <Grid item lg={4} xs={12}>
            <TextField
              select
              size="small"
              variant="outlined"
              name="company"
              label="Компания"
              helperText={
                isEmpty(form.company) ? (
                  <>
                    Не си избрал компания.{' '}
                    <Link component={RouterLink} to="/admin/companies">
                      Искаш ли да създадеш?
                    </Link>
                  </>
                ) : (
                  `Избрана компания: ${form.company?.name}`
                )
              }
              fullWidth
              value={form.company}
              onChange={handleChange}>
              {user.companies.map((company) => (
                <MenuItem key={company} value={company}>
                  {company.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Адресна информация
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              select
              size="small"
              variant="outlined"
              name="city"
              label="Град"
              fullWidth
              value={form.city}
              onChange={handleChange}>
              {cities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="address"
              label="Улица"
              helperText="Само улицата, без града"
              fullWidth
              value={form.address}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Основна информация
            </Typography>
          </Grid>

          <Grid item lg={12} xs={12}>
            <FormLabel component="legend">Описание на стаята</FormLabel>
            <CKEditor
              editor={Editor}
              data={form.description}
              onChange={(event, editor) => {
                const data = editor.getData()
                setForm({ ...form, description: data })
              }}
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Жанрове</FormLabel>
              <FormGroup style={{ flexDirection: 'row' }}>
                {filters
                  .filter((genre) => genre.name !== '*')
                  .map((genre) => {
                    return (
                      <FormControlLabel
                        key={genre.name}
                        control={
                          <Checkbox
                            checked={form.genres.includes(genre.name)}
                            onChange={handleChangeGenre}
                            disabled={!form.genres.includes(genre.name) && form.genres.length >= 3}
                            name={genre.name}
                          />
                        }
                        label={genre.value}
                      />
                    )
                  })}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="complexity"
              label="Трудност"
              type="number"
              helperText="Число по-голямо или равно на 1 и по-малко или равно на 5"
              fullWidth
              value={form.complexity >= 5 ? 5 : form.complexity <= 0 ? 1 : form.complexity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              size="small"
              variant="outlined"
              name="people"
              label="Играчи"
              type="number"
              helperText="от"
              value={form.people[0]}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: [+e.target.value, form.people[1]] })
              }
            />
            <MinimizeIcon />
            <TextField
              size="small"
              variant="outlined"
              name="people"
              label="Играчи"
              type="number"
              helperText="до"
              value={form.people[1]}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: [form.people[0], +e.target.value] })
              }
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="gameLength"
              label="Продължителност на играта"
              type="number"
              helperText="в минути"
              fullWidth
              value={form.gameLength}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="ageRestriction"
              label="Възрастово ограничение"
              type="number"
              fullWidth
              value={form.ageRestriction}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Контактна информация
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="site"
              label="Уебсайт на стаята"
              helperText="Препоръчително е да води до резервационната форма"
              fullWidth
              value={form.site}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="email"
              label="Имейл на стаята"
              type="email"
              fullWidth
              value={form.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="phone"
              label="Телефонен номер"
              type="phone"
              fullWidth
              value={form.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="facebook"
              label="Facebook страница"
              helperText="Моля, напишете пълния линк (пример: https://facebook.com/SawRoomBG/)"
              fullWidth
              value={form.facebook}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="messenger"
              label="Messenger линк"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.messenger}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="instagram"
              label="Instagram страница"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.instagram}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="tripadvisor"
              label="Tripadvisor страница"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.tripadvisor}
              onChange={handleChange}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <TextField
              size="small"
              variant="outlined"
              name="twitter"
              label="Twitter страница"
              helperText="Моля, напишете пълния линк"
              fullWidth
              value={form.twitter}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} color="primary" disabled={isLoading}>
          Създай
        </Button>
        <Button onClick={handleClose}>Отмени</Button>
      </DialogActions>
    </Dialog>
  )
}
