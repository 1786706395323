import React, { useEffect, useState } from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { messages } from 'utils'
import clsx from 'clsx'
import {
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
  Box,
  Collapse,
  IconButton,
} from '@material-ui/core'
import { Review } from './components'
import { useFetchData } from 'helpers'
import { ComponentTitle } from '..'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.sizing.main.contentWidth,
    marginTop: -theme.spacing(1),
    padding: theme.spacing(2),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    margin: `0 ${theme.spacing(2)}px`,
    backgroundColor: '#bcbdbf',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: theme.spacing(1),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

const Reviews = ({ title, newReviews, totalPagesNewReviews }) => {
  const classes = useStyles()

  const [reviews, setReviews] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(null)
  const [expanded, setExpanded] = useState(false)

  const [data, isLoading, isError, setErrorMessage] = useFetchData(
    `/api/legacy-reviews/room/${title}?page=${page}`
  )

  useEffect(() => {
    newReviews &&
      totalPagesNewReviews &&
      totalPagesNewReviews === 1 &&
      newReviews.length < 8 &&
      setExpanded(true)
  }, [totalPagesNewReviews, newReviews])

  useEffect(() => {
    setErrorMessage(messages.review.failurePlural)
    !isLoading && setReviews(data.reviews)
    setTotalPages(data.totalPages)
    // eslint-disable-next-line
  }, [data, isLoading])

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleClick = () => {
    const anchor = document.querySelector('#legacy-reviews-part')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return reviews && reviews.length ? (
    <>
      <Grid item xs={12} id="legacy-reviews-part">
        <Box display="flex" alignItems="center" style={{ paddingBottom: 18 }}>
          <ComponentTitle noGutter>
            Оценки от стария сайт
            <IconButton
              size="small"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}>
              <ExpandMoreIcon />
            </IconButton>
          </ComponentTitle>
        </Box>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid
          container
          className={classes.root}
          style={reviews?.length < 8 ? {} : { minHeight: 1158 }}
          spacing={5}>
          {isLoading ? (
            <CircularProgress />
          ) : isError ? (
            ''
          ) : reviews !== undefined && reviews.length && totalPages !== null ? (
            <>
              {reviews.map((review) => (
                <Review
                  key={review._id}
                  team={review.team}
                  reviewId={review._id}
                  user={review.user?._id}
                  date={review.date}
                  image={review.file}
                  score={review.score}
                  room={title}
                  review={review.review}
                />
              ))}
              <Grid className={classes.pagination} item xs={12}>
                <Pagination
                  page={page}
                  onChange={(e, page) => {
                    setPage(page)
                    handleClick()
                  }}
                  count={totalPages}
                  color="primary"
                  showFirstButton
                  showLastButton
                />
              </Grid>
            </>
          ) : (
            <Grid item>
              <Typography>Не бяха открити оценки за тази стая</Typography>
            </Grid>
          )}
        </Grid>
      </Collapse>
    </>
  ) : (
    ''
  )
}

export default Reviews
