import React from 'react'
import { Box, CardMedia, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    cursor: 'pointer',
    transition: 'all 0.2s',

    '&:hover': {
      filter: 'brightness(0.85)',
      transition: 'all 0.2s',
    },
  },
  media: {
    width: '100%',
    height: 0,
    borderRadius: 4,
    paddingTop: '56.25%',
  },
  category: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
  },
  texts: {
    marginTop: theme.spacing(3),
  },
}))

const MainNews = ({ news }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Link to={`/news/${news?.shortname}`}>
        <Box style={{ position: 'relative' }}>
          <CardMedia
            className={classes.media}
            image={news?.image ? news?.image : '/image/missing_image.png'}
          />
          {/* <Box display="flex" className={classes.category}>
            {news.rubrics.map((rubric) => (
              <Chip style={{ marginRight: 4 }} key={rubric} label={rubric} />
            ))}
          </Box> */}
        </Box>
      </Link>
    </div>
  )
}

export default MainNews
