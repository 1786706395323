import React, { useState } from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  InputLabel,
  OutlinedInput,
  IconButton,
  Grid,
  Menu,
  InputAdornment,
  Divider,
} from '@material-ui/core'
import PaletteIcon from '@material-ui/icons/Palette'
import { useTheme } from '@material-ui/styles'
import { useEscapeRooms } from 'core'
import { ChromePicker } from 'react-color'
import { EscapeRoom } from './components'
import { Badges } from './components/EscapeRoom/components'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Modal({ setOpen, open, escaperoom, title, badges }) {
  const { removeBadge, addBadge } = useEscapeRooms()
  const theme = useTheme()
  const [backgroundColor, setBackgroundColor] = useState(theme.palette.primary.main)
  const [textColor, setTextColor] = useState(theme.palette.white)
  const [backgroundAnchor, setBackgroundAnchor] = useState(null)
  const [textAnchor, setTextAnchor] = useState(null)
  const [text, setText] = useState('Текст')

  const handleChangeBackground = (color) => {
    setBackgroundColor(color.hex)
  }

  const handleChangeText = (color) => {
    setTextColor(color.hex)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenColorPickerBackground = (event) => {
    setBackgroundAnchor(event.currentTarget)
  }

  const handleCloseColorPickerBackground = () => {
    setBackgroundAnchor(null)
  }

  const handleOpenColorPickerText = (event) => {
    setTextAnchor(event.currentTarget)
  }

  const handleCloseColorPickerText = () => {
    setTextAnchor(null)
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>Редактирай значките на {title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="primary" variant="overline">
              Добави нова значка
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <InputLabel htmlFor="standard-adornment-password">Напиши текст</InputLabel>
            <OutlinedInput
              id="standard-adornment-password"
              size="small"
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <InputLabel htmlFor="standard-adornment-password">Избери фонов цвят</InputLabel>
            <OutlinedInput
              id="standard-adornment-password"
              size="small"
              variant="outlined"
              value={backgroundColor}
              readOnly
              onClick={handleOpenColorPickerBackground}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleOpenColorPickerBackground}>
                    <PaletteIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Menu
              anchorEl={backgroundAnchor}
              keepMounted
              open={Boolean(backgroundAnchor)}
              onClose={handleCloseColorPickerBackground}>
              <ChromePicker color={backgroundColor} onChange={handleChangeBackground} />
            </Menu>
          </Grid>
          <Grid item lg={4} xs={12}>
            <InputLabel htmlFor="standard-adornment-password">Избери текстов цвят</InputLabel>
            <OutlinedInput
              id="standard-adornment-password"
              size="small"
              variant="outlined"
              value={textColor}
              readOnly
              onClick={handleOpenColorPickerText}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleOpenColorPickerText}>
                    <PaletteIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Menu
              anchorEl={textAnchor}
              keepMounted
              open={Boolean(textAnchor)}
              onClose={handleCloseColorPickerText}>
              <ChromePicker color={textColor} onChange={handleChangeText} />
            </Menu>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setText('Текст')
                addBadge(escaperoom._id, {
                  label: text,
                  color: backgroundColor,
                  textColor: textColor,
                })
              }}>
              Добави значката
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">Предварителен преглед на новата значка</Typography>
            <EscapeRoom
              previewBadge={{ label: text, textColor: textColor, color: backgroundColor }}
              escaperoom={escaperoom}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Divider />
          {badges.length ? (
            <Grid item xs={12}>
              <Typography color="primary" variant="overline">
                Съществуващи значки
              </Typography>
            </Grid>
          ) : (
            ''
          )}
          {badges.map((badge) => {
            return (
              <Grid key={badge.label} item xs={4} style={{ textAlign: 'center' }}>
                <Badges
                  label={badge.label}
                  backgroundColor={badge.color}
                  textColor={badge.textColor}
                />
                <Button
                  style={{ marginTop: 4 }}
                  variant="contained"
                  size="small"
                  onClick={() => removeBadge(escaperoom._id, badge)}>
                  Изтрий значката
                </Button>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
