import React from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  findRoomText: {
    marginRight: 12,
  },
}))

const SearchTypography = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    fontFamily: 'Ubuntu',
    fontSize: 18,
    fontWeight: 700,
    alignItems: 'center',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
}))(Typography)

const SearchHeading = (props) => {
  const { children, searchText } = props
  const classes = useStyles()

  return (
    <SearchTypography component="div">
      <Box className={classes.findRoomText}>{searchText}</Box>
      {children}
    </SearchTypography>
  )
}

export default React.memo(SearchHeading)
