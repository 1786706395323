import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))

const Footer = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{' '}
        <Link component={RouterLink} to="/">
          Vsichkistai
        </Link>{' '}
        - {new Date().getUTCFullYear()}
      </Typography>
      <Typography variant="caption">
        Created with love for the escape room branch by{' '}
        <Link target="_blank" href="https://www.facebook.com/ivan.vladimirov.graphic/">
          this guy
        </Link>
      </Typography>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
