import React, { useState } from 'react'
import { Box, Dialog, DialogActions, IconButton, Avatar, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  previewImage: {
    maxWidth: 500,
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
    width: 150,
    height: 150,
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  iconButton: {
    color: theme.palette.white,
  },
}))

const ExistingPhoto = ({ image, rectangle = false, width = 1000, height = 300 }) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleOpenImage = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {rectangle ? (
        <Box
          style={{
            height: height,
            width: width,
            backgroundImage: `url(${image})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            cursor: 'pointer',
          }}
          onClick={handleOpenImage}
        />
      ) : (
        <Avatar alt="" src={image} className={classes.previewImage} onClick={handleOpenImage} />
      )}

      <Dialog maxWidth={false} open={open} onClose={handleClose}>
        <DialogActions className={classes.actions}>
          <IconButton className={classes.iconButton} size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <img alt="Team" src={image} style={{ maxWidth: '100%' }} />
      </Dialog>
    </Box>
  )
}

export default React.memo(ExistingPhoto)
