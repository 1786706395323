import React, { useState } from 'react'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEscapeRooms } from 'core'
import { filters } from 'utils'
import { intersection } from 'lodash'
import { Box, Dialog, DialogTitle, useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    background: theme.palette.background.mineShaft,
    paddingBottom: theme.spacing(2.5),
    paddingTop: theme.spacing(2.5),
  },
  chip: {
    border: 0,

    '& .MuiChip-deleteIcon': {
      color: 'white',
    },

    '& .MuiChip-label': {
      textTransform: 'uppercase',
    },
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'space-between',
    width: theme.sizing.main.contentWidth,
    paddingLeft: theme.sizing.main.spaceBetweenContents,
    paddingRight: theme.sizing.main.spaceBetweenContents,

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
    },
  },
  chips: {
    display: 'flex',

    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
      marginBottom: theme.spacing(1),
      flexDirection: 'column',
      padding: theme.spacing(1),
    },

    '& > *': {
      margin: theme.spacing(0.5),
      color: 'white',
    },
  },
  mobileChips: {
    '& > *': {
      margin: theme.spacing(0.5),
      color: 'white',
    },
  },
  select: {
    minWidth: 120,

    '& .MuiFormLabel-root': {
      color: theme.palette.primary.main,
    },
    '&.MuiInputBase-root': {
      fontSize: 40,
      fontWeight: 700,
    },

    '& .MuiOutlinedInput-input': {
      color: theme.palette.white,
      paddingTop: 7,
      paddingBottom: 7,
    },

    '& .MuiSelect-select': {
      backgroundColor: 'rgba(255, 255, 255, 0.10) !important',
      borderRadius: 6,
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },

    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
      borderWidth: 1,
    },
  },
}))

const Filters = ({ filteredCity, setFilteredCity, filteredGenre, setFilteredGenre }) => {
  const classes = useStyles()
  const { escaperooms, cities } = useEscapeRooms()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (genre = '*') => {
    setOpen(false)
    setFilteredGenre(genre)
  }

  const rawFiltersForSelectedCity = intersection(
    filters.map((filter) => filter.name),
    escaperooms
      .filter((escaperoom) => escaperoom.city === filteredCity)
      .map((escaperoom) => {
        return escaperoom.genres
      })
      .flat()
  )

  const filtersForSelectedCity = filters.filter(
    (filter) => rawFiltersForSelectedCity.includes(filter.name) || filter.name === '*'
  )

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TextField
          select
          className={classes.select}
          variant="outlined"
          value={filteredCity}
          onChange={(e) => {
            setFilteredGenre('*')
            setFilteredCity(e.target.value)
          }}>
          {cities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          ))}
        </TextField>
        {isDesktop ? (
          <div className={classes.chips}>
            {filtersForSelectedCity.map((filter) =>
              filteredGenre === filter.name ? (
                filteredGenre === '*' ? (
                  <Chip
                    key={filter.name}
                    label={filter.value}
                    style={{ backgroundColor: filter.background }}
                    className={classes.chip}
                    clickable
                    avatar={
                      <Avatar style={{ color: 'white', background: 'transparent' }}>
                        <FontAwesomeIcon size="lg" icon={filter.icon} />
                      </Avatar>
                    }
                    color="primary"
                  />
                ) : (
                  <Chip
                    key={filter.name}
                    label={filter.value}
                    style={{ backgroundColor: filter.background }}
                    className={classes.chip}
                    clickable
                    avatar={
                      <Avatar style={{ color: 'white', background: 'transparent' }}>
                        <FontAwesomeIcon size="lg" icon={filter.icon} />
                      </Avatar>
                    }
                    onDelete={() => setFilteredGenre('*')}
                  />
                )
              ) : (
                <Chip
                  key={filter.name}
                  label={filter.value}
                  style={{ backgroundColor: filter.background }}
                  className={classes.chip}
                  clickable
                  color="primary"
                  avatar={
                    <Avatar style={{ color: 'white', background: 'transparent' }}>
                      <FontAwesomeIcon size="lg" icon={filter.icon} />
                    </Avatar>
                  }
                  onClick={() => setFilteredGenre(filter.name)}
                  variant="outlined"
                />
              )
            )}
          </div>
        ) : (
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ width: '100%' }}>
            <div className={classes.mobileChips}>
              {filtersForSelectedCity
                .filter((item) => item.name === filteredGenre)
                .map((filter) =>
                  filteredGenre === filter.name ? (
                    filteredGenre === '*' ? (
                      <Chip
                        key={filter.name}
                        label={filter.value}
                        style={{ backgroundColor: filter.background }}
                        className={classes.chip}
                        clickable
                        avatar={
                          <Avatar style={{ color: 'white', background: 'transparent' }}>
                            <FontAwesomeIcon size="lg" icon={filter.icon} />
                          </Avatar>
                        }
                        color="primary"
                      />
                    ) : (
                      <Chip
                        key={filter.name}
                        label={filter.value}
                        style={{ backgroundColor: filter.background }}
                        className={classes.chip}
                        clickable
                        avatar={
                          <Avatar style={{ color: 'white', background: 'transparent' }}>
                            <FontAwesomeIcon size="lg" icon={filter.icon} />
                          </Avatar>
                        }
                        onDelete={() => setFilteredGenre('*')}
                      />
                    )
                  ) : (
                    <Chip
                      key={filter.name}
                      label={filter.value}
                      style={{ backgroundColor: filter.background }}
                      className={classes.chip}
                      clickable
                      color="primary"
                      avatar={
                        <Avatar style={{ color: 'white', background: 'transparent' }}>
                          <FontAwesomeIcon size="lg" icon={filter.icon} />
                        </Avatar>
                      }
                      onClick={() => setFilteredGenre(filter.name)}
                      variant="outlined"
                    />
                  )
                )}
            </div>
            <Chip
              style={{ backgroundColor: theme.palette.primary.main, color: 'white' }}
              onClick={handleOpen}
              label="Избери категория">
              Избери категория
            </Chip>
            <Dialog
              onClose={() => handleClose('*')}
              aria-labelledby="filters-categories"
              open={open}>
              <DialogTitle id="filters-categories">Филтрирай по категория</DialogTitle>
              <div className={classes.chips}>
                {filtersForSelectedCity.map((filter) =>
                  filteredGenre === filter.name ? (
                    filteredGenre === '*' ? (
                      <Chip
                        key={filter.name}
                        label={filter.value}
                        style={{ backgroundColor: filter.background }}
                        className={classes.chip}
                        clickable
                        avatar={
                          <Avatar style={{ color: 'white', background: 'transparent' }}>
                            <FontAwesomeIcon size="lg" icon={filter.icon} />
                          </Avatar>
                        }
                        color="primary"
                      />
                    ) : (
                      <Chip
                        key={filter.name}
                        label={filter.value}
                        style={{ backgroundColor: filter.background }}
                        className={classes.chip}
                        clickable
                        avatar={
                          <Avatar style={{ color: 'white', background: 'transparent' }}>
                            <FontAwesomeIcon size="lg" icon={filter.icon} />
                          </Avatar>
                        }
                        onDelete={() => handleClose('*')}
                      />
                    )
                  ) : (
                    <Chip
                      key={filter.name}
                      label={filter.value}
                      style={{ backgroundColor: filter.background }}
                      className={classes.chip}
                      clickable
                      color="primary"
                      avatar={
                        <Avatar style={{ color: 'white', background: 'transparent' }}>
                          <FontAwesomeIcon size="lg" icon={filter.icon} />
                        </Avatar>
                      }
                      onClick={() => handleClose(filter.name)}
                      variant="outlined"
                    />
                  )
                )}
              </div>
            </Dialog>
          </Box>
        )}
      </div>
    </div>
  )
}

export default Filters
