import React from 'react'
import Pagination from '@material-ui/lab/Pagination'
import {
  Typography,
  Grid,
  makeStyles,
  CircularProgress,
  TextField,
  Box,
  Divider,
  MenuItem,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Review } from './components'
import { ComponentTitle } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.sizing.main.contentWidth,
    marginTop: -theme.spacing(1),
    padding: theme.spacing(2),
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
  },
  divider: {
    margin: `0 ${theme.spacing(2)}px`,
    backgroundColor: '#bcbdbf',
  },
}))

const Reviews = ({
  title,
  page,
  setPage,
  reviews,
  totalPages,
  setFilter,
  isLoading,
  isError,
  filter,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  })

  const handleClick = () => {
    const anchor = document.querySelector('#reviews-part')

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return reviews && reviews.length ? (
    <>
      <Grid item xs={12} style={{ paddingBottom: 0 }} id="reviews-part">
        <Box display="flex" alignItems="center">
          <ComponentTitle noGutter>Мнения от играчи</ComponentTitle>
          <Divider orientation="vertical" flexItem className={classes.divider} />
          <TextField
            className={classes.filter}
            select
            variant="outlined"
            size="small"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            label={'Сортирай по:'}>
            <MenuItem value="latest">Най-нови ревюта</MenuItem>
            <MenuItem value="oldest">Най-стари ревюта</MenuItem>
            <MenuItem value="not-recommended">Непрепоръчващи ревюта</MenuItem>
            <MenuItem value="recommended">Препоръчващи ревюта</MenuItem>
          </TextField>
        </Box>
      </Grid>

      {isLoading ? (
        <CircularProgress />
      ) : isError ? (
        ''
      ) : reviews !== undefined && reviews.length && totalPages !== null ? (
        <Grid
          container
          className={classes.root}
          style={reviews?.length < 8 ? {} : { minHeight: 1158 }}
          spacing={isDesktop ? 5 : 0}>
          {reviews.map((review) => (
            <Review
              key={review._id}
              reviewId={review._id}
              user={review.user}
              date={review.date}
              image={review.file}
              recommends={review.recommend}
              room={title}
              review={review.review}
              interactions={{
                comments: review.comments,
                likes: review.likes,
              }}
            />
          ))}
          <Grid className={classes.pagination} item xs={12}>
            <Pagination
              page={page}
              onChange={(e, page) => {
                setPage(page)
                handleClick()
              }}
              count={totalPages}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Grid>
        </Grid>
      ) : (
        <Grid item>
          <Typography>Не бяха открити оценки за тази стая</Typography>
        </Grid>
      )}
    </>
  ) : (
    ''
  )
}

export default Reviews
