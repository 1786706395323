import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}))

const Toolbar = (props) => {
  const { setPage, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={classes.root}>
      <div className={classes.row}>
        <Typography variant="h3">Игри</Typography>
        <span className={classes.spacer} />
        <Link style={{ marginLeft: 8 }} to="/admin/create-news">
          <Button variant="contained" color="primary">
            Създай новина
          </Button>
        </Link>
      </div>
      <div className={classes.row}></div>
    </div>
  )
}

export default Toolbar
