import React, { useState } from 'react'
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined'
import {
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { messages } from 'utils'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.success.main,
  },
}))

const EnableUser = ({ user, setInitialUser }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleEnableUser = async () => {
    setIsLoading(true)
    try {
      await axios.put(`/api/admin/user/enable/${user._id}`)
      enqueueSnackbar(messages.admin.user.enableSuccess, { variant: 'success' })
      setInitialUser({ ...user, isEnabled: true })
      setIsLoading(false)
      handleClose()
    } catch (error) {
      enqueueSnackbar(messages.admin.user.enableFail, { variant: 'error' })
      setIsLoading(false)
      handleClose()
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Tooltip title="Активирай потребителя">
        <IconButton size="small" onClick={handleClickOpen}>
          <LockOpenOutlinedIcon className={classes.root} />
        </IconButton>
      </Tooltip>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Активирай потребител</DialogTitle>
        <DialogContent>
          <Typography>Този потребител е бил деактивиран по някаква причина.</Typography>
          <Typography>Сигурен ли си, че искаш да го активираш обратно?</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={isLoading} onClick={handleEnableUser}>
            {isLoading && <CircularProgress size={14} style={{ marginRight: 4 }} />} Активирай
          </Button>
          <Button onClick={handleClose}>Отмени</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EnableUser
