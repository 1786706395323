import React from 'react'
import { CardMedia, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 211,
    height: 52,

    [theme.breakpoints.down('md')]: {
      width: 180,
      height: 44,
    },
  },
}))

const Logo = ({ color = 'black' }) => {
  const classes = useStyles()

  return (
    <div>
      <CardMedia
        image={color === 'black' ? '/image/logos/logoNew.png' : '/image/logos/logoNewWhite.png'}
        className={classes.logo}
      />
    </div>
  )
}

export default Logo
