import React from 'react'
import Box from '@material-ui/core/Box'
import { Image, Logo, Title } from './components'

const Header = (props) => {
  const { cover, logo, title } = props

  return (
    <Image url={cover}>
      <Box display="flex" alignItems="center" flexWrap="wrap" mb={2}>
        <Logo url={logo} />
        <Title>{title}</Title>
      </Box>
    </Image>
  )
}

export default Header
