import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Typography } from '@material-ui/core'
import { NavbarLinks } from 'Routes'
import { useTheme } from '@material-ui/styles'
import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  navigation: {
    '& a': {
      textDecoration: 'none',
      fontWeight: '600',
      margin: '0 15px',
      transition: 'all 0.3s',
      display: 'inline-block',
      textTransform: 'uppercase',
      paddingTop: 6,

      '&:hover': {
        color: theme.palette.mainLayout.text,
      },

      '&:after': {
        content: '""',
        display: 'block',
        margin: 'auto',
        marginTop: 3,
        height: 2,
        width: 0,
        background: 'transparent',
        transition: 'width .3s ease, background-color .3s ease',
      },

      '&:hover:after': {
        width: '67%',
        background: theme.palette.mainLayout.text,
      },
    },
  },
}))

const NavBar = () => {
  const classes = useStyles()
  const { isAuthenticated } = useAuth()
  const theme = useTheme()

  return (
    <nav className={classes.navigation}>
      {NavbarLinks.map((page, id) => {
        if (page.isAuthenticated === true && isAuthenticated === false) {
          return null
        } else {
          return (
            <Link key={id} to={`${page.href}`}>
              <Typography
                style={{ color: theme.palette.black, fontWeight: 600 }}
                variant="subtitle2">
                {page.title}
              </Typography>
            </Link>
          )
        }
      })}
    </nav>
  )
}

export default NavBar
