import React, { useState } from 'react'
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { useAuth, useEscapeRooms } from 'core'
import { Share } from 'layouts/Main/components'
import ShareIcon from '@material-ui/icons/Share'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import { Link, useParams } from 'react-router-dom'
import MuiLink from '@material-ui/core/Link'
import { EditLegacyReviewModal } from 'components'
import { PageURLs } from 'Routes'
import { isEmpty } from 'lodash'
import { USER_GROUP } from 'helpers'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(3),
  },
  media: {
    height: 0,
    cursor: 'pointer',
    paddingTop: '75%',
    maxHeight: 300,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: `${theme.spacing(1)}px !important`,

    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
  mobileContentBox: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  actions: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  date: {
    position: 'absolute',
    bottom: 16,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 4,
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: theme.palette.white,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    textAlign: 'center',
  },
}))

const Review = (props) => {
  const classes = useStyles()
  const { profileId } = useParams()
  const { isAuthenticated, user } = useAuth()
  const { fullReview, isOwn = false } = props
  const { file, roomName, date, review, _id, score, team, finished } = fullReview
  const { escaperooms } = useEscapeRooms()
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openImage, setOpenImage] = useState(false)

  const handleOpenShareDialog = () => {
    setOpenShareDialog(true)
  }

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false)
  }

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true)
  }

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
  }

  const handleOpenImage = () => {
    setOpenImage(true)
  }

  const handleCloseImage = () => {
    setOpenImage(false)
  }

  return (
    <>
      <Card className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={2} style={{ position: 'relative' }}>
            <CardMedia
              className={classes.media}
              image={file ? file : '/image/missing_image.png'}
              title="Review"
              onClick={handleOpenImage}
            />
            <Link to={`${PageURLs.Profile}/${profileId}/legacy-review/${_id}`}>
              <Typography className={classes.date}>
                {Intl.DateTimeFormat('bg-BG').format(new Date(date))}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={10}>
            <CardContent className={classes.content}>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                className={classes.mobileContentBox}>
                <Typography variant="h5" component="h2">
                  {!isEmpty(escaperooms.find((escaperoom) => escaperoom.title === roomName)) ? (
                    <MuiLink
                      component={Link}
                      to={`/escape-rooms/${
                        escaperooms.find((escaperoom) => escaperoom.title === roomName).shortname
                      }`}>
                      {escaperooms.find((escaperoom) => escaperoom.title === roomName).title}
                    </MuiLink>
                  ) : (
                    roomName
                  )}
                </Typography>
                <Alert
                  style={{ marginLeft: 16, marginRight: 16, paddingTop: 0, paddingBottom: 0 }}
                  icon={false}
                  severity={'info'}>
                  Обща оценка: {score}
                </Alert>
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align="justify"
                style={{ marginBottom: 16, marginTop: 16, fontSize: 13 }}>
                {finished ? (
                  review
                ) : isOwn ? (
                  <Button
                    size="small"
                    onClick={handleOpenEditDialog}
                    variant="contained"
                    startIcon={<EditIcon />}>
                    Напиши ревю
                  </Button>
                ) : (
                  <i>Няма написано ревю</i>
                )}
              </Typography>
              <div>
                <Button
                  size="small"
                  style={{ marginRight: 16 }}
                  onClick={handleOpenShareDialog}
                  startIcon={<ShareIcon />}>
                  Сподели
                </Button>
                {isAuthenticated && user.userGroup === USER_GROUP.admin.id && finished && (
                  <Button size="small" onClick={handleOpenEditDialog} startIcon={<EditIcon />}>
                    Редактирай
                  </Button>
                )}
                <Share
                  title={`Ескейп игра на отбор "${team}"`}
                  shareUrl={`${PageURLs.Profile}/${profileId}/legacy-review/${_id}`}
                  open={openShareDialog}
                  onClose={handleCloseShareDialog}
                />
                <EditLegacyReviewModal
                  review={{
                    finished: finished || false,
                    _id,
                    review,
                  }}
                  open={openEditDialog}
                  onClose={handleCloseEditDialog}
                />
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </Card>

      <Dialog maxWidth={false} open={openImage} onClose={handleCloseImage}>
        <DialogActions className={classes.actions}>
          <IconButton style={{ color: '#fff' }} size="small" onClick={handleCloseImage}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <img alt="Game" src={file} style={{ maxHeight: 'calc(100vh - 64px)' }} />
      </Dialog>
    </>
  )
}

export default React.memo(Review)
