import React, { useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import { UploadPhoto, Submit, Toolbar, SelectRoom } from './components'
import { Grid, TextField } from '@material-ui/core'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from '@ckeditor/ckeditor5-build-classic'
import { linkify } from 'helpers'
import { useAuth } from 'core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))

const CreateNews = () => {
  const history = useHistory()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useAuth()

  const [form, setForm] = useState({
    image: '',
    taggedRoom: null,
    title: '',
    content: '',
    shortname: '',
    isEnabled: false,
  })

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post(`/api/owner/news/create`, form)
      enqueueSnackbar(data.message, { variant: 'success' })
      history.push('/admin/news')
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' })
    }
  }

  return (
    <div className={classes.root}>
      <Toolbar />
      <Grid container spacing={2} style={{ maxWidth: 1110, margin: '0 auto' }}>
        <UploadPhoto form={form} setForm={setForm} />
        <Grid item xs={12}>
          <TextField
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            variant="outlined"
            size="small"
            label="Заглавие на новината"
            fullWidth
          />
        </Grid>
        {user.email === 'cattz0r@gmail.com' && (
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              helperText={`Линкът ще изглежда така: https://vsichkistai.bg/news/${linkify(
                form.shortname
              )}`}
              label="Линк към новината (само на латиница)"
              fullWidth
              value={linkify(form.shortname)}
              onChange={(e) => setForm({ ...form, shortname: e.target.value })}
            />
          </Grid>
        )}
        <SelectRoom form={form} setForm={setForm} />
        <Grid item xs={12}>
          <CKEditor
            editor={Editor}
            data={form.content}
            onChange={(event, editor) => {
              const data = editor.getData()
              setForm({ ...form, content: data })
            }}
          />
        </Grid>
        <Submit form={form} handleSubmit={handleSubmit} />
      </Grid>
    </div>
  )
}

export default CreateNews
